import React from "react";
import { render } from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import { reportWebVitals } from "./reportWebVitals";

import { App } from "./App";
import { theme } from "./theme";
import { CustomRouter } from "./components/CustomRouter";
import { customHistory } from "./components/CustomHistory";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <CustomRouter history={customHistory}>
                <App />
            </CustomRouter>
        </ThemeProvider>
    </React.StrictMode>, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
