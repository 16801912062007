import { Box, Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import { useCallback, useState } from "react";

import { BotSecrets } from "../../../services/model/botSecret";
import { getSecrets } from "../../../services/api/BotService";
import useDataClient from "../../../axios/dataClient";
import { useAsync } from "../../../useAsync";

import { LoadingCard } from "../../shared/LoadingCard";

import { BotSecretsDialog } from "./BotSecretsDialog";

interface BotSecretsCardDialogProps {
    botId: string;
}

export const BotSecretsCard = ({ botId }: BotSecretsCardDialogProps) => {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [secrets, setSecrets] = useState<BotSecrets>({});
    const { get } = useDataClient();

    const onCancel = useCallback(() => {
        setDialogOpen(false);
    }, []);

    const onSave = useCallback((newSecrets: BotSecrets) => {
        setSecrets(newSecrets);
        setDialogOpen(false);
    }, []);

    const onEdit = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const onError = useCallback(() => {
        setDialogOpen(false);
    }, []);

    const getSecret = useCallback(async () => {
        const result = await getSecrets(get, botId);
        setIsLoading(false);
        return result;
    }, [get, botId]);

    const secretSummary = useCallback((secret: string) => {
        return (
            <Box sx={{ display: "flex" }}>
                <Typography>{secret}: </Typography>
                <Typography>••••••••</Typography>
            </Box>);
    }, []);

    useAsync(getSecret, setSecrets, [get]);

    if (isLoading) {
        return <div className="bb-tac"><LoadingCard title="Secrets" /></div>;
    }

    return (<><Card>
        <CardHeader title="Secrets" />
        <CardContent>
            {Object.keys(secrets).some(() => true) ?
                Object.keys(secrets).map(secretSummary) :
                <Typography sx={{ fontStyle: "italic" }}>None</Typography>}
        </CardContent>
        <CardActions className="bb-card-footer"
            sx={{
                display: "flex",
                justifyContent: "flex-end",
            }}>
            <Button onClick={onEdit}>Edit</Button>
        </CardActions>
    </Card>
    <BotSecretsDialog onCancel={onCancel} onSave={onSave} onError={onError} botId={botId} open={dialogOpen} secrets={secrets} />
    </>);
};
