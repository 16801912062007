import { Schema } from "../../../schemas/theme/schema";

const formatColorValue = (colorValue: string | undefined) => {

    if (!colorValue) {
        // This is the default color value - Black
        return "#000000";
    }

    if (colorValue && colorValue.length === 4) {
        return `${colorValue}${colorValue.substring(1, 4)}`;
    }

    return colorValue;
};

export const convert3HexTo6Hex = (schemaData: Schema) => {
    /* eslint-disable dot-notation */
    if (schemaData.clientBrand) {
        schemaData.clientBrand["primaryColor"] = formatColorValue(schemaData.clientBrand["primaryColor"]);
        schemaData.clientBrand["secondaryColor"] = formatColorValue(schemaData.clientBrand["secondaryColor"]);
        schemaData.clientBrand["tertiaryColor"] = formatColorValue(schemaData.clientBrand["tertiaryColor"]);
    }

    if (schemaData.header) {
        schemaData.header["backgroundColor"] = formatColorValue(schemaData.header["backgroundColor"]);
        schemaData.header["fontColor"] = formatColorValue(schemaData.header["fontColor"]);
        schemaData.header["iconsColor"] = formatColorValue(schemaData.header["iconsColor"]);
    }

    if (schemaData.main) {
        schemaData.main["backgroundColor"] = formatColorValue(schemaData.main["backgroundColor"]);
        schemaData.main["backgroundAltColor"] = formatColorValue(schemaData.main["backgroundAltColor"]);
        schemaData.main["panelBackgroundColor"] = formatColorValue(schemaData.main["panelBackgroundColor"]);
        schemaData.main["inputBackgroundColor"] = formatColorValue(schemaData.main["inputBackgroundColor"]);
        schemaData.main["botPanelsHeaderFontColor"] = formatColorValue(schemaData.main["botPanelsHeaderFontColor"]);
        schemaData.main["botPanelsSpanFontColor"] = formatColorValue(schemaData.main["botPanelsSpanFontColor"]);
        schemaData.main["panelFontColor"] = formatColorValue(schemaData.main["panelFontColor"]);
        schemaData.main["panelListBackgroundColor"] = formatColorValue(schemaData.main["panelListBackgroundColor"]);
        schemaData.main["panelsLancherBorderColor"] = formatColorValue(schemaData.main["panelsLancherBorderColor"]);
        schemaData.main["shadowColor"] = formatColorValue(schemaData.main["shadowColor"]);
    }

    if (schemaData.popups) {
        schemaData.popups["backgroundColor"] = formatColorValue(schemaData.popups["backgroundColor"]);
        schemaData.popups["fontColor"] = formatColorValue(schemaData.popups["fontColor"]);
    }

    if (schemaData.smartPages) {
        schemaData.smartPages["backgroundColor"] = formatColorValue(schemaData.smartPages["backgroundColor"]);
        schemaData.smartPages["fontColor"] = formatColorValue(schemaData.smartPages["fontColor"]);
        schemaData.smartPages["smartPageButtonColor"] = formatColorValue(schemaData.smartPages["smartPageButtonColor"]);
        schemaData.smartPages["smartPageButtonTextColor"] = formatColorValue(schemaData.smartPages["smartPageButtonTextColor"]);
        schemaData.smartPages["smartPageButtonIconColor"] = formatColorValue(schemaData.smartPages["smartPageButtonIconColor"]);
    }

    if (schemaData.bot) {
        schemaData.bot["backgroundColor"] = formatColorValue(schemaData.bot["backgroundColor"]);
        schemaData.bot["fontColor"] = formatColorValue(schemaData.bot["fontColor"]);
        schemaData.bot["adaptivecardButtonColor"] = formatColorValue(schemaData.bot["adaptivecardButtonColor"]);
        schemaData.bot["adaptivecardFontColor"] = formatColorValue(schemaData.bot["adaptivecardFontColor"]);
    }
    /* eslint-enable dot-notation */

    return schemaData;
};
