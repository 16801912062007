import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import React, { useState, useEffect, useCallback } from "react";

import Typography from "@mui/material/Typography";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { toast } from "react-toastify";

import { UiSchema } from "@rjsf/utils";

import { v7 as uuidv7 } from "uuid";

import useDataClient from "../../../axios/dataClient";

import { getAppConfigContent, postAppConfigContent } from "../../../services/api/FrontendAppConfigService";

import { SchemaType } from "../../../schemas/schemaTypes";

import { Schema } from "../../../schemas/settings";

import { SettingsForm } from "./SettingsForm";

import { AppFeaturesForm } from "./AppFeaturesForm";

import { UndoButton } from "../UndoButton";

export interface AppSettingsBaseFormProps {
    appId: string | undefined;
}

export const AppSettingsBaseForm: React.FC<AppSettingsBaseFormProps> = ({ appId }) => {
    const [appSettings, setAppSettings] = useState<Schema | null>(null);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const { get, post } = useDataClient();
    const [showSettingsReloadModal, setShowSettingsReloadModal] = useState(false);

    const uiSchema: UiSchema = {
        'ui:submitButtonOptions': {
            norender: true
        },
        "ui:title": "",
        "ui:classNames": "bb-settings-container",
        "Restart": {
            "ui:options": {
                "classNames": "bb-feature-restart"
            }
        },
        "appLogo": {
            "ui:widget": "MediaLibraryWidget"
        },
        "appLogoDark": {
            "ui:widget": "MediaLibraryWidget"
        },
        "appHeaderBackgroundImg": {
            "ui:widget": "MediaLibraryWidget"
        },
        "appPanelBackgroundImg": {
            "ui:widget": "MediaLibraryWidget"
        },
        "customerApp": {
            "ui:widget": "hidden"
        }
    };

    const fetchData = React.useCallback(async () => {
        if (appId) {
            const settingsData = await getAppConfigContent<Schema>(get)(appId, SchemaType.App);
            setAppSettings(settingsData);
        }
    }, [appId, get]);

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [appId, get, fetchData]);

    const handleChange = useCallback((e: any): void => {
        if (e.retrievedSchema.title === "App Features" && appSettings) {
            setAppSettings({ ...appSettings, appFeatures: e.formData });
            return;
        }

        setAppSettings({ ...appSettings, ...e.formData });
    },
    [appSettings, setAppSettings]);

    const handleSubmit = useCallback(async ({ formData }: any) => {
        if (appId) {
            setSubmitting(true);

            const latestSettingsData = await getAppConfigContent<Schema>(get)(appId, SchemaType.App);
            const latestSettingsHash = latestSettingsData.savedHash;

            if (latestSettingsHash !== formData.savedHash) {
                setShowSettingsReloadModal(true);
                setSubmitting(false);
                return;
            }

            const newSettingsData: Schema = { ...appSettings, ...formData, savedHash: uuidv7() };

            setAppSettings(newSettingsData);
            await postAppConfigContent<Schema>(post)(appId, SchemaType.App, {
                configDocument: newSettingsData,
                message: "Updated app settings"
            }).then(() => {
                toast.success("Changes Saved");
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }, [post, appId, appSettings, get]);

    const handleError = useCallback((e: any): void => {
        console.log("Data changed: ", e.formData);
    }, []);

    const handleUndo = useCallback(async (): Promise<void> => {
        setSubmitting(true);
        fetchData().then(() => {
            toast.warning("Settings data has been reset");
            return;
        }).finally(() => {
            setSubmitting(false);
        });
    }, [fetchData]);

    const handleShowSettingsReloadModalClose = () => {
        setShowSettingsReloadModal(false);
    };

    const handleShowSettingsReloadModalProceed = () => {
        setShowSettingsReloadModal(false);
        handleUndo();
    };

    if (!appSettings) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <>
            <Dialog
                open={showSettingsReloadModal}
            >
                <DialogTitle id="alert-dialog-title" className="bb-error-dialog-header bb-flex bb-align-items-center">
                    <WarningAmberIcon className="bb-mr-1"></WarningAmberIcon> <h2 className="bb-m0 bb-p0">{"WARNING!"}</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="bb-tac">
                        <h2>
                            Another user has recently updated Settings. Please reload the page to get the most recent settings data and try again.
                        </h2>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleShowSettingsReloadModalClose}>
                        Close
                    </Button>
                    <Button onClick={handleShowSettingsReloadModalProceed} className="bb-ml-auto">
                        Reload Settings Data
                    </Button>
                </DialogActions>
            </Dialog>
            {submitting && <Box className="bb-tac"><CircularProgress /></Box>}
            <Container className="bb-settings-list bb-m0 bb-p0">
                <Box className="bb-mb-2 bb-flex">
                    <UndoButton onClick={handleUndo} disable={submitting}></UndoButton>
                    <Button className="bb-ml-auto" variant="contained" disabled={submitting} type="submit" form="settings_pagesubmit">
                        Save</Button>
                </Box>
                <Accordion className="bb-app-settings"
                    square={true}
                    defaultExpanded={false}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant="h4">Application Settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SettingsForm
                            settings={{ ...appSettings }}
                            onChange={handleChange}
                            onError={handleError}
                            onSubmit={handleSubmit}
                            uiSchema={uiSchema}
                            submitting={submitting}
                        ></SettingsForm>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="bb-app-features"
                    square={true}
                    defaultExpanded={false}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant="h4">App Features</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AppFeaturesForm
                            appFeatures={{ ...appSettings.appFeatures }}
                            onChange={handleChange}
                            onError={handleError}
                            onSubmit={handleSubmit}
                            uiSchema={uiSchema}
                            submitting={submitting}
                        ></AppFeaturesForm>
                    </AccordionDetails>
                </Accordion>
            </Container>
        </>
    );
};
