import * as React from "react";

import { Grid, Card, CardContent, Box, Typography } from "@mui/material";

export interface BBAccordionProps {
    panel: any;
}
export const BBAccordion: React.FC<BBAccordionProps> = ({ panel }) => {
    return (<Box>
        {panel?.accData?.map((data: any, index: number) => (
            <Grid item xs className={` ${index === 0 ? '' : ''}`} key={index}>
                {index === 0 && (
                    <>
                        <img src={panel.imgUrl} className="bb-pe-img bb-width-100"/>
                        <Typography variant="h3" className="bb-tal bb-mt-1 bb-mb-1">{panel.panelHeading}</Typography>
                        <Typography variant="body1">{panel.panelPara}</Typography>
                    </>
                )}
                <Card className="bb-mb-2 bb-tal">
                    <CardContent className="bb-tal" sx={{ borderBottom: "1px solid #eee", background: "#eee", padding: ".25rem 1rem", }}>
                        <h3>{data.heading}</h3>
                    </CardContent>
                    <CardContent sx={{ padding: "1rem 1rem 0 1rem", }} className="bb-accordion-content bb-tal">
                        {data.body}
                    </CardContent>
                </Card>
            </Grid>
        ))}
    </Box>
    );
};
