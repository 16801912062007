import {
    Box,
    Container,
    Grid,
    Skeleton,
    Alert,
} from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import useDataClient from "../../axios/dataClient";
import { getBots } from "../../services/api/BotService";
import { BotListModel } from "../../services/model/bot";

import { MemberAppContext } from "../../MemberAppContext";

import { useAsync } from "../../useAsync";

import { BotCard } from "./BotCard";
import { AddBotDialog } from "./AddBotDialog";
import { AddCard } from "./AddCard";

export const BotPage = () => {
    const { get, post } = useDataClient();

    const [bots, setBots] = useState<BotListModel[]>([]);
    const [templates, setTemplates] = useState<{ displayName: string; templateKey: string }[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [addDialogOpen, setAddDialogOpen] = useState(false);

    const loadBots = useCallback(async () => {
        setIsLoading(true);
        const data = await getBots(get);
        setIsLoading(false);
        return data;
    }, [get]);

    const loadTemplates = useCallback(async () => {
        return get<{ displayName: string; templateKey: string }[]>("api/bot/templates");
    }, [get]);

    useAsync(loadBots, setBots, [get]);
    useAsync(loadTemplates, setTemplates, []);

    const { searchString } = useContext(MemberAppContext);

    const filteredBots = useMemo(() => {
        return bots?.filter((x) =>
            x.displayName
                .toLocaleUpperCase()
                .includes(searchString.trim().toLocaleUpperCase())
        );
    }, [bots, searchString]);

    useEffect(() => {
        let isActive = true;
        const interval = setInterval(() => {
            getBots(get).then((updatedBots) => {
                if (isActive) {
                    setBots(updatedBots);
                }
                return;
            });
        }, 30000);

        return () => {
            isActive = false;
            clearInterval(interval);
        };
    }, [get]);

    const onIsEnabledChange = useCallback(async (botId: string, isEnabled: boolean) => {
        const updateBot = (transform: ((old: BotListModel) => BotListModel)) => {
            setBots(state => {
                const i = bots.findIndex(bot => bot.azureBotName === botId);
                const nextState = [...state];
                nextState[i] = transform({ ...nextState[i] });
                return nextState;
            });
        };

        updateBot(x => ({ ...x, isEnabledChanging: true }));

        try {
            await post(`api/customer/bots/${botId}/setEnabled`, { enabled: isEnabled });
            updateBot(x => ({ ...x, isActive: isEnabled }));
        } finally {
            updateBot(x => ({ ...x, isEnabledChanging: false }));
        }

    }, [bots, post]);

    const updateBot = useCallback((oldBotId: string, newBot: Partial<BotListModel>) => {
        setBots(state => {
            const i = state.findIndex(bot => bot.azureBotName === oldBotId);
            const nextState = [...state];
            nextState[i] = { ...nextState[i], ...newBot };
            return nextState;
        });
    }, []);

    const openAddDialog = useCallback(() => {
        setAddDialogOpen(true);
    }, []);

    const closeAddDialog = useCallback(() => {
        setAddDialogOpen(false);
    }, []);

    const onAddSuccess = useCallback(async () => {
        closeAddDialog();
        const newBots = await loadBots();
        setBots(newBots);
    }, [closeAddDialog, loadBots]);

    return (
        <Container>
            <Box>
                <Box className="bb-title-bar"><h2>Conversational AI</h2></Box>
                <Alert className="bb-title-info" severity="info">Select one of your chat bots to manage & edit.</Alert>
                <Grid
                    container
                    spacing={2}
                    alignItems="stretch"
                    alignContent="center">
                    {!isLoading ? (
                        <>
                            {filteredBots?.map((c) => (
                                <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={c.azureBotName} className="animated animatedFadeInUp fadeInUp">
                                    <BotCard
                                        botId={c.azureBotName}
                                        views={c.views}
                                        botName={c.displayName}
                                        isEnabled={c.isActive}
                                        isTraining={c.isTraining}
                                        visitsInLast24Hours={c.visitsInLast24Hours}
                                        handleIsEnabledChange={onIsEnabledChange}
                                        avatarSrc={c.logo}
                                        handleBotUpdated={updateBot}
                                        isEnabledToggleDisabled={c.isEnabledChanging} />
                                </Grid>
                            ))}
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} className="animated animatedFadeInUp fadeInUp2">
                                <AddCard onClick={openAddDialog} headingText="Create CAI" />
                            </Grid>
                        </>
                    ) : (<Skeleton variant="rectangular" />)
                    }
                </Grid>
                <AddBotDialog open={addDialogOpen} onCancel={closeAddDialog} onSuccess={onAddSuccess} templates={templates} />
            </Box>
        </Container>
    );
};
