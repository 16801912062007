import * as React from 'react';

import EditIcon from '@mui/icons-material/Edit';

import IconButton from '@mui/material/IconButton';

export interface EditButtonProps {
    rowIndex: number;
    columnIndex?: number;
    panelIndex?: number;
    onClick: (rowIndex: number, columnIndex?: number, panelIndex?: number) => void;
}

export const EditButton: React.FC<EditButtonProps> = ({ rowIndex, columnIndex, panelIndex, onClick }) => {
    return (
        <IconButton aria-label="settings" onClick={
            React.useCallback(() => onClick(rowIndex, columnIndex, panelIndex), [rowIndex, columnIndex, panelIndex, onClick])
        }>
            <EditIcon />
        </IconButton>
    );
};
