export interface TypingIndicatorProps {
    isShown: boolean;
}

const TypingIndicator = (props: TypingIndicatorProps) => {
    const isShown = props.isShown;

    if (isShown) {
        return (
            <>Typing...</>
        );
    } else {
        return (<></>);
    }
};

export { TypingIndicator };
