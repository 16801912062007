import {
    Chart as ChartJS,
    TimeSeriesScale,
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    Filler,
    ChartOptions
} from "chart.js";
import { Line } from "react-chartjs-2";

import "chartjs-adapter-moment";

import { bbot_visuals_splitDataByGroup, get_new_data, create_colour_dict } from "./AnalyticsHelpers";

ChartJS.register(
    TimeSeriesScale,
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    Filler
);

interface Props {
    data: any | undefined;
    valueCol: string;
    rowFilters: any | undefined;
    formatPc: boolean;
    valueColName: string;
}

export const AnalyticsLineChart = ({ data, valueCol, formatPc, valueColName }: Props) => {

    const lastSegment = function(ctx: any, value: any,data0: any) {
        if (ctx.p1DataIndex ===data0.length-1) {
            return value;
        }
    };

    if (!data) {
        return null;
    }
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const dataTest = {
        labels,
        datasets: [
            {
                label: 'Dataset 1',
                data: [1,2,3,4,5,6,7],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                segment: {  borderDash: (ctx: any) => lastSegment(ctx, '#FF0000', [])  }
            },
            {
                label: 'Dataset 2',
                data: [1,2,3,4,5,6,7].map( (q) => q*1.2),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                segment: {  borderDash: (ctx: any) => lastSegment(ctx, '#FF0000', [])  }
            },
        ],
    };

    const datag = data.data as any;
    const options: ChartOptions<"line"> = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
        },
        scales: {  y: { title: { text: valueColName ,display: true } } }
    };

    const flatColumns =[
        {
            kpi_period: "Month"
        },
        {
            customerBot: "App"
        }
    ];
    const new_col_dict = flatColumns.reduce((r: any, o: any) => {
        Object.keys(o).forEach((k: string) => { r[k] = o[k]; });
        return r;
    }, {});
    new_col_dict[valueCol]=valueCol;
    const cols_dict = [];
    for (const [key, ] of Object.entries(datag)) {
        cols_dict.push({ title: key });
    }

    const rows = Object.keys(datag[cols_dict[0].title]);

    const new_data = get_new_data(rows, datag, new_col_dict);
    const k2 = bbot_visuals_splitDataByGroup(
        new_data,
        "App",
        "Month",
        {},
        {
            barPercentage: 1,
            barThickness: 15,
            maxBarThickness: 25,
            hoverBorderWidth: 1,
            hoverRadius: 3,
            tension: 0.15
        },
        true
    );
    for (const kk of k2[1]) {
        if ( formatPc) {
            kk.data = kk.data.map( (x: any) =>  { return { x: x.Month, y: x[valueCol] * 100 }; } );
        } else {
            kk.data = kk.data.map( (x: any) =>  { return { x: x.Month, y: x[valueCol] }; } );
        }
        kk.spanGaps = true;
    }
    k2[0].sort();
    dataTest.labels = k2[0];
    dataTest.datasets = k2[1];

    const value_to_colour = create_colour_dict(dataTest.datasets.map(e => e.label));

    for (const trace of dataTest.datasets) {
        trace.backgroundColor= value_to_colour[trace.label];
        trace.borderColor=value_to_colour[trace.label];
        trace.segment ={  borderDash: (ctx: any) => lastSegment(ctx, valueColName.includes('Predicted') ?  [6,6] : undefined, trace.data)   };
    }
    return <Line options={options} data={dataTest} />;

};
