import React, { useCallback } from 'react';

import { MenuItem, Select, TextField } from '@mui/material';

const LinkTypeWidget = (props: any) => {
    const idParts = props.id.split('_');
    const index = idParts.find((part: any) => !isNaN(Number(part)));

    const formData = props.formContext.formData;
    const pageOptions = props.formContext.pageOptions;

    const handleChange = useCallback((event: any) => {
        const newValue = event.target.value;
        props.onChange(newValue);
    }, [props]);

    let linkType = false;
    if (formData?.links) {
        linkType = formData?.links[index]?.linkType === '_nav_';
    }
    else if (formData?.bannerData) {
        linkType = formData?.bannerData[index]?.linkType === '_nav_';
    }

    if (linkType) {
        return (
            <Select
                id={props.id}
                value={props.value}
                onChange={handleChange}
                variant="outlined"
                defaultValue={pageOptions[0]}
            >
                {pageOptions.map((option: any, i: number) => <MenuItem key={i} value={option}>{option}</MenuItem>)}
            </Select>
        );
    } else {
        return (
            <TextField
                id={props.id}
                label="Action"
                variant="outlined"
                value={props.value}
                onChange={handleChange}
            />
        );
    }
};

export { LinkTypeWidget };
