import {
    Button,
    CircularProgress,
    Grid,
    Container,
    Box,
    Typography,
    Modal,
    useTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import { useParams, Link, useNavigate, useLocation } from "react-router-dom";

import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { toast } from "react-toastify";

import { Form } from '@rjsf/mui';

import validatorAjv8 from "@rjsf/validator-ajv8";

import { JSONSchema7 } from "json-schema";

import { UiSchema } from "@rjsf/utils";

import { v7 as uuidv7 } from "uuid";

import useDataClient from "../../../axios/dataClient";

import { getApp } from "../../../services/api/FrontendService";

import { getAppConfigContent, postAppConfigContent  } from "../../../services/api/FrontendAppConfigService";

import { AppViewModel } from "../../../services/model/apps/AppViewModel";

import { SchemaType } from "../../../schemas/schemaTypes";

import { Schema, SmartPage } from "../../../schemas/smartPages/schema";

import { Schema as ProfileSchema } from "../../../schemas/profile/schema";

import smartPageSchemaModel from "../../../schemas/smartPages/smartPagesSchema.json";

import { MediaLibraryWidget } from "../customWidget/MediaLibrary/MediaLibraryWidget";

import { UndoButton } from "../UndoButton";

import { WarningModal } from "../../shared/WarningModal";

import { newSmartPage as newSmartPageName } from "../../newModuleNames";

import { SmartPageContext } from "./smartPagesContext";

import { AppSmartPageIntroBenForm } from "./AppSmartPageIntroBenForm";

import { AppSmartPageProfileForm } from "./AppSmartPageProfileForm";

import { AppSmartPageSlideForm } from "./AppSmartPageSlideForm";

import { AppPreview } from "../preview/AppPreview";

import { debounce } from "lodash";

import { getStagingUrl } from "../../../services/helper/regex";

export const AppSmartPageForm: React.FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();

    const { appId, smartPageName } = useParams();
    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const [schema, setSchema] = useState<Schema>();
    const [schemaPreview, setSchemaPreview] = useState<string>();
    const [profile, setProfile] = useState<ProfileSchema>();
    const [smartPage, setSmartPage] = useState<SmartPage>();
    const { get, post } = useDataClient();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [currentSmartPageName, setCurrentSmartPageName] = useState<string | undefined>(smartPageName);
    const [openEditProfilesModal, setOpenEditProfilesModal] = React.useState(false);
    const [openEditSlidesModal, setOpenEditSlidesModal] = React.useState(false);
    const [openEditIntroBensModal, setOpenEditIntroBensModal] = React.useState(false);
    const [warningModalOpen, setWarningModalOpen] = React.useState(false);
    const [showSmartPageReloadModal, setShowSmartPageReloadModal] = useState(false);
    const isDelete = location?.pathname?.endsWith("/delete");

    const handleWarningModalOpen = () => setWarningModalOpen(true);
    const handleWarningModalClose = () => {
        setWarningModalOpen(false);
        navigate(`/applications/${appId}/smartPages/${currentSmartPageName}`);
    };

    const customWidgets = useMemo(() => ({
        MediaLibraryWidget
    }), []);

    const uiSchema: UiSchema = {
        'ui:submitButtonOptions': {
            norender: true
        },
        "name": {
            "ui:readonly": !!smartPageName
        },
        "bbIntroImgUrl": {
            "ui:widget": "MediaLibraryWidget"
        },
        "bbIntroCopy": {
            "ui:widget": "textarea",
            "ui:options": {
                "rows": 3
            }
        },
        "ui:options": {
            classNames: "custom-class-title2"
        }
    };

    const navigateToDelete = () => {
        navigate(`/applications/${appId}/smartPages/${currentSmartPageName}/delete`);
    };

    useEffect(() => {
        if (isDelete) {
            handleWarningModalOpen();
        }
    }, [isDelete]);

    useEffect(() => {
        const fetchAppData = async () => {
            if (appId) {
                const data = await getApp(get)(appId);
                setAppData(data);
            }
        };

        fetchAppData();
    }, [appId, get]);

    const fetchData = React.useCallback(async () => {
        if (appId) {
            const smartPagesApiData = await getAppConfigContent<Schema>(get)(appId, SchemaType.Modal);
            const profileData = await getAppConfigContent<ProfileSchema>(get)(appId, SchemaType.Profile);

            setSchema(smartPagesApiData);
            setProfile(profileData);

            if (currentSmartPageName) {
                const smartPageData = smartPagesApiData?.smartPages?.find(p => p.name === currentSmartPageName);
                setSmartPage(smartPageData);
                setSchemaPreview(JSON.stringify(smartPagesApiData));
            }
            else {
                const newSmartPage: SmartPage = {
                    bbIntroBens: [],
                    bbSlides: [],
                    target: {
                        profile: []
                    }
                };

                setSmartPage(newSmartPage);
                setSchemaPreview(JSON.stringify(smartPagesApiData));
            }
        }
    }, [appId, get, currentSmartPageName]);

    useEffect(() => {
        if (smartPageName && !currentSmartPageName) {
            setCurrentSmartPageName(smartPageName);
        }
    }, [smartPageName, currentSmartPageName]);

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [appId, get, fetchData]);

    const handleSubmit = useCallback(async ({ formData }: any): Promise<void> => {
        if (appId) {
            setSubmitting(true);

            const isNewSmartPage = !smartPageName;

            const schemaData: Schema = !schema ? { smartPages: [] } : { ...schema };
            const newSmartPage = formData as SmartPage;

            if (newSmartPage.name?.toUpperCase() === newSmartPageName.toUpperCase()) {
                toast.error(`Smart Page name cannot be ${newSmartPageName}`);
                setSubmitting(false);
                return;
            }

            const smartPageDataIndex = schemaData.smartPages.findIndex(p => p.name?.toUpperCase() === newSmartPage.name?.toUpperCase());
            if (smartPageDataIndex >= 0) {

                let latestSmartPageHash: string | undefined;

                if (!isNewSmartPage) {
                    const latestSmartPageData = await getAppConfigContent<Schema>(get)(appId, SchemaType.Modal);
                    const lastestSmartPageData = latestSmartPageData.smartPages.find(p => p.name?.toUpperCase() === newSmartPage.name?.toUpperCase());

                    if (lastestSmartPageData) {
                        latestSmartPageHash = lastestSmartPageData.savedHash;

                        if (latestSmartPageHash !== newSmartPage.savedHash) {
                            setShowSmartPageReloadModal(true);
                            setSubmitting(false);
                            return;
                        }
                    }
                }
                else {
                    toast.error("Smart Page with the same name already exists");
                    setSubmitting(false);
                    return;
                }

                newSmartPage.savedHash = uuidv7();
                schemaData.smartPages.splice(smartPageDataIndex, 1, newSmartPage);
            }
            else {
                newSmartPage.savedHash = uuidv7();
                schemaData.smartPages.push(newSmartPage);
            }

            postAppConfigContent<Schema>(post)(appId, SchemaType.Modal, {
                configDocument: schemaData,
                message: "Updated Smart Pages"
            }).then(() => {
                toast.success("Smart Pages settings updated");
                if (isNewSmartPage) {
                    navigate(`/applications/${appId}/smartPages/${newSmartPage.name}`);
                }

                setSchema(schemaData);
                setSmartPage(newSmartPage);

                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }, [post, appId, navigate, smartPageName, schema, get]);

    const handleError = useCallback((e: any): void => {
        console.log("Data changed: ", e.formData);
    }, []);

    const handleUndo = useCallback(async (): Promise<void> => {
        setSubmitting(true);
        fetchData().then(() => {
            toast.warning("Smart Page data has been reset");
            return;
        }).finally(() => {
            setSubmitting(false);
        });
    }, [fetchData]);

    const handleDelete = useCallback(async (): Promise<boolean> => {
        if (appId && currentSmartPageName) {
            setSubmitting(true);
            const schemaData: Schema = !schema ? { smartPages: [] } : { ...schema };

            const smartPageDataIndex = schemaData.smartPages.findIndex(p => p.name === currentSmartPageName);
            if (smartPageDataIndex >= 0) {
                schemaData.smartPages.splice(smartPageDataIndex, 1);
            }

            return postAppConfigContent<Schema>(post)(appId, SchemaType.Modal, {
                configDocument: schemaData,
                message: "Removed Smart Page"
            }).then(() => {
                toast.success("Smart Page has been deleted");
                navigate(`/applications/${appId}/smartPages`);
                return true;
            }).finally(() => {
                setSubmitting(false);
            });
        }

        return false;
    }, [appId, currentSmartPageName, post, navigate, schema]);

    const handleConfirmDelete = useCallback(async (): Promise<void> => {
        const isDeleted = await handleDelete();
        setWarningModalOpen(false);
        if (!isDeleted) {
            navigate(`/applications/${appId}/smartPages/${currentSmartPageName}`);
        }
    }, [navigate, handleDelete, appId, currentSmartPageName]);

    const handleModalClose = React.useCallback(() => {
        return;
    }, []);

    const handleProfilesModalCancel = React.useCallback((): void => {
        setOpenEditProfilesModal(false);
    }, []);

    const handleProfilesModalOpen = React.useCallback(() => {
        setOpenEditProfilesModal(true);
    }, []);

    const handleProfilesModalUpdate = React.useCallback((e: any): void => {
        const newSmartPageProfile = e.formData as SmartPage;
        const newSmartPage = { ...smartPage };
        if (newSmartPageProfile?.target?.profile && newSmartPage) {
            if (!newSmartPage.target) {
                newSmartPage.target = {};
            }
            newSmartPage.target.profile = newSmartPageProfile.target.profile;
            newSmartPage.lastUpdated = new Date().toISOString();
            setSmartPage(newSmartPage);
        }
        setOpenEditProfilesModal(false);
    }, [smartPage]);

    const handleSlidesModalCancel = React.useCallback((): void => {
        setOpenEditSlidesModal(false);
    }, []);

    const handleSlidesModalOpen = React.useCallback(() => {
        setOpenEditSlidesModal(true);
    }, []);

    const handleSlidesModalUpdate = React.useCallback((e: any): void => {
        const newSmartPageSlides = e.formData as SmartPage;
        const newSmartPage = { ...smartPage };
        if (newSmartPageSlides && newSmartPage) {

            newSmartPage.bbSlides = newSmartPageSlides.bbSlides;
            newSmartPage.lastUpdated = new Date().toISOString();
            setSmartPage(newSmartPage);
        }
        setOpenEditSlidesModal(false);
    }, [smartPage]);

    const handleIntroBensModalCancel = React.useCallback((): void => {
        setOpenEditIntroBensModal(false);
    }, []);

    const handleIntroBensModalOpen = React.useCallback(() => {
        setOpenEditIntroBensModal(true);
    }, []);

    const handleIntroBensModalUpdate = React.useCallback((e: any): void => {
        const newSmartPageIntroBens = e.formData as SmartPage;
        const newSmartPage = { ...smartPage };
        if (newSmartPageIntroBens && newSmartPage) {

            newSmartPage.bbIntroBens = newSmartPageIntroBens.bbIntroBens;
            newSmartPage.lastUpdated = new Date().toISOString();
            setSmartPage(newSmartPage);
        }
        setOpenEditIntroBensModal(false);
    }, [smartPage]);

    const handleShowSmartPageReloadModalClose = () => {
        setShowSmartPageReloadModal(false);
    };

    const handleShowSmartPageReloadModalProceed = () => {
        setShowSmartPageReloadModal(false);
        handleUndo();
    };

    const debouncedSetSmartPagePreview = useRef(debounce(async (data: any) => {
        setSchemaPreview(JSON.stringify(data));
    }, 3000)).current;

    const onChange = useCallback(async (e: any) => {
        if (appId && currentSmartPageName) {
            const schemaData: Schema = !schema ? { smartPages: [] } : { ...schema };
            const smartPageDataIndex = schemaData.smartPages.findIndex(p => p.name === e.formData.name);
            const newSmartPage = e.formData as SmartPage;
            schemaData.smartPages.splice(smartPageDataIndex, 1, newSmartPage);
            debouncedSetSmartPagePreview(schemaData);
            setSchema(schemaData);
        }
    }, [appId, debouncedSetSmartPagePreview, schema, currentSmartPageName]);

    if (!appData || !smartPage || !profile) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <SmartPageContext.Provider
            value={{
                profileData: profile
            }}
        >
            <Box className="bb-pe-fab">
                {currentSmartPageName &&
                    <AppPreview page={schemaPreview} pageName={currentSmartPageName} queryParamName={"modal"} />
                }
            </Box>
            <Modal
                open={openEditProfilesModal}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ "& .MuiBox-root": { backgroundColor: theme.palette.background.default } }}
            >
                <AppSmartPageProfileForm
                    currentSmartPage={smartPage}
                    onUpdate={handleProfilesModalUpdate}
                    onError={handleError}
                    onCancel={handleProfilesModalCancel}
                />
            </Modal>
            <Modal
                open={openEditSlidesModal}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ "& .MuiBox-root": { backgroundColor: theme.palette.background.default } }}
            >
                <AppSmartPageSlideForm
                    currentSmartPage={smartPage}
                    onUpdate={handleSlidesModalUpdate}
                    onError={handleError}
                    onCancel={handleSlidesModalCancel}
                />
            </Modal>
            <Modal
                open={openEditIntroBensModal}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ "& .MuiBox-root": { backgroundColor: theme.palette.background.default } }}
            >
                <AppSmartPageIntroBenForm
                    currentSmartPage={smartPage}
                    onUpdate={handleIntroBensModalUpdate}
                    onError={handleError}
                    onCancel={handleIntroBensModalCancel}
                />
            </Modal>
            <Dialog
                open={showSmartPageReloadModal}
            >
                <DialogTitle id="alert-dialog-title" className="bb-error-dialog-header bb-flex bb-align-items-center">
                    <WarningAmberIcon className="bb-mr-1"></WarningAmberIcon> <h2 className="bb-m0 bb-p0">{"WARNING!"}</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="bb-tac">
                        <h3>
                            Another user has recently updated this Smart Page. Please reload the page to get the most recent smart page data and try again.
                        </h3>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleShowSmartPageReloadModalClose}>
                        Close
                    </Button>
                    <Button onClick={handleShowSmartPageReloadModalProceed} className="bb-ml-auto">
                        Reload Smart Page Data
                    </Button>
                </DialogActions>
            </Dialog>
            <Container className="bb-page-editor-container">
                <Box className="bb-title-bar bb-mt-3">
                    <h2 className="bb-m0 bb-p0">Smart Pages: {appData.displayName}</h2>
                    <Box className="bb-ml-auto bb-align-items-center bb-flex">
                        <OpenInNewIcon className="bb-open-app-link"></OpenInNewIcon>
                        <a rel="noreferrer" className="bb-mr-1" href={`https://${getStagingUrl(appData.url)}/?modal=${smartPageName}`} target="_blank">View my
                        Staging App</a>
                        <OpenInNewIcon className="bb-open-app-link"></OpenInNewIcon>
                        <a rel="noreferrer" className="bb-mr-1" href={`https://${appData.url}/?modal=${smartPageName}`} target="_blank">View my
                        Live App</a>
                    </Box>
                </Box>
                <Breadcrumbs className="bb-mb-3 bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                    <Link to="/applications">Applications</Link>
                    <Link to={`/applications/${appId}`}>Modules</Link>
                    <Link to={`/applications/${appId}/smartPages`}>Smart Pages</Link>
                    <Typography color="text.primary" className="bb-m0 bb-p0">{smartPageName}</Typography>
                </Breadcrumbs>
                <Box className="bb-flex bb-ui-box bb-flex-column bb-tac" sx={{ minHeight: "60vh" }}>
                    <Box className="bb-page-edit-cont">
                        {submitting && <Box className="bb-tac"><CircularProgress /></Box>}
                        <Box className="bb-flex bb-flex-column bb-justify-content-center bb-tac" sx={{ minHeight: "60vh" }}>
                            <Box className="bb-flex page-edit-buttons">
                                <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom alignContent="left">
                                    Smart Page Editor
                                </Typography>
                                <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/applications/${appId}/smartPages`}>
                                    <Button variant="outlined" startIcon={<KeyboardArrowLeftIcon />} disabled={submitting}>
                                        Smart Pages
                                    </Button>
                                </Link>
                                {currentSmartPageName &&
                                    <>
                                        <Grid item xs={1}>
                                            <Button
                                                onClick={navigateToDelete}
                                                variant="outlined"
                                                startIcon={<DeleteOutlineIcon />}
                                                color="error"
                                                disabled={submitting}>
                                                    Delete
                                            </Button>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <UndoButton onClick={handleUndo} disable={submitting}></UndoButton>
                                        </Grid>
                                    </>
                                }
                                <Button variant="contained" startIcon={<SaveOutlinedIcon />} disabled={submitting} type="submit" form="smartPage_pagesubmit">
                                    Save
                                </Button>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="bb-sp-cont">
                                    <Form
                                        id={"smartPage_pagesubmit"}
                                        schema={smartPageSchemaModel as JSONSchema7}
                                        formData={smartPage}
                                        onChange={onChange}
                                        validator={validatorAjv8}
                                        onSubmit={handleSubmit}
                                        onError={handleError}
                                        uiSchema={uiSchema}
                                        widgets={customWidgets}
                                        liveValidate={true}
                                        showErrorList={false}
                                        disabled={submitting}
                                    />
                                    {currentSmartPageName &&
                                        <Typography sx={{ fontSize: 14, fontWeight: "bold" }} color="text.secondary" gutterBottom alignContent="left">
                                            {smartPage.target?.profile?.length || 0} profiles
                                            <Button
                                                onClick={handleProfilesModalOpen}
                                                variant="outlined"
                                                disabled={submitting}
                                                size="small"
                                                sx={{ marginLeft: 2, marginRight: 2 }}>
                                                Edit Profiles
                                            </Button>
                                            {smartPage.bbSlides?.length || 0} slides
                                            <Button
                                                onClick={handleSlidesModalOpen}
                                                variant="outlined"
                                                disabled={submitting}
                                                size="small"
                                                sx={{ marginLeft: 2, marginRight: 2 }}>
                                                Edit Slides
                                            </Button>
                                            {smartPage.bbIntroBens?.length || 0} intro bens
                                            <Button
                                                onClick={handleIntroBensModalOpen}
                                                variant="outlined"
                                                disabled={submitting}
                                                size="small"
                                                sx={{ marginLeft: 2, marginRight: 2 }}>
                                                Edit Intro Bens
                                            </Button>
                                        </Typography>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <WarningModal
                open={warningModalOpen}
                description={"Are you sure you want to perform this action?"}
                onConfirm={handleConfirmDelete}
                onCancel={handleWarningModalClose}
            />
        </SmartPageContext.Provider>);
};
