import { WithSessionDay } from "../model/statistics/BotStats";
import { HomePageData } from "../model/statistics/HomePageData";
import { StatisticsData } from "../model/statistics/StatisticsData";
import { TotalUsers } from "../model/statistics/TotalUsers";
import { UsersByBot } from "../model/statistics/UsersByBot";
import { UsersByBotByDate } from "../model/statistics/UsersByBotByDate";
import { UsersByDate } from "../model/statistics/UsersByDate";

export const getTotalUsers = (getFunction: <T>(url: string) => Promise<T>) =>
    getFunction<StatisticsData<TotalUsers>>("/api/statistics/totalUsers");

export const getUsersByBot = (getFunction: <T>(url: string) => Promise<T>) =>
    getFunction<StatisticsData<UsersByBot>>("/api/statistics/usersByBot");

export const getUsersByDate = (getFunction: <T>(url: string) => Promise<T>) =>
    getFunction<StatisticsData<UsersByDate>>("/api/statistics/usersByDate").then(datifyResult);

export const getUsersByBotByDate = (getFunction: <T>(url: string) => Promise<T>) =>
    getFunction<StatisticsData<UsersByBotByDate>>("/api/statistics/usersByBotByDate").then(datifyResult);

export const getHomePage = (getFunction: <T>(url: string) => Promise<T>) =>
    getFunction<HomePageData>("/api/statistics/homePage");

export const getAnalyticsData = (getFunction: <T>(url: string) => Promise<T>, appName: string, fromDate: string, isProductionDomains: boolean) => {
    return getFunction<any>(
        `/api/legacyapp/analyticsquery?app_name=${appName}&query_id=customerportal&query_period_seconds=${fromDate}&production=${isProductionDomains}`);
};

export const getAnalyticsKpiData = (getFunction: <T>(url: string) => Promise<T>, fromDate: string, isProductionDomains: boolean) => {
    return getFunction<any>(
        `/api/legacyapp/analyticsquery?query_id=kpi&query_period_seconds=${fromDate}&production=${isProductionDomains}`);
};

function datifyResult<T>(input: StatisticsData<T>): StatisticsData<T> {
    return ({
        queryTime: input.queryTime,
        data: input.data.map<T>(datifyPoint)
    });
}

function datifyPoint<T>(point: T): T {
    const sessionDay = new Date((point as any as WithSessionDay).sessionDay);
    return { ...point, sessionDay } as any as T;
}

