import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControlLabel,
    FormGroup,
    LinearProgress,
    Switch,
    Tooltip,
    Grid
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import { BotListModel } from "../../services/model/bot";

interface BotCardProps {
    isEnabled: boolean;
    isTraining: boolean;
    handleIsEnabledChange: (botId: string, isEnabled: boolean) => Promise<void>;
    handleBotUpdated: (botId: string, newBot: Partial<BotListModel>) => void;
    botName: string;
    views?: number;
    visitsInLast24Hours?: number;
    botId: string;
    avatarSrc?: string | null;
    isEnabledToggleDisabled: boolean;
}

export const BotCard = ({
    isEnabled,
    isTraining,
    botName,
    views,
    visitsInLast24Hours,
    botId,
    avatarSrc,
    handleIsEnabledChange,
    isEnabledToggleDisabled
}: BotCardProps) => {

    const [trainingState, setTrainingState] = useState(isTraining);
    const [progress, setProgress] = useState(0);
    const [progressInterval, setProgressInterval] = useState<NodeJS.Timer | null>(null);

    useEffect(() => {
        if (isTraining !== trainingState) {
            setTrainingState(isTraining);
            // training has completed
            if (!isTraining) {
                toast.success("Training has completed");
                if (progressInterval !== null) {
                    clearInterval(progressInterval);
                    setProgressInterval(null);
                }
                setProgress(0);
            }
        }
    }, [trainingState, isTraining, progressInterval]);

    useEffect(() => {
        return () => {
            if (progressInterval) {
                clearInterval(progressInterval);
                setProgressInterval(null);
                setProgress(0);
            }
        };
    }, [progressInterval]);

    useEffect(() => {
        if (isTraining) {
            if (progressInterval === null) {
                setProgress(0);
                setProgressInterval(() => {
                    return setInterval(() => {
                        setProgress(p => {
                            if (p < 95) {
                                return p + 0.2;
                            }
                            return p;
                        });
                    }, 1000);
                });
            }
        }
    }, [isTraining, progressInterval]);

    const [open, setOpen] = useState(false);
    const [desiredState, setDesiredState] = useState(isEnabled);

    const handleOpen = (newState: boolean) => () => {
        setDesiredState(newState);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirm = async () => {
        await handleIsEnabledChange(botId, desiredState);
        handleClose();
    };

    const handleSwitchChange = handleOpen(!isEnabled);
    const handleButtonClick = handleOpen(!isEnabled);
    const bbhandleClose = handleClose;
    const bbhandleConfirm = handleConfirm;

    return (
        <Card data-testid="bot-card">
            <CardHeader
                avatar={<Avatar src={avatarSrc ?? undefined} />}
                title={botName}
                className="bot-card-header"
            />
            {isTraining ? <LinearProgress variant="determinate" color="secondary" value={progress} className="bot-progress" /> :
                <Box className="bot-progress" />}
            <CardContent sx={{ textAlign: "center", verticalAlign: "middle" }}>
                <Grid container spacing={1} className="bb-app-icon-stats bb-p0">
                    <Grid item xs={6}>
                        <div className="bb-app-icon-description">
                            <h2 className="fontSize2">{views}{" "}</h2>
                            <p className="bb-m0 bb-p0">Messages</p>
                            <p className="bb-small-text bb-m0 bb-p0">(Last month)</p>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="bb-app-icon-description">
                            <h2 className="fontSize2">{visitsInLast24Hours}{" "}</h2>
                            <p className="bb-m0 bb-p0">Messages</p>
                            <p className="bb-small-text bb-m0 bb-p0">(Sent yesterday)</p>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions disableSpacing className="bot-card-actions">
                <FormGroup>
                    <Tooltip title="Warning! This will enable / disable your bot...">
                        <FormControlLabel
                            control={
                                <Switch
                                    onClick={handleButtonClick}
                                    checked={isEnabled}
                                    size="small"
                                    onChange={handleSwitchChange}
                                    disabled={isEnabledToggleDisabled}
                                />
                            }
                            label={isEnabled ? "Active" : "Inactive"}
                        /></Tooltip>
                </FormGroup>
                <Button variant="contained"
                    component={Link}
                    to={`/bots/${botId}`}
                    sx={{ marginLeft: "auto" }}
                >
                    View
                </Button>
            </CardActions>
            <Dialog
                open={open}
                onClose={bbhandleClose}
            >
                <DialogTitle id="alert-dialog-title" className="bb-error-dialog-header bb-flex bb-align-items-center">
                    <WarningAmberIcon className="bb-mr-1"></WarningAmberIcon> <h2 className="bb-m0 bb-p0">{"WARNING!"}</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="bb-tac">
                        <h2>Are you really sure?</h2>
                        {isEnabled ? "This will disable your bot" : "This will enable your bot"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={bbhandleClose}>
                        Cancel
                    </Button>
                    <Button onClick={bbhandleConfirm} className="bb-ml-auto">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};
