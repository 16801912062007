import { createTheme } from "@mui/material/styles";

const PrimaryColour = "#11a681";
const SecondaryColour = "#25454d";
const TetriaryColour = "#1b3240";
const ErrorColour = "#d9534f";

const BackgroundColour = "#F8F7FF";
const PaperColour = "#FFFFFF";
const BorderColour = "#cecece";

const PrimaryTextColour = TetriaryColour;
const SecondaryTextColour = "rgba(27,50,64,0.8)";
const DisabledTextColour = BorderColour;

export const DefaultBorderWidth = "1px";

export let theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: PrimaryColour,
        },
        secondary: {
            main: SecondaryColour,
        },
        background: {
            default: BackgroundColour,
            paper: PaperColour,
        },
        text: {
            primary: PrimaryTextColour,
            secondary: SecondaryTextColour,
            disabled: DisabledTextColour,
        },
        error: {
            main: ErrorColour,
        },
        divider: BorderColour,
        warning: {
            main: ErrorColour,
        }
    },

    typography: {
        fontSize: 16,
        h1: {
            fontSize: "2rem",
            lineHeight: 1.2,
            letterSpacing: "0em",
            fontWeight: 400,
        },
        h2: {
            fontSize: "1.5rem",
            letterSpacing: "0em",
            fontWeight: 400,
        },
        h3: {
            lineHeight: 1.2,
            fontWeight: 400,
            fontSize: "1.2rem",
        },
        h4: {
            fontSize: "1rem",
            lineHeight: 1.2,
            letterSpacing: "0em",
            fontWeight: 500,
        },
        h5: {
            fontSize: "0.8rem",
            lineHeight: 1.2,
            letterSpacing: "0em",
            fontWeight: 700,
        },
        h6: {
            fontWeight: 500,
            fontSize: "0.8rem",
            lineHeight: 1.2,
            letterSpacing: "0em",
        },
        body1: {
            fontSize: "0.9rem",
            fontWeight: 400,
            lineHeight: 1.2,
            letterSpacing: "0em",
        },
        body2: {
            lineHeight: 1.4,
            letterSpacing: "0.02em",
        },
        caption: {
            lineHeight: 1.2,
            letterSpacing: "0em",
        },
        button: {
            fontWeight: 400,
            lineHeight: 1.2,
            letterSpacing: "0em",
        },
    },

    shape: {
        borderRadius: 8,
    },

});

theme = createTheme(theme, {
    components: {
        MuiCard: {
            defaultProps: {
                elevation: 4,
            },
            styleOverrides: {
                root: {
                    background: PaperColour,
                }
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorDefault: {
                    defaultProps: {
                        elevation: 4,
                    },
                    backgroundColor: TetriaryColour,
                    borderRadius: theme.shape.borderRadius,
                }
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(1, 2),
                    boxShadow: "none"
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 4,
            },
            styleOverrides: {
                outlined: {
                    borderWidth: DefaultBorderWidth,
                }
            }
        }
    },
});

export const TopBarHeight = theme.spacing(7.5);
export const FooterHeight = "32px";
export const MainContainerTopMargin = "8px";
export const BreadcrumbsBarHeight = "54px";
export const PaperColor = "#fff";
export const beeBotTitleLogo = ("../../img/beebot-logo.png");
export const softHover = "#f6f9ff";
