import { Box, Paper, Typography, Button } from "@mui/material";
import { useFormikContext } from "formik";

import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { Check, Clear } from "@mui/icons-material";

import { error } from "../ErrorDisplay";
import { AssetUploadType } from "../../yup-schemas/assetSchema";

interface UploadFileProps {
    validFileTypes: string[];
    isSingleFile: boolean;
}

const checkExtension = (extension: string) => {
    return (file: File) => {
        const fileNameParts = file.name.split(".");
        return extension === fileNameParts[fileNameParts.length - 1];
    };
};

export const UploadFile = ({ validFileTypes, isSingleFile }: UploadFileProps) => {
    const { setFieldValue, values } = useFormikContext<AssetUploadType>();

    const onDrop = useCallback((files: File[]) => {
        let validFiles = files.filter(droppedFile => validFileTypes.some(validType => droppedFile.name.endsWith(validType)));
        if (isSingleFile && values.extensions && values.extensions.length > 0) {
            validFiles = validFiles.filter(checkExtension(values.extensions[0]));
        }
        if (validFiles.length === 0) {
            error("Must chose a valid file");
            return;
        }
        if (validFiles.length > 100) {
            error("Max 100 files can be uploaded at a time");
        }
        const fileNameParts = validFiles.map(file => file.name.split("."));
        setFieldValue("files", validFiles);
        setFieldValue("extensions", fileNameParts.map(fileNamePart => fileNamePart[fileNamePart.length - 1]));
        setFieldValue("fileNames", fileNameParts.map(fileNamePart => fileNamePart.slice(0, -1).join(".")));

        return;
    }, [setFieldValue, validFileTypes, values.extensions, isSingleFile]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop
    });

    return (
        <Box>
            <Paper className="bb-drag-drop" sx={{ marginTop: "1em", padding: "1em", display: "flex" }}>
                {values.files.length > 0 ? <Check /> : <Clear />}
                <div className="bb-drag-input"  {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? <Box>
                        <Typography>
                            DROP HERE
                        </Typography>
                    </Box> : <Box>
                        <Button variant="outlined">Select</Button>
                        <Typography>DRAG AND DROP</Typography>
                    </Box>}
                </div>
            </Paper>
        </Box>
    );
};
