const getPageContent = <schema>(get: <T>(url: string) => Promise<T>) => async (appId: string, pageName: string) => {
    const result = await get<schema>(`/api/frontendPage/${appId}/page/${pageName}`);
    return result;
};

const getPages = <schema>(get: <T>(url: string) => Promise<T>) => async (appId: string) => {
    const result = await get<schema>(`/api/frontendPage/${appId}/pages`);
    return result;
};

const getPageNames = <schema>(get: <T>(url: string) => Promise<T>) => async (appId: string) => {
    const result = await get<schema>(`/api/frontendPage/${appId}/pagenames`);
    return result;
};

const deletePage = <schema>(deleteRequest: <T>(url: string) => Promise<T>) => async (appId: string, pageName: string) => {
    const result = await deleteRequest<schema>(`/api/frontendPage/${appId}/page/${pageName}`);
    return result;
};

const upsertPageContent = <schema>(post: <T>(url: string, body: any) => Promise<T>) => async (appId: string, fileName: string, body: any) => {
    const result = await post<schema>(`/api/frontendPage/${appId}/${fileName}`, body);
    return result;
};

export { getPageContent, upsertPageContent, getPages, getPageNames, deletePage };
