import {
    Button,
    CircularProgress,
    Typography,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { Form } from '@rjsf/mui';

import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";

import { toast } from "react-toastify";

import { JSONSchema7 } from "json-schema";

import validatorAjv8 from "@rjsf/validator-ajv8";

import { UiSchema } from "@rjsf/utils";

import { v7 as uuidv7 } from "uuid";

import useDataClient from "../../../axios/dataClient";

import { convertDocumentToCss, getThemeContent, upsertThemeContent } from "../../../services/api/ThemesService";

import { Schema } from "../../../schemas/theme/schema";

import schemaModel from "../../../schemas/theme/themeSchema.json";

import { UndoButton } from "../UndoButton";

import SaveIcon from '@mui/icons-material/Save';

import { convert3HexTo6Hex } from "./themeHelper";
import {
    botUiSchema,
    clientBrandUiSchema,
    customCssUiSchema,
    headerUiSchema,
    mainUiSchema,
    popupsUiSchema,
    smartPagesUiSchema,
    savedHashUiSchema
} from "./themeUiSchema";

import { ColourPicker } from "../customWidget/ColourPicker/ColourPicker";

import { AppPreview } from "../preview/AppPreview";

import { getPageContent } from "../../../services/api/PagesService";

import { Schema as pageSchema } from "../../../schemas/pages/schema";

export interface AppThemeFormProps {
    appId: string | undefined;
}

export const AppThemeForm: React.FC<AppThemeFormProps> = ({ appId }) => {
    const [theme, setTheme] = useState<Schema | null>(null);
    const [themeCss, setThemeCss] = useState<string>();
    const { get, post } = useDataClient();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [homePage, setHomePage] = useState<pageSchema>();
    const [showThemeReloadModal, setShowThemeReloadModal] = useState(false);

    const customWidgets = useMemo(() => ({
        ColourPicker
    }), []);

    const uiSchema: UiSchema = {
        "ui:submitButtonOptions": {
            norender: true
        },
        "clientBrand": { ...clientBrandUiSchema },
        "header": { ...headerUiSchema },
        "main": { ...mainUiSchema },
        "popups": { ...popupsUiSchema },
        "smartPages": { ...smartPagesUiSchema },
        "bot": { ...botUiSchema },
        "customCss": { ...customCssUiSchema },
        "savedHash": { ...savedHashUiSchema },
    };

    const formatColorValues = React.useCallback((schemaData: Schema) => {
        return convert3HexTo6Hex(schemaData);
    }, []);

    const fetchData = React.useCallback(async () => {
        if (appId) {
            const themeData = await getThemeContent<Schema>(get)(appId);
            const initializedTheme: Schema = {
                ...themeData,
                bot: themeData.bot || {},
                clientBrand: themeData.clientBrand || {},
                header: themeData.header || {},
                main: themeData.main || {},
                popups: themeData.popups || {},
                smartPages: themeData.smartPages || {},
                customCss: themeData.customCss || {},
                savedHash: themeData.savedHash || {}
            };

            if (initializedTheme) {
                formatColorValues(initializedTheme);
            }

            setTheme(initializedTheme);

            const pageData = await getPageContent<pageSchema>(get)(appId, "home");
            setHomePage({ ...pageData });
        }
    }, [appId, get, formatColorValues]);

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [appId, get, fetchData]);

    const handleSubmit = useCallback(async ({ formData }: any) => {
        if (appId) {
            setSubmitting(true);

            const latestThemeData = await getThemeContent<Schema>(get)(appId);
            const latestThemeHash = latestThemeData?.savedHash?.guid;

            if (latestThemeHash !== (formData as Schema)?.savedHash?.guid) {
                setShowThemeReloadModal(true);
                setSubmitting(false);
                return;
            }

            const newThemeData: Schema = {
                ...formData,
                savedHash: {
                    guid: uuidv7()
                }
            };

            setTheme(newThemeData);
            upsertThemeContent<Schema>(post)(appId, {
                configDocument: newThemeData,
                message: "Updated theme settings"
            }).then(() => {
                toast.success("Theme settings updated");
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }, [post, appId, get]);

    const handleUndo = useCallback(async (): Promise<void> => {
        setSubmitting(true);
        fetchData().then(() => {
            toast.warning("Theme data has been reset");
            return;
        }).finally(() => {
            setSubmitting(false);
        });
    }, [fetchData]);

    const debounce = (func: any, delay: number) => {
        let timeoutId: NodeJS.Timeout;
        return (...args: any[]) => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => func(...args), delay);
        };
    };

    const debouncedConvertToCss = useRef(debounce(async (id: string, data: any) => {
        const themeDataCss = await convertDocumentToCss<string>(post)(id, {
            ...data
        });
        setThemeCss(themeDataCss);
    }, 3000)).current;

    const onChange = useCallback(async (e: any) => {
        if (appId) {
            setTheme(e.formData);
            debouncedConvertToCss(appId, theme);
        }
    }, [appId, debouncedConvertToCss, theme]);

    const handleShowThemeReloadModalClose = () => {
        setShowThemeReloadModal(false);
    };

    const handleShowThemeReloadModalProceed = () => {
        setShowThemeReloadModal(false);
        handleUndo();
    };

    if (!theme) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <Box className="bb-page-editor-form-container">
            <Dialog
                open={showThemeReloadModal}
            >
                <DialogTitle id="alert-dialog-title" className="bb-error-dialog-header bb-flex bb-align-items-center">
                    <WarningAmberIcon className="bb-mr-1"></WarningAmberIcon> <h2 className="bb-m0 bb-p0">{"WARNING!"}</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="bb-tac">
                        <h3>
                            Another user has recently updated your Theme. Please reload the page to get the most recent theme data and try again.
                        </h3>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleShowThemeReloadModalClose}>
                        Close
                    </Button>
                    <Button onClick={handleShowThemeReloadModalProceed} className="bb-ml-auto">
                        Reload Theme Data
                    </Button>
                </DialogActions>
            </Dialog>
            {submitting && <Box className="bb-tac"><CircularProgress /></Box>}
            <Box className="bb-pe-fab">
                {homePage &&
                    <AppPreview page={homePage} pageName={"home"} theme={themeCss} />
                }
            </Box>
            <Box className="bb-flex bb-flex-column bb-justify-content-center" sx={{ minHeight: "60vh" }}>
                <Box className="bb-flex bb-m0 bb-p0">
                    <Box>
                        <Typography sx={{ fontSize: 18, fontWeight: "bold", marginTop: ".5rem" }} color="text.secondary" gutterBottom alignContent="left">
                            Theme
                        </Typography>
                    </Box>
                    <Box className="bb-ml-auto">
                        <UndoButton onClick={handleUndo} disable={submitting}></UndoButton>
                        <Button
                            variant="contained"
                            startIcon={<SaveIcon></SaveIcon>}
                            disabled={submitting}
                            type="submit"
                            form="theme_pagesubmit"
                            className="bb-ml-1">
                            Save
                        </Button>
                    </Box>
                </Box>
                <Form
                    id="theme_pagesubmit"
                    className="bb-page-editor-form bb-m0 bb-drop-form-group-padding"
                    schema={schemaModel as JSONSchema7}
                    formData={theme}
                    onChange={onChange}
                    validator={validatorAjv8}
                    onSubmit={handleSubmit}
                    uiSchema={uiSchema}
                    liveValidate={true}
                    showErrorList={false}
                    disabled={submitting}
                    widgets={customWidgets}
                />
            </Box>
        </Box>);
};
