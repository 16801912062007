import { Card, CardContent, CardHeader, Typography } from "@mui/material";

import { LoadingCard } from "../../shared/LoadingCard";

interface ActiveLiveAgentsProps {
    isLoading: boolean;
    liveAgentsCount: number;
}

export const ActiveLiveAgents = ({ isLoading, liveAgentsCount }: ActiveLiveAgentsProps) => {
    if (isLoading) {
        return (<LoadingCard title="Active Agents" />);
    }

    return (
        <Card>
            <CardHeader title="Active Agents" />
            <CardContent>
                <Typography variant="h1" textAlign="center">
                    {liveAgentsCount}
                </Typography>
            </CardContent>
        </Card>);
};
