import { Badge, Button, SxProps } from "@mui/material";
import { useCallback, useState } from "react";

import { LiveAgentQueueItem } from "./liveAgentQueueItem";

import { QueueDialog } from ".";

interface LiveChatQueueButtonProps {
    chatDequeuedCallback: (item: LiveAgentQueueItem) => void;
    sx: SxProps;
}

export const LiveChatQueueButton = ({ sx, chatDequeuedCallback }: LiveChatQueueButtonProps) => {
    const [queueDialogOpen, setQueueDialogOpen] = useState(false);
    const [queueItemCount, setQueueItemCount] = useState(0);

    const showQueueDialog = useCallback(() => {
        setQueueDialogOpen(true);
    }, []);
    const closeDialog = useCallback(() => {
        setQueueDialogOpen(false);
    }, []);

    const onItemSelected = useCallback(() => {
        setQueueDialogOpen(false);
    }, []);

    const updateQueueItemCount = useCallback((value: number) => {
        setQueueItemCount(value);
    }, []);

    return (<>
        <Badge badgeContent={queueItemCount} color="secondary">
            <Button variant="outlined"
                fullWidth
                onClick={showQueueDialog}
                sx={sx}>
    Queue
            </Button>
        </Badge>
        <QueueDialog
            open={queueDialogOpen}
            onClose={closeDialog}
            onItemSelected={onItemSelected}
            chatDequeuedCallback={chatDequeuedCallback}
            updateQueueItemCount={updateQueueItemCount} />
    </>);
};
