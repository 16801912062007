import { useParams, Link } from "react-router-dom";

import Typography from "@mui/material/Typography";

import {
    Container,
    Box,
    Alert,
    CircularProgress,
    Button,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead
} from "@mui/material";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { useState, useEffect, useCallback } from "react";

import useDataClient from "../../../axios/dataClient";

import { getApp } from "../../../services/api/FrontendService";

import { getPages } from "../../../services/api/PagesService";

import { AppViewModel } from "../../../services/model/apps/AppViewModel";

import { stringSort } from "../../../services/helper/sort";

import { SearchField } from "../customWidget/Search/SearchField";

import { PageFile, Schema } from "../../../schemas/pages/schema";

import { DateDisplay } from "../../shared/DateDisplay";

import { newPage as newPageName } from "../../newModuleNames";
import { getStagingUrl } from "../../../services/helper/regex";

export const AppPages = () => {
    const { appId } = useParams();
    const { get } = useDataClient();

    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const [pageFiles, setPageFiles] = useState<PageFile[] | undefined>();
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchString, setSearchString] = useState<string>();
    const [filteredPages, setFilteredPages] = useState<Schema[] | undefined>();

    const callSearch = useCallback((search?: string) => {
        setSearchString(search);
    }, [setSearchString]);

    useEffect(() => {
        setSearchString("");
    }, []);

    const getFilteredPages = useCallback((filteredPageFiles: PageFile[]) => {
        return filteredPageFiles.map((pageFile) => {
            const schemaData = JSON.parse(pageFile.content);
            schemaData.pageName = pageFile.name;
            return schemaData;
        });
    }, []);

    useEffect(() => {
        if (!pageFiles) {
            return;
        }

        const searchStringUpper = searchString?.toUpperCase();
        setCurrentPage(0);
        const filtered = pageFiles.filter((pageFile) =>
            !searchStringUpper || (pageFile?.name && pageFile?.name.toUpperCase().includes(searchStringUpper)));
        setFilteredPages(getFilteredPages(filtered));
    }, [searchString, pageFiles, getFilteredPages]);

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                getApp(get)(appId).then((data) => {
                    setAppData(data);
                    return;
                });

                getPages<PageFile[]>(get)(appId).then((pages) => {
                    setPageFiles(pages);
                    setFilteredPages(getFilteredPages(pages));
                    return;
                });
            }
        };

        fetchData();
    }, [appId, get, getFilteredPages]);

    const handleChangePage = (event: any, newPage: any) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0); // Reset to first page
    };

    if (!appData || !filteredPages) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <Container>
            <Box className="bb-title-bar bb-mt-3">
                <h2 className="bb-m0 bb-p0">Pages: {appData.displayName}</h2>
                <Box className="bb-ml-auto bb-align-items-center bb-flex">
                    <OpenInNewIcon className="bb-open-app-link"></OpenInNewIcon>
                    <a rel="noreferrer" className="bb-mr-1" href={`https://${getStagingUrl(appData.url)}`} target="_blank">View my
                        Staging App</a>
                    <OpenInNewIcon className="bb-open-app-link"></OpenInNewIcon>
                    <a rel="noreferrer" className="bb-mr-1" href={`https://${appData.url}`} target="_blank">
                    View my Live App</a>
                </Box>
            </Box>
            <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                <Link to="/applications">Applications</Link>
                <Link to={ `/applications/${appId}` }>Modules</Link>
                <Typography color="text.primary" className="bb-m0 bb-p0">Pages</Typography>
            </Breadcrumbs>
            <Alert className="bb-title-info bb-mb-2" severity="info">Manage, create and edit pages within your <b>{appData.displayName}</b> application.</Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac">
                <Box className="bb-flex bb-tal bb-align-items-center bb-mb-2">
                    <h3 className="bb-m0 bb-p0">Your Pages</h3>
                    <Box className="bb-med-search">
                        <SearchField
                            searchString={searchString}
                            callSearch={callSearch}
                            timeout={500}
                        />
                    </Box>
                    <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/applications/${appId}/pages/${newPageName}`}>
                        <Button variant="contained">
                            New Page
                        </Button>
                    </Link>
                </Box>
                <TableContainer component={Paper} sx={{ boxShadow: "none", marginTop: "1rem" }} >
                    <Table aria-label="table" sx={{ padding: "0", margin: "0" }} className="bb-page-list-table bb-global-table">
                        <TableHead sx={{ borderRadius: "5px 5px 0 0" }}>
                            <TableRow >
                                <TableCell>Page Name</TableCell>
                                <TableCell>Created Date</TableCell>
                                <TableCell>Last Updated Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{
                            filteredPages
                                .sort((a, b) => stringSort(a.pageName, b.pageName))
                                .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                                .map((pageFile, index) => {
                                    const filteredPageName = pageFile?.pageName?.replace(".json", "");
                                    return (
                                        <TableRow key={`page_${index}`} sx={{ padding: "0", margin: "0" }}>
                                            <TableCell sx={{ padding: "0", margin: "0" }}>
                                                <Link className="bb-pages-list bb-align-items-center" to={`/applications/${appId}/pages/${filteredPageName}`}>
                                                    <Box className="bb-ml-2">{filteredPageName}</Box>
                                                </Link>
                                            </TableCell>
                                            {pageFile?.createdDate ?
                                                (<TableCell>
                                                    <DateDisplay value={new Date(pageFile?.createdDate)} dateStyle="short" timeStyle="short" />
                                                </TableCell>)
                                                : <TableCell></TableCell>
                                            }
                                            {pageFile?.lastUpdatedDate ?
                                                (<TableCell>
                                                    <DateDisplay value={new Date(pageFile?.lastUpdatedDate)} dateStyle="short" timeStyle="short" />
                                                </TableCell>)
                                                : <TableCell></TableCell>
                                            }
                                        </TableRow>
                                    );
                                })
                        }
                        </TableBody>
                    </Table>
                    <TablePagination
                        className="bb-global-table-footer"
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredPages.length}
                        rowsPerPage={rowsPerPage}
                        page={currentPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Box>
        </Container>
    );
};
