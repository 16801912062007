import { SearchOutlined } from "@mui/icons-material";
import { TextField, styled } from "@mui/material";
import { useCallback, useContext } from "react";

import { MemberAppContext } from "../../MemberAppContext";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(TextField)(({ theme }) => ({
    "color": "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            "width": "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
}));

export const SearchBar = () => {

    const { searchString, setSearchString } = useContext(MemberAppContext);

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchString(event.target.value);
        },
        [setSearchString]
    );

    return (<Search className="bb-top-bar-search">
        <SearchIconWrapper className="bb-top-bar-search-icon">
            <SearchOutlined />
        </SearchIconWrapper>

        <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            variant="outlined"
            value={searchString}
            onChange={handleChange}
        />
    </Search>);
};
