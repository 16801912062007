import React, { useCallback, useState } from 'react';

import { ColorPicker } from "react-pick-color";
import { Button } from "@mui/material";

const ColourPicker = (props: any) => {
    const [showPicker, setShowPicker] = useState(false);

    const handleChange = useCallback((color: any) => {
        const newValue = color.hex;
        props.onChange(newValue);
    }, [props]);

    const togglePicker = () => {
        setShowPicker(!showPicker);
    };

    const getTextColor = (hexColor: string) => {
        const rgb = parseInt(hexColor?.slice(1), 16);
        const r = Math.floor(rgb / (256 * 256)) % 256;
        const g = Math.floor(rgb / 256) % 256;
        const b = rgb % 256;
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        return brightness > 186 ? 'black' : 'white';
    };

    const textColor = getTextColor(props.value);

    return (
        <div className='bb-flex bb-align-items-center'>
            <Button
                variant="contained"
                onClick={togglePicker}
                style={{
                    backgroundColor: props.value,
                    color: textColor,
                    boxShadow: "0px 0px 5px #eee",
                    minWidth: "40px",
                    minHeight: "40px",
                    borderRadius: "5px",
                    marginRight: ".5rem" }}
            >
            </Button>
            {showPicker && (
                <ColorPicker color={props.value} onChange={handleChange} hideAlpha={true} />
            )}
            <p className="bb-ml-1 bb-p0 bb-mb-0 bb-mt-0">{props.label}</p>
        </div>
    );
};

export { ColourPicker };
