import { useCallback, useContext, useEffect, useState } from "react";

import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";

import { MemberAppContext } from "../MemberAppContext";

import { AxiosApi } from "./axios";

export function useQueryRequest<ReturnType>(url: string, initData: ReturnType, deps: any[] = [], authenticated: boolean = false) {
    const [responseData, setResponseData] = useState<ReturnType>(initData);
    const [error, setError] = useState<any | null>(null);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const { msalClientApp, msalConfig } = useContext(MemberAppContext).msalConfig;

    const authenticatedQuery = useCallback(async (): Promise<AuthenticationResult> => {
        if (!msalClientApp) {
            setInProgress(false);
            throw Error("msalClientApp cannot be null when authenticating requests");
        }

        const account = msalClientApp.getAllAccounts()[0];
        const currentAccount: AccountInfo | undefined = msalClientApp.getAccountByLocalId(account.localAccountId) ?? undefined;

        return msalClientApp.acquireTokenSilent({
            scopes: [`https://${msalConfig?.tenantName}/${msalConfig?.clientId}/${msalConfig?.apiScope}`],
            account: currentAccount,
        });
    }, [msalClientApp, msalConfig]);

    const updateData = useCallback(async () => {
        setResponseData(initData);
        setInProgress(true);

        let headers = {};

        if (authenticated)
        {
            const authenticatedResult = await authenticatedQuery();
            headers = {
                authorization: "Bearer " + authenticatedResult.accessToken
            };
        }

        try {
            const axiosResponse = (await AxiosApi.request({
                url,
                method: "GET",
                headers
            }));

            setResponseData(axiosResponse.data);
            setInProgress(false);
        }
        catch (e) {
            setError(e);
            setInProgress(false);
        }
    }, [url, authenticated, initData, authenticatedQuery]);

    useEffect(() => {
        updateData();

        // This warns about the spread operator exhaustive deps, but that's unfixable
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, updateData]);

    return { data: responseData, inProgress, error };
}
