export enum PanelType {
    Img = "bb-img",
    Button = "bb-button",
    StartChat = "bb-start-chat",
    Slider = "bb-slider",
    MediaPanel = "bb-media-panel",
    Accordion = "bb-accordion",
    ButtonStack = "bb-button-stack",
    FadingBanner = "bb-fading-banner",
    CardGrid = "bb-card-grid",
    Html = "bb-html",
    Launcher = "bb-launcher",
    BotLearn = "bb-bot-learn",
    Video = "bb-video",
    IconBox = "bb-icon-box",
    Events = "bb-events",
    Map = "bb-map",
    Social = "bb-social",
    ContactStrip = "bb-contact"
}
