import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";

import { useCallback, useRef } from "react";

import useDataClient from "../../axios/dataClient";

interface XeroDialogProps {
    isOpen: boolean;
    onCancel: () => void;
    onSaveSuccess: () => void;
}

export const XeroDialog = ({ isOpen, onCancel, onSaveSuccess }: XeroDialogProps) => {
    const { post } = useDataClient();
    const clientIdRef = useRef({ value: "" });
    const clientSecretRef = useRef({ value: "" });
    const onSave = useCallback(async () => {
        await post("api/connections/xero", { clientId: clientIdRef.current.value, clientSecret: clientSecretRef.current.value });
        onSaveSuccess();
    }, [post, onSaveSuccess]);

    return (<Dialog open={isOpen}>
        <DialogContent>
            <TextField inputRef={clientIdRef} label="Client Id"></TextField>
            <TextField inputRef={clientSecretRef} label="Client Secret"></TextField>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>Close</Button>
            <Button onClick={onSave}>Save</Button>
        </DialogActions>
    </Dialog>);
};
