import { Dialog, DialogContent } from "@mui/material";

import { LiveChatDashboard } from "./LiveChatDashboard";

interface LiveChatDashboardDialogProps {
    open: boolean;
    onClose: () => void;
}

export const LiveChatDashboardDialog = (props: LiveChatDashboardDialogProps) => {
    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth={"xl"}>
            <DialogContent>
                <LiveChatDashboard />
            </DialogContent>
        </Dialog>);
};
