import { useCallback, useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Box, List, ListItemButton, ListItemText } from "@mui/material";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import InsertChartOutlinedRoundedIcon from "@mui/icons-material/InsertChartOutlinedRounded";
import CropOriginalOutlinedIcon from "@mui/icons-material/CropOriginalOutlined";
import SpokeOutlinedIcon from "@mui/icons-material/SpokeOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import AppsIcon from "@mui/icons-material/Apps";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { SupportAgent } from "@mui/icons-material";
import { AutoMode } from "@mui/icons-material";
import { Groups } from "@mui/icons-material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

import { useNavigate } from "react-router-dom";

import { theme } from "../theme";

import { MemberAppContext } from "../MemberAppContext";

import { CustomerConfigurationContext } from "../CustomerConfigurationContext";

import { ChatSignInDialog } from "./livechat";

import { SidebarItem } from "./SidebarItem";

export const Sidebar = () => {

    const appContext = useContext(MemberAppContext);

    const {
        conversationAI,
        home,
        liveAgent,
        assets,
        automation,
        connections,
        insights,
        dataManagement,
        applications
    } = useContext(CustomerConfigurationContext);
    const [liveChatDialogOpen, setLiveChatDialogOpen] = useState(false);

    const isMobile = useMediaQuery({ query: "(max-width: 760px)" });

    const navigate = useNavigate();

    const startSignIn = useCallback(() => {
        setLiveChatDialogOpen(true);
    }, []);

    const cancelDialog = useCallback(() => {
        setLiveChatDialogOpen(false);
    }, []);

    const onSignIn = useCallback(() => {
        setLiveChatDialogOpen(false);
        appContext.setSidebarOpen(!isMobile);
        navigate("/livechat/chat");
    }, [navigate, appContext, isMobile]);

    const onFailedSignin = useCallback(() => {
        setLiveChatDialogOpen(false);
    }, []);

    return (
        <Box className="sidebar">
            <List sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                breakPoint: "xs",
                paddingTop: "1rem",
                fontWeight: "bold",
                width: "100%"
            }}>
                <SidebarItem
                    isEnabled={home}
                    primaryText="Dashboard"
                    linkTo="/"
                    icon={<GridViewOutlinedIcon fontSize="small" />}
                />
                <SidebarItem
                    isEnabled={(appContext.user.isAdmin || appContext.user.isApplicationAdmin || appContext.user.isApplicationContributor) && applications}
                    primaryText="Applications"
                    linkTo="/applications"
                    icon={<AppsIcon fontSize="small" />}
                />
                <SidebarItem
                    isEnabled={(appContext.user.isAdmin || appContext.user.isCaiAdmin || appContext.user.isCaiContributor) && conversationAI}
                    primaryText="Conversational AI"
                    linkTo="/bots"
                    icon={<ForumOutlinedIcon fontSize="small" />}
                />
                <SidebarItem
                    isEnabled={(appContext.user.isAdmin || appContext.user.isRpaAdmin || appContext.user.isRpaContributor) && automation}
                    primaryText="Automation"
                    linkTo="automation"
                    icon={<AutoMode fontSize="small" />}
                />
                <SidebarItem
                    customOnClickHandler={startSignIn}
                    isEnabled={(appContext.user.isAdmin || appContext.user.isLiveOperator || appContext.user.isLiveAgentAdmin) && liveAgent}
                    primaryText="Live Agent"
                    icon={<SupportAgent fontSize="small" />}
                />
                <SidebarItem
                    isEnabled={(appContext.user.isAdmin || appContext.user.isLiveOperator || appContext.user.isLiveAgentAdmin) && liveAgent}
                    primaryText="Live Agent Dashboard"
                    linkTo={"/livechat"}
                    icon={<Groups fontSize="small" />}
                />
                <SidebarItem
                    isEnabled={appContext.user.isAdmin || appContext.user.isLiveAgentAdmin}
                    primaryText="User Management"
                    linkTo={"/users"}
                    icon={<ManageAccountsOutlinedIcon fontSize="small" />}
                />
                <SidebarItem
                    isEnabled={(appContext.user.isAdmin || appContext.user.isDataAdmin || appContext.user.isDataContributor) && dataManagement}
                    primaryText="Data Management"
                    linkTo="/data"
                    icon={<ListAltOutlinedIcon fontSize="small" />}
                />
                <SidebarItem
                    isEnabled={(appContext.user.isAdmin || appContext.user.isMediaContributor) && assets}
                    primaryText="Media Library"
                    linkTo="/assets/public"
                    icon={<CropOriginalOutlinedIcon fontSize="small" />}
                />
                <SidebarItem
                    isEnabled={appContext.user.isAdmin && automation}
                    primaryText="Pathways"
                    linkTo="/pathways"
                    icon={<AltRouteIcon fontSize="small" />}
                />
                <SidebarItem
                    isEnabled={appContext.user.isAdmin && automation}
                    primaryText="Deployments"
                    linkTo="/deployments"
                    icon={<RocketLaunchIcon fontSize="small" />}
                />
                <SidebarItem
                    isEnabled={(appContext.user.isAdmin || appContext.user.isApplicationAdmin) && insights}
                    primaryText="Insights"
                    linkTo="/insights"
                    icon={<InsertChartOutlinedRoundedIcon fontSize="small" />}
                />
                <SidebarItem
                    isEnabled={appContext.user.isAdmin && connections}
                    primaryText="Integrations"
                    linkTo="/connections"
                    icon={<SpokeOutlinedIcon fontSize="small" />}
                />
                <ListItemButton sx={[
                    { "&:hover": {
                        background: theme.palette.text.primary,
                        color: theme.palette.secondary.contrastText
                    },
                    "&:active": {
                        backgroundColor: "aliceblue"
                    }
                    }

                ]} className="sidebar-item" href="https://beebotai.atlassian.net/servicedesk/customer/portals" target="_blank">

                    <Box className="sidebar-item-icon"><HelpOutlineOutlinedIcon fontSize="small"></HelpOutlineOutlinedIcon></Box>
                    <ListItemText className="sidebar-item-text" sx={{ padding: theme.spacing(1) }} >Help Desk</ListItemText>
                </ListItemButton>
            </List>
            <ChatSignInDialog
                dialogOpen={liveChatDialogOpen}
                onCancel={cancelDialog}
                onSuccessfulSignin={onSignIn}
                onFailedSignin={onFailedSignin}
            />
        </Box>
    );
};
