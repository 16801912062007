/* eslint-disable max-len */

import * as React from "react";

import { Box, Link, Tooltip } from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export interface BBFadingBannerProps {
    panel: any;
}
export const BBFadingBanner: React.FC<BBFadingBannerProps> = ({ panel }) => {
    const zoom = panel.bannerZoomEffect === true ? " Yes" : " No";

    return (<Box>
        <Box className="bb-flex bb-flex-wrap bb-justify-content-center">
            {panel?.bannerData?.map((banner: any, index: number) => (
                <Box key={index} sx={{ backgroundColor: banner.bannerBgColor, color: "#fff", overflow: "hidden", borderRadius: "5px", flexDirection: "column", maxWidth: '320px' }} className="bb-m1">
                    <img src={banner.bannerImgUrl} className="bb-width-100 bb-pe-fb-img"/>
                    <Box className="bb-tal bb-p-2 bb-flex bb-flex-column bb-pe-fb-display">
                        <h2>{banner.bannerHeading}</h2>
                        <h4>{banner.bannerSubHeading}</h4>
                        <p>{banner.bannerPara}</p>
                        <Link href={banner.bannerLink} className="bb-mb-1 bb-mt-1 bb-pe-fb-btn"
                            sx={{ textDecoration: "none", fontWeight: "bold", padding: "1rem", borderRadius: "5px", backgroundColor: banner.bannerButtonBgColor, color: banner.bannerButtonTextColor, width: "fit-content" }}>
                            {banner.bannerLinkText}
                        </Link>
                    </Box>
                </Box>
            ))}
        </Box>
        <Box sx={{ borderTop: "1px solid #eee" }}>
            <Box className="bb-flex bb-align-items-center bb-justify-content-center bb-mt-2" sx={{ alignItems: "bottom" }}>
                <h3 className="bb-header-with-icon">Fading Banner </h3><Tooltip title="Fading banners can display across the full width of your page. Text will display on the left, and your image on the right. If you have multiple banners on one row, banners will stack so the image sits on top." placement="top"><InfoOutlinedIcon className="bb-header-icon"></InfoOutlinedIcon></Tooltip>
            </Box>
            <ul className="bb-pe-list bb-justify-content-center">
                <li className="bb-flex bb-align-items-center">
                    <Tooltip title="Images zoom in as the banners fade between one another." placement="top"><InfoOutlinedIcon className="bb-tip-icon"></InfoOutlinedIcon></Tooltip>
                    <span className="bb-pe-setting">Zoom</span>: <b>&nbsp;{zoom}</b>
                </li>
            </ul>
        </Box>
    </Box>
    );
};
