import { HubConnectionState } from "@microsoft/signalr";
import { createContext } from "react";

import { LiveAgentQueueItem } from "./queues";

import { AssignedChatRequest } from "./assignedChatRequest";

import { ChatHandoffRequest, receiveTransferRequest } from ".";

export type messageCallback = (message: string) => void;

export type typingCallback = () => void;

export type chatTransferredCallback = () => void;

export type messageQueuedCallback = (item: LiveAgentQueueItem) => void;

export type messageDequeuedCallback = (customerId: string, chatId: string) => void;

export type chatExistsCallback = (handoff: ChatHandoffRequest) => Promise<void>;

export type fileScannedCallback = (chatId: string, fileName: string, success: boolean) => void;

export interface ChatContext {
    subscribe: () => Promise<void>;
    sendMessage: (message: string, botId: string, chatId: string) => Promise<void>;
    sendTypingEvent: (botId: string, chatId: string) => Promise<void>;
    closeConnection: () => Promise<void>;
    registerCallback: (callback: messageCallback, botName: string, chatId: string) => void;
    registerTypingCallback: (typingCallback: typingCallback, botName: string, chatId: string) => void;
    registerTransferredCallback: (chatTransferredCallback: chatTransferredCallback, botName: string, chatId: string) => void;
    registerOnReceiveTransferCallback: (callback: (transfer: receiveTransferRequest) => void) => void;
    registerOnAssignedCallback: (callback: (assignedChat: AssignedChatRequest) => void) => void;
    registerOnNewChatCallback: (callback: (handoff: ChatHandoffRequest) => void) => void;
    registerMessageQueuedCallback: (callback: messageQueuedCallback) => void;
    registerMessageDequeuedCallback: (callback: messageDequeuedCallback) => void;
    registerChatExistsCallback: (callback: chatExistsCallback) => void;
    registerFileScannedCallback: (callback: fileScannedCallback, chatId: string) => void;
    connectionState: HubConnectionState;
}

export const ChatHubContext = createContext<ChatContext>({
    subscribe: async () => undefined,
    closeConnection: async () => undefined,
    registerCallback: () => undefined,
    registerTypingCallback: () => undefined,
    registerTransferredCallback: () => undefined,
    sendMessage: async () => undefined,
    sendTypingEvent: async () => undefined,
    registerOnReceiveTransferCallback: () => undefined,
    registerOnAssignedCallback: () => undefined,
    registerOnNewChatCallback: () => undefined,
    registerMessageQueuedCallback: () => undefined,
    registerMessageDequeuedCallback: () => undefined,
    registerChatExistsCallback: () => undefined,
    registerFileScannedCallback: () => undefined,
    connectionState: HubConnectionState.Disconnected,
});
