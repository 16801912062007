import * as React from "react";

import { Grid, Box } from "@mui/material";

export interface BBLauncherProps {
    panel: any;
}

export const BBLauncher: React.FC<BBLauncherProps> = ({ panel }) => {
    return (
        <Box>
            <Grid item xs className="bb-tal">
                {panel?.imgUrl ? (<img src={panel?.imgUrl} className="bb-pe-img, bb-width-100" />) : (<span></span>)}
                {panel?.panelHeading ? (<h3 className="bb-tal">{panel?.panelHeading}</h3>) : (<span></span>)}
                {panel?.panelHeading ? (<p className="bb-tal">{panel?.panelPara}</p>) : (<span></span>)}
                <ul>
                    {panel?.links?.map((link: any, index: number) => (
                        <li key={index}>
                            <a href={link?.linkUrl} target="_blank" rel="noreferrer">{link?.linkText}</a>
                        </li>
                    ))}
                </ul>
            </Grid>
        </Box>
    );
};
