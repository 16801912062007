import React from "react";

import {
    Container,
    Box,
    Alert,
    Tab,
    Tabs,
} from "@mui/material";

import { DeploymentTable } from "./DeploymentTable";

import { DeploymentType } from "../../../schemas/deployment";
import { TabPanelProps } from "../pages/PageEditForm";

export const DeploymentsPage = () => {
    const [tabValue, setTabValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, value: number) => {
        setTabValue(value);
    };

    const TabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    };

    return (
        <Container>
            <Box className="bb-title-bar bb-mt-3">
                <h2 className="bb-m0 bb-p0">Deployments</h2>
            </Box>
            <Alert className="bb-title-info bb-mb-2" style={{ marginTop: "22px" }} severity="info">The Deployment Centre enables you to
                manage deployments for mulitple solutions on the Beebot AI platform such as: Applications, Notifications and Pathways.</Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac bb-m0 bb-p0 bb-tabs-cont">
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Application Config" />
                    <Tab label="Pathways" />
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                    <Box sx={{ background: "#eee" }}>
                        <DeploymentTable deploymentType={DeploymentType.Config} />
                    </Box>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <DeploymentTable deploymentType={DeploymentType.Pathways} />
                </TabPanel>
            </Box>
        </Container>
    );
};
