import { useEffect, useMemo } from "react";
import { HubConnectionState } from "@microsoft/signalr";
import {
    Box,
    Toolbar,
    Typography,
    Button,
    Menu,
    MenuItem,
    List,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useState, useCallback, useContext } from "react";

import { error } from "../ErrorDisplay";

import { MemberAppContext } from "../../MemberAppContext";

import useDataClient from "../../axios/dataClient";

import { theme } from "../../theme";

import { ChatWindowProps } from "./ChatWindowProps";
import { ChatPreview } from "./ChatPreview";
import { ChatSignInDialog } from "./sign-in-dialog";
import { LiveChatDashboardButton } from "./dashboard/LiveChatDashboardButton";
import { LiveChatQueueButton } from "./queues";
import { AgentState } from "./agentState";
import { LiveAgentQueueItem } from "./queues/liveAgentQueueItem";
import { SetAwayDialog } from "./setAwayDialog";
import { GoOfflineDialog } from "./goOfflineDialog";
import { BeRightBackDialog } from "./BeRightBackDialog";
import { isChatMessage } from "./ChatWindow";

interface ChatsListSidebarProps {
    signOut: () => Promise<void>;
    agentState: AgentState;
    activeChats: ChatWindowProps[];
    setCurrentChat: (chatId: string) => void;
    connectionStatus: HubConnectionState;
    chatDequeuedCallback: (item: LiveAgentQueueItem) => void;
}

export const ChatsListSidebar = (props: ChatsListSidebarProps) => {
    const {
        signOut,
        agentState,
        activeChats,
        setCurrentChat,
        connectionStatus,
        chatDequeuedCallback
    } = props;
    const [openUnavailableDialog, setOpenUnavailableDialog] = useState<boolean>(false);
    const [openSignInDialog, setOpenSignInDialog] = useState<boolean>(false);
    const [openAwayDialog, setOpenAwayDialog] = useState<boolean>(false);
    const [openBRBDialog, setOpenBRBDialog] = useState<boolean>(false);
    const [anchorElStatus, setAnchorElStatus] = useState<null | HTMLElement>(
        null
    );
    const [componentRendered, setComponentRendered] = useState(false);
    const [activeChatId, setActiveChatId] = useState<string | null>(null);
    const { updateLiveAgentStatus } = useContext(MemberAppContext);
    const { get } = useDataClient();

    // This is to stop us from trying to close the status when the whole page has already been disposed of.
    // See https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
    useEffect(() => {
        setComponentRendered(true);

        return () => setComponentRendered(false);
    }, []);

    const handleOpenStatusMenu = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            setAnchorElStatus(event.currentTarget);
        },
        []
    );

    const handleCloseStatusMenu = useCallback(() => {
        if (componentRendered) {
            setAnchorElStatus(null);
        }
    }, [componentRendered]);

    const handleOpenSetUnavailableDialog = useCallback(() => {
        setOpenUnavailableDialog(true);
    }, []);

    const handleCloseGoOfflineDialog = useCallback(() => {
        setOpenUnavailableDialog(false);
    }, []);

    const handleSignIn = useCallback(() => {
        setAnchorElStatus(null);
        setOpenSignInDialog(true);
    }, []);

    const goOffline = useCallback(async () => {
        await get("api/chat/setStatus/unavailable");
        handleCloseStatusMenu();
        handleCloseGoOfflineDialog();

        if (updateLiveAgentStatus === undefined) {
            return;
        }
        updateLiveAgentStatus(AgentState.UNAVAILABLE);
    }, [
        get,
        handleCloseGoOfflineDialog,
        handleCloseStatusMenu,
        updateLiveAgentStatus,
    ]);

    const cancelGoOffline = useCallback(() => {
        handleCloseGoOfflineDialog();
        handleCloseStatusMenu();
    }, [handleCloseGoOfflineDialog, handleCloseStatusMenu]);

    const cancelSignIn = useCallback(() => setOpenSignInDialog(false), []);

    const onSuccessfulSignIn = useCallback(async () => {
        setOpenSignInDialog(false);
        await get("api/chat/setStatus/connected");

        if (updateLiveAgentStatus === undefined) {
            return;
        }
        updateLiveAgentStatus(AgentState.CONNECTED);
    }, [get, updateLiveAgentStatus]);

    const onFailedSignin = useCallback(() => {
        setOpenSignInDialog(false);
        error("Error occured while trying to sign in.");
    }, []);

    const selectChat = useCallback(
        (chatId: string) => {
            setActiveChatId(chatId);
            setCurrentChat(chatId);
        },
        [setCurrentChat]
    );

    const handleSetAway = useCallback(() => setOpenAwayDialog(true), []);
    const handleAwayCancel = useCallback(() => setOpenAwayDialog(false), []);

    const handleAwayNotifications = useCallback(async () => {
        await get("api/chat/setStatus/away");

        updateLiveAgentStatus(AgentState.AWAY);
        setOpenAwayDialog(false);
    }, [updateLiveAgentStatus, get]);

    const handleAwayNoNotifications = useCallback(async () => {
        await get("api/chat/setStatus/unavailable");

        updateLiveAgentStatus(AgentState.UNAVAILABLE);
        setOpenAwayDialog(false);
    }, [updateLiveAgentStatus, get]);

    const onOpenBRBDialog = useCallback(() => {
        setOpenBRBDialog(true);
    }, []);

    const cancelBRBDialog = useCallback(() => {
        setOpenBRBDialog(false);
    }, []);

    const handleSetBRB = useCallback(async () => {
        await get("api/chat/setStatus/berightback");

        updateLiveAgentStatus(AgentState.BeRightBack);
        setOpenBRBDialog(false);
    }, [get, updateLiveAgentStatus]);

    const [statusText, statusIconStyles] = useMemo(() => {
        switch (connectionStatus) {
            case HubConnectionState.Connected:
                switch (agentState) {
                    case AgentState.CONNECTED:
                        return ["Available", { color: "#77d864" }];
                    case AgentState.AWAY:
                        return ["Away", { color: "yellow" }];
                    case AgentState.BeRightBack:
                        return ["Be Right Back", { color: "yellow" }];
                    case AgentState.UNAVAILABLE:
                        return ["Unavailable", { color: "red" }];
                    case AgentState.DISCONNECTED:
                        return ["Disconnected", { color: "red " }];
                    default: return [`AgentState: ${agentState}`, { color: "grey" }];
                }
            case HubConnectionState.Connecting:
            case HubConnectionState.Disconnecting:
            case HubConnectionState.Reconnecting:
                return [connectionStatus, { color: "yellow" }];
            case HubConnectionState.Disconnected:
                return ["Disconnected", { color: "red" }];
            default:
                return [`ConnectionStatus: ${connectionStatus}`, { color: "grey" }];
        }
    }, [agentState, connectionStatus]);

    return (
        <>
            <ChatSignInDialog
                dialogOpen={openSignInDialog}
                onCancel={cancelSignIn}
                onSuccessfulSignin={onSuccessfulSignIn}
                onFailedSignin={onFailedSignin}
            />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                flex={1}
                padding={theme.spacing(2)}
                className="chat-sidebar"
            >
                <Box>
                    <Toolbar>
                        <CircleIcon sx={statusIconStyles} />
                        <Typography paddingLeft={1}>
                            <Button
                                onClick={handleOpenStatusMenu}
                                endIcon={<KeyboardArrowDownIcon />}
                                sx={{ fontWeight: "bold" }}
                                data-testid="status-text-button"
                            >
                                {statusText}
                            </Button>
                        </Typography>
                        <Menu
                            anchorEl={anchorElStatus}
                            open={Boolean(anchorElStatus)}
                            onClose={handleCloseStatusMenu}
                        >
                            <MenuItem
                                onClick={signOut}
                                sx={agentState !== AgentState.UNAVAILABLE ? { display: "none" } : {}}
                                disabled={agentState !== AgentState.UNAVAILABLE}
                            >
                                <Typography>Go Offline</Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={handleOpenSetUnavailableDialog}
                                disabled={agentState === AgentState.UNAVAILABLE}
                            >
                                <Typography>Set Unavailable</Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={handleSignIn}
                                disabled={agentState === AgentState.CONNECTED}
                            >
                                <Typography>Set Available</Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={handleSetAway}
                                disabled={agentState === AgentState.AWAY}
                            >
                                <Typography>Set Away</Typography>
                            </MenuItem>
                            <MenuItem
                                onClick={onOpenBRBDialog}
                                disabled={agentState === AgentState.BeRightBack}
                            >
                                <Typography>Set Be Right Back</Typography>
                            </MenuItem>
                        </Menu>
                    </Toolbar>

                    <List>
                        {activeChats.map((chat) => (
                            <ChatPreview
                                activeChatId={activeChatId}
                                chatId={chat.chatId}
                                conversationMessages={chat.conversationMessages.filter(isChatMessage)}
                                userName={chat.userName}
                                selectChat={selectChat}
                                unreadMessages={chat.unreadMessages}
                                key={chat.chatId}
                            />
                        ))}
                    </List>
                </Box>
                <Box className="live-agent-sidebar-buttons">
                    <LiveChatQueueButton chatDequeuedCallback={chatDequeuedCallback} sx={{ marginBottom: "10px" }} />
                    <LiveChatDashboardButton />
                </Box>
            </Box>
            <SetAwayDialog
                open={openAwayDialog}
                onNoNotifications={handleAwayNoNotifications}
                onNotifications={handleAwayNotifications}
                onCancel={handleAwayCancel} />
            <GoOfflineDialog open={openUnavailableDialog} onCancel={cancelGoOffline} onGoOffline={goOffline} />
            <BeRightBackDialog open={openBRBDialog} onCancel={cancelBRBDialog} onSetBRB={handleSetBRB} />
        </>
    );
};
