import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

import { LiveAgentQueueItem } from "./liveAgentQueueItem";

interface QueueItemSelectedDialogProps {
    open: boolean;
    item: LiveAgentQueueItem | null;
    onCancel: () => Promise<void>;
    onSelect: () => Promise<void>;
}

export const QueueItemSelectedDialog = ({ open, item, onCancel, onSelect }: QueueItemSelectedDialogProps) => {

    return (<Dialog open={open}>
        <DialogTitle className="queue-select-confirm-prompt">
            <Typography>
                {item?.userName}
            </Typography>
        </DialogTitle>
        <DialogContent dividers>
            <Typography className="queue-select-confirm-content">
            You are about to start a chat with {item?.userName}. Continue?
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onSelect}>Select</Button>
        </DialogActions>
    </Dialog>);
};
