import { useParams, Link, useLocation } from "react-router-dom";

import Typography from "@mui/material/Typography";
import {
    Container,
    Box,
    Alert,
    CircularProgress
} from "@mui/material";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { useState, useEffect } from "react";

import useDataClient from "../../../axios/dataClient";

import { getApp } from "../../../services/api/FrontendService";

import { AppViewModel } from "../../../services/model/apps/AppViewModel";

import { PageEditBaseForm } from "./PageEditBaseForm";
import { getStagingUrl } from "../../../services/helper/regex";

export const PageEdit: React.FC = () => {
    const { appId, pageName } = useParams();
    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const { get } = useDataClient();
    const location = useLocation();
    const isDelete = location?.pathname?.endsWith("/delete");

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                const data = await getApp(get)(appId);
                setAppData(data);
            }
        };

        fetchData();
    }, [appId, get]);

    if (!appData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <Container className="bb-page-editor-container">
            <Box className="bb-title-bar bb-mt-3">
                <h2 className="bb-m0 bb-p0">Pages: {appData.displayName}</h2>
                <Box className="bb-ml-auto bb-align-items-center bb-flex">
                    <OpenInNewIcon className="bb-open-app-link"></OpenInNewIcon>
                    <a rel="noreferrer" className="bb-mr-1" href={`https://${getStagingUrl(appData.url)}/?page=${pageName}`} target="_blank">View my
                        Staging App</a>
                    <OpenInNewIcon className="bb-open-app-link"></OpenInNewIcon>
                    <a rel="noreferrer" className="bb-mr-1" href={`https://${appData.url}/?page=${pageName}`} target="_blank">View my
                        Live App</a>
                </Box>
            </Box>
            <Breadcrumbs className="bb-mb-3 bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                <Link to="/applications">Applications</Link>
                <Link to={`/applications/${appId}`}>Modules</Link>
                <Link to={`/applications/${appId}/pages`}>Pages</Link>
                <Typography color="text.primary" className="bb-m0 bb-p0">{pageName}</Typography>
            </Breadcrumbs>
            <Alert
                className="bb-title-info bb-mb-2"
                severity="info">
                Easily edit your page with our editor, then click "Preview" to see it live. You can also check how it looks on different devices.
                To learn more about using the page editor&nbsp;
                <a rel="noreferrer" className="bb-mr-1" href={`https://product.bbotapp.com/?page=page-editor`}
                    target="_blank">Click Here</a>
            </Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac" sx={{ minHeight: "60vh" }}>
                <PageEditBaseForm appId={appId} pageName={pageName} isDelete={isDelete}></PageEditBaseForm>
            </Box>
        </Container>
    );
};
