/* eslint-disable max-len */

import * as React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import DeleteIcon from '@mui/icons-material/Delete';

import IconButton from '@mui/material/IconButton';
import { WarningModal } from "../../shared/WarningModal";

export interface AllButtonsProps {
    rowIndex: number;
    indexTotal: number;
    columnIndex?: number;
    onMoveUpClick: (rowIndex: number, columnIndex?: number) => void;
    onMoveDownClick: (rowIndex: number, columnIndex?: number) => void;
    onEditClick: (rowIndex: number, columnIndex?: number) => void;
    onDeleteClick: (rowIndex: number, columnIndex?: number) => void;
}

export const AllButtons: React.FC<AllButtonsProps> = ({
    rowIndex,
    indexTotal,
    columnIndex,
    onMoveUpClick,
    onMoveDownClick,
    onEditClick,
    onDeleteClick }) => {

    const [open, setOpen] = React.useState(false);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const [confirmAction, setConfirmAction] = React.useState<() => void>(() => () => {});

    const handleOpen = (action: () => void) => {
        setConfirmAction(() => action);

        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    const handleConfirm = () => {
        confirmAction();
        setOpen(false);
    };

    return (
        <>
            <IconButton aria-label="settings" title="Move Up" className="bb-move-up" disabled={(columnIndex === undefined ? rowIndex : columnIndex) === 0} onClick={
                React.useCallback(() => onMoveUpClick(rowIndex, columnIndex), [rowIndex, columnIndex, onMoveUpClick])
            }>
                <ArrowCircleUpIcon />
            </IconButton>
            <IconButton aria-label="settings" title="Move Down" className="bb-move-down" disabled={(columnIndex === undefined ? rowIndex : columnIndex) === indexTotal - 1} onClick={
                React.useCallback(() => onMoveDownClick(rowIndex, columnIndex), [rowIndex, columnIndex, onMoveDownClick])
            }>
                <ArrowCircleDownIcon />
            </IconButton>
            <IconButton aria-label="settings" title="Edit" onClick={
                React.useCallback(() => onEditClick(rowIndex, columnIndex), [rowIndex, columnIndex, onEditClick])
            }>
                <EditIcon />
            </IconButton>
            <IconButton aria-label="settings" title="Delete" color="error" onClick={
                React.useCallback(() => handleOpen(() => onDeleteClick(rowIndex, columnIndex)), [rowIndex, columnIndex, onDeleteClick])
            }>
                <DeleteIcon />
            </IconButton>
            <WarningModal
                open={open}
                description={"Are you sure you want to perform this action?"}
                onConfirm={handleConfirm}
                onCancel={handleClose}
            />
        </>
    );
};
