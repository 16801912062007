import { AccountInfo } from "@azure/msal-browser";
import { useCallback, useContext } from "react";

import { MemberAppContext } from "../MemberAppContext";

import { AxiosApi } from "./axios";

const useDataClient = () => {

    const { msalClientApp, msalConfig } = useContext(MemberAppContext).msalConfig;
    const getToken = useCallback(async () => {
        if (!msalClientApp) {
            throw Error("msalClientApp cannot be null when authenticating requests");
        }
        const account = msalClientApp.getAllAccounts()[0];
        const currentAccount: AccountInfo | undefined = msalClientApp.getAccountByLocalId(account.localAccountId) ?? undefined;
        const result = await msalClientApp?.acquireTokenSilent({
            scopes: [`https://${msalConfig?.tenantName}/${msalConfig?.clientId}/${msalConfig?.apiScope}`],
            account: currentAccount,
        });

        return result.accessToken;
    }, [msalClientApp, msalConfig]);

    const get = useCallback(async <T>(url: string, data: any = undefined) => {
        const headers  = {
            authorization: "Bearer " + (await getToken())
        };
        const axiosResponse = (await AxiosApi.request({
            url,
            method: "GET",
            headers,
            data
        }));

        return axiosResponse.data as T;
    }, [getToken]);

    const getBlob = useCallback(async <T>(url: string, data: any = undefined) => {
        const headers  = {
            authorization: "Bearer " + (await getToken())
        };
        const axiosResponse = (await AxiosApi.request({
            url,
            method: "GET",
            headers,
            data,
            responseType: "blob"
        }));

        return axiosResponse.data as T;
    }, [getToken]);

    const post = useCallback(async <T> (url: string, body: any) => {
        const headers = {
            authorization: "Bearer " + (await getToken())
        };
        const axiosResponse = (await AxiosApi.request({
            url,
            method: "POST",
            headers,
            data: body
        }));

        return axiosResponse.data as T;
    }, [getToken]);

    const put = useCallback(async <T> (url: string, body?: any) => {
        const headers = {
            authorization: "Bearer " + (await getToken())
        };
        const axiosResponse = (await AxiosApi.request({
            url,
            method: "PUT",
            headers,
            data: body
        }));

        return axiosResponse.data as T;
    }, [getToken]);

    const deleteRequest = useCallback(async <T> (url: string, body: any | undefined = undefined) => {
        const headers = {
            authorization: "Bearer " + (await getToken())
        };
        const axiosResponse = (await AxiosApi.request({
            url,
            method: "DELETE",
            headers,
            data: body
        }));

        return axiosResponse.data as T;
    }, [getToken]);

    return { get, getBlob, post, put, deleteRequest, getToken };
};
// This is ignored, because we need to export it by default to properly mock it out in tests
// eslint-disable-next-line import/no-default-export
export default useDataClient;
