/* eslint-disable max-lines */
import {
    Box,
    Button,
    IconButton,
    TextField,
    Typography,
    Paper,
    Toolbar,
    Divider,
} from "@mui/material";
import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";

import { useMediaQuery } from "react-responsive";

import { debounce } from "lodash";

import { Send } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import useDataClient from "../../axios/dataClient";

import { theme } from "../../theme";

import { ChatHubContext } from "./chatHubContext";
import { ChatMessageBox } from "./ChatMessageBox";
import { ChatInformation } from "./ChatInformation";
import { TypingIndicator } from "./TypingIndicator";
import { ChatWindowProps } from "./ChatWindowProps";

import { ChatFileBox } from "./ChatFileBox";

import { CloseChatConfirmDialog } from ".";

export interface ChatMessage {
    // User is is the end user, admin is the user of the customer portal
    sender: "Bot" | "Admin" | "User";
    message: string;
    time: Date;
    transfer?: boolean;
}

export interface FileMessage {
    fileName: string;
    time: Date;
}

export const isChatMessage = (message: ChatMessage | FileMessage): message is ChatMessage => {
    return (message as ChatMessage).message !== undefined;
};

export const ChatWindow = ({
    botName,
    chatId,
    userName,
    userEmail,
    newMessage,
    onChatCompletion,
    conversationMessages,
    pushConversationMessage,
    setCurrentChat
}: ChatWindowProps) => {
    const valueRef = useRef({ value: "" });
    const { post, get } = useDataClient();
    const chatBoxDiv = useRef<HTMLDivElement>();
    const [messages, setMessages] =
        useState<(ChatMessage | FileMessage)[]>(conversationMessages);
    const {
        sendMessage: sendSignalRMessage,
        sendTypingEvent: sendTypingEvent
    } = useContext(ChatHubContext);
    const [closeDialogOpen, setCloseDialogOpen] = useState(false);
    const isMobile = useMediaQuery({ query: "(max-width: 760px)" });
    const [chatInformationOpen, setChatInformationOpen] =
        useState<boolean>(!isMobile);
    const [typing, setTyping] = useState(false);
    const [requestingFile, setRequestingFile] = useState(false);

    const sendMessage = useCallback(
        async (message: string) => {
            await sendSignalRMessage(message, botName, chatId);

            const adminMessage: ChatMessage = {
                sender: "Admin",
                message: valueRef.current.value,
                time: new Date(),
            };
            setMessages((existingMessages) => [
                ...existingMessages,
                adminMessage,
            ]);
            pushConversationMessage(adminMessage, chatId, false);

            if (chatBoxDiv.current !== undefined) {
                chatBoxDiv.current.scrollTop = chatBoxDiv.current.scrollHeight;
                valueRef.current.value = "";
            }
        },
        [botName, chatId, pushConversationMessage, sendSignalRMessage]
    );

    const onKeyDown = useCallback(
        async (event: React.KeyboardEvent) => {
            if (event.key === "Enter") {
                await sendMessage(valueRef.current.value);
            }
        },
        [sendMessage]
    );

    const onSendClicked = useCallback(async () => {
        await sendMessage(valueRef.current.value);
    }, [sendMessage]);

    const closeConversation = useCallback(async () => {
        const body = {
            chatId,
            botName,
        };

        await post("/api/chat/completeChat", body).finally(() => {
            onChatCompletion(chatId, botName);
        });
    }, [post, botName, chatId, onChatCompletion]);

    const onNewMessageRecivedCallback = useCallback(
        (message: string) => {
            const endUserMessage: ChatMessage = {
                sender: "User",
                message,
                time: new Date(),
            };
            setMessages((existingMessages) => [
                ...existingMessages,
                endUserMessage,
            ]);
            pushConversationMessage(endUserMessage, chatId, true);

            if (chatBoxDiv.current !== undefined) {
                chatBoxDiv.current.scrollTop = chatBoxDiv.current.scrollHeight;
            }
            setTyping(false);
        },
        [chatId, pushConversationMessage]
    );

    const debouncedDisableTypingIndicatorFactory = useCallback(() => {
        return debounce(() => {
            setTyping(false);
        }, 3000);
    }, []);

    const [debouncedDisableTypingIndicator] = useState(debouncedDisableTypingIndicatorFactory);

    const onNewTypingEventReceivedCallback = useCallback(() => {
        setTyping(true);
        debouncedDisableTypingIndicator();
    }, [debouncedDisableTypingIndicator]);

    const onNewFileRecievedCallback = useCallback((fileName: string, success: boolean) => {
        if (success) {
            const fileMessage: FileMessage = {
                fileName,
                time: new Date()
            };
            setMessages(existingMessages => [
                ...existingMessages,
                fileMessage
            ]);

            pushConversationMessage(fileMessage, chatId, true);
        }
        else {
            const message: ChatMessage = {
                sender: "Bot",
                message: `file ${fileName} was sent but viruses detected`,
                time: new Date(),
            };

            setMessages((existingMessages) => [
                ...existingMessages,
                message,
            ]);
            pushConversationMessage(message, chatId, true);

            if (chatBoxDiv.current !== undefined) {
                chatBoxDiv.current.scrollTop = chatBoxDiv.current.scrollHeight;
            }
        }
    }, [pushConversationMessage, chatId]);

    const requestFile = useCallback(async () => {
        const params = new URLSearchParams({
            chatId,
            botName
        });
        setRequestingFile(true);
        try {
            const message: ChatMessage = {
                sender: "Admin",
                message: "File has been requested",
                time: new Date(),
            };
            setMessages(existingMessages => [
                ...existingMessages,
                message
            ]);

            pushConversationMessage(message, chatId, true);
            await get("api/chat/requestFile?" + params.toString());
            setRequestingFile(false);
        }
        catch {
            setRequestingFile(false);
        }
    }, [get, chatId, botName, pushConversationMessage]);

    const typingIntervalMilliseconds = 10 * 1000;

    useEffect(() => {
        const interval = setInterval(async () => {
            if (valueRef.current.value && valueRef.current.value.length > 0) {
                await sendTypingEvent(botName, chatId);
            }
        }, typingIntervalMilliseconds);
        return () => clearInterval(interval);
    }, [valueRef.current.value, botName, chatId, sendTypingEvent, typingIntervalMilliseconds]);

    useEffect(() => {
        newMessage.on("message", onNewMessageRecivedCallback);

        return () => {
            newMessage.removeListener("message", onNewMessageRecivedCallback);
        };
    }, [newMessage, onNewMessageRecivedCallback]);

    useEffect(() => {
        newMessage.on("typing", onNewTypingEventReceivedCallback);

        return () => {
            newMessage.removeListener("typing", onNewTypingEventReceivedCallback);
        };
    }, [newMessage, onNewTypingEventReceivedCallback]);

    useEffect(() => {
        newMessage.on("file", onNewFileRecievedCallback);

        return () => {
            newMessage.removeListener("file", onNewFileRecievedCallback);
        };
    }, [newMessage, onNewFileRecievedCallback]);

    useEffect(() => {
        if (chatBoxDiv.current !== undefined) {
            chatBoxDiv.current.scrollTop = chatBoxDiv.current.scrollHeight;
        }
    }, []);

    const startClosing = useCallback(() => setCloseDialogOpen(true), []);
    const onCloseCancel = useCallback(() => setCloseDialogOpen(false), []);
    const handleHideChat = useCallback(() => setCurrentChat(""), [setCurrentChat]);

    const onCloseAccept = useCallback(
        async (closeMessage: string) => {
            const endUserMessage = "Admin closed the chat: " + closeMessage;
            await sendMessage(endUserMessage);

            if (isMobile) {
                handleHideChat();
            }

            await closeConversation();
            setCloseDialogOpen(false);
        }, [closeConversation, sendMessage, isMobile, handleHideChat]);

    const handleOpenChatInformation = useCallback(() => setChatInformationOpen(true), []);
    const handleCloseChatInformation = useCallback(() => setChatInformationOpen(false), []);

    return (
        <Paper
            sx={{
                height: "100%",
                minHeight: "500px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>
            <Toolbar>
                {isMobile && (
                    <IconButton onClick={handleHideChat}>
                        <ArrowBackIosIcon color="primary" fontSize="small" />
                    </IconButton>
                )}
                <Typography variant="h3" color="primary"
                    sx={{
                        wordBreak: "break-word",
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                    }}
                >{userName}</Typography>
                <Box sx={{ flexGrow: 1 }} />
                {!chatInformationOpen ? (
                    <IconButton onClick={handleOpenChatInformation}>
                        <InfoOutlinedIcon color="primary" />
                    </IconButton>
                ) : (
                    <></>
                )}
                <Button onClick={startClosing} variant="text">
                    End chat
                </Button>
                <Button onClick={requestFile} disabled={requestingFile} variant="text">
                    Request File
                </Button>
            </Toolbar>

            <Divider />

            <Box
                sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column-reverse" : "row",
                    flexGrow: 1,
                    height: "70%",
                }}>
                <Box
                    sx={{
                        display: "flex",
                        flex: "1 0 66%",
                        flexDirection: "column",
                        padding: theme.spacing(0, 2, 2),
                        justifyContent: "flex-end",
                        backgroundColor: theme.palette.grey[50],
                        maxHeight: "100%"
                    }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            overflowY: "auto",
                        }}
                        ref={chatBoxDiv}>
                        {messages.map((message, index) => {
                            if (isChatMessage(message)) {
                                return (
                                    <ChatMessageBox key={index} {...message} />
                                );
                            }
                            else {
                                return <ChatFileBox key={index} fileName={message.fileName} chatId={chatId} />;
                            }
                        })}
                    </Box>
                    <Box>
                        <TypingIndicator isShown={typing} />
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", pr: theme.spacing(1) }}>
                        <TextField
                            sx={{ flexGrow: 1, backgroundColor: theme.palette.background.paper }}
                            data-testid="SendMessageInput"
                            inputRef={valueRef}
                            onKeyDown={onKeyDown} />
                        <IconButton onClick={onSendClicked}>
                            <Send />
                        </IconButton>
                    </Box>
                </Box>
                {chatInformationOpen ? (
                    <ChatInformation
                        handleCloseChatInformation={handleCloseChatInformation}
                        botName={botName}
                        chatId={chatId}
                        initialMessage={conversationMessages[0] as ChatMessage}
                        userEmail={userEmail}
                        userName={userName}
                        messageHistory={messages.filter(message => isChatMessage(message)) as ChatMessage[]}
                    />
                ) : (
                    <></>
                )}
            </Box>
            <CloseChatConfirmDialog
                open={closeDialogOpen}
                chatName={userName}
                onCancel={onCloseCancel}
                onAccept={onCloseAccept}
            />
        </Paper>
    );
};
