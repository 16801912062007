import * as React from "react";

import { Grid, Box } from "@mui/material";

export interface BBMapProps {
    panel: any;
}
export const BBMap: React.FC<BBMapProps> = ({ panel }) => {
    return (
        <Box>
            <Grid item xs>
                <iframe src={panel?.mapLink} className="bb-map" ></iframe>
            </Grid>
        </Box>
    );
};
