import { Autocomplete, Box, lighten, styled, TextField } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from "react";
import { PageRow } from "../../../schemas/pages/schema";
import { SearchItem } from "./search";

export interface SearchProps {
    bbRows: PageRow[];
}

export const PageSearch: React.FC<SearchProps> = ({ bbRows }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<SearchItem[]>([]);

    const GroupHeader = styled('div')(({ theme }) => ({
        position: 'sticky',
        top: '-8px',
        padding: '4px 10px',
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.85)
    }));

    const GroupItems = styled('ul')({
        padding: 0,
    });

    const searchContent = (searchValue: string, bbRow: any[]) => {
        const results: SearchItem[] = [];

        const searchInObject = (obj: any, schemaIndex: any) => {
            for (const key in obj) {
                // eslint-disable-next-line no-prototype-builtins
                if (obj.hasOwnProperty(key)) {
                    const rowIndexExists = results.some(result => result.schemaIndex.rowIndex === schemaIndex.rowIndex && result.value === obj[key]);

                    if (obj[key] && obj[key].toString().includes(searchValue) && !rowIndexExists) {
                        results.push({ key, value: obj[key], schemaIndex });
                    } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                        searchInObject(obj[key], schemaIndex);
                    } else if (Array.isArray(obj[key])) {
                        obj[key].forEach((item: any, index: number) => {
                            searchInObject(item, { ...schemaIndex, [`${key}Index`]: index });
                        });
                    }
                }
            }
        };

        bbRow.forEach((row, rowIndex) => {
            searchInObject(row, { rowIndex });

            row.columns?.forEach((column: any, columnIndex: any) => {
                searchInObject(column, { rowIndex, columnIndex });

                column.bbPanelTypes?.forEach((panel: any, panelIndex: any) => {
                    searchInObject(panel, { rowIndex, columnIndex, panelIndex });
                });
            });
        });

        return results;
    };

    const useDebounce = (value: string, delay: number) => {
        const [debouncedValue, setDebouncedValue] = useState(value);

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }, [value, delay]);

        return debouncedValue;
    };

    const navigateToElement = (item: any) => {
        if (item === null) {return;}
        const targetElement = document.getElementById(`row-${item.schemaIndex.rowIndex}`);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
        setSearchTerm('');
    };

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect(() => {
        if (debouncedSearchTerm) {
            const results = searchContent(debouncedSearchTerm, bbRows);
            setSearchResults([...results]);
        } else {
            setSearchResults([]);
        }
    }, [debouncedSearchTerm, bbRows]);

    return (
        <Box>
            <Autocomplete
                options={searchResults}
                inputValue={searchTerm}
                onInputChange={(e, value) => setSearchTerm(value)}
                groupBy={(option) => {
                    const rowIndex = option.schemaIndex.rowIndex + 1;
                    return rowIndex.toString();
                }}
                getOptionLabel={(option) => `${option.value}`}
                sx={{ width: 500, paddingTop: 2 }}
                onChange={(e, v) => navigateToElement(v)}
                renderInput={(params) => <TextField {...params} label="Search page" />}
                renderGroup={(params) => (
                    <li key={params.key}>
                        <GroupHeader>Row: {params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
            />
        </Box>
    );
};
