import {
    Chart as ChartJS,
    TimeSeriesScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    Filler,
    ChartOptions
} from "chart.js";
import { Bar } from "react-chartjs-2";

import "chartjs-adapter-moment";

import { bbot_visuals_splitDataByGroup, flattenDictValue, COLORB, get_new_data } from "./AnalyticsHelpers";

ChartJS.register(
    TimeSeriesScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    Filler
);

interface Props {
    data: any | undefined;
    timeunit: string | "day";
    simpleData: boolean | false;
    title?: string | "App Sessions By Day";
}

export const AnalyticsBarChart = ({ data, timeunit, simpleData, title }: Props) => {

    if (!data) {
        return null;
    }
    if (!simpleData && !data["005-plot-ai-app"]) {
        return null;
    }
    let datag = data.data as any;
    if (!simpleData) {
        datag = data["005-plot-ai-app"].data as any;
    }
    const options: ChartOptions<"bar"> = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
            title: {
                display: true,
                text: title,
            }
        },
        scales: {
            x: {
                type: "time",
                time: {
                    unit: timeunit as any,
                },
                stacked: true,
            },
            y: {
                ticks: {
                    precision: 0
                },
                stacked: true,
                title: { text: "Notifications Sent", display: true },
                grace: '20%'
            }
        }
    };

    const columnNames = [
        {
            Hour: {
                label: "Time"
            }
        },
        {
            council: {
                label: "Council"
            }
        },
        {
            Conversations: {
                label: "Conversations"
            }
        }
    ];

    const flatColumns = flattenDictValue(columnNames);

    const new_col_dict = flatColumns.reduce((r: any, o: any) => {
        Object.keys(o).forEach((k: string) => { r[k] = o[k]; });
        return r;
    }, {});

    const cols_dict = [];
    for (const [key, ] of Object.entries(datag)) {
        cols_dict.push({ title: key });
    }

    const rows = Object.keys(datag[cols_dict[0].title]);

    const new_data = get_new_data(rows, datag, new_col_dict);

    const k2 = bbot_visuals_splitDataByGroup(
        new_data,
        "Council",
        "Time",
        {
            ealing: {
                backgroundColor: "#000000"
            },
            hull: {
                backgroundColor: "#000000"
            }
        },
        {
            barPercentage: 1,
            barThickness: 15,
            maxBarThickness: 25,
            hoverBorderWidth: 1,
            hoverRadius: 3
        },
        true
    );

    const chartConfig = {
        grouping: "Council",
        autoColour: true,
        groupingConfig: {
            ealing: {
                backgroundColor: "#000000"
            },
            hull: {
                backgroundColor: "#000000"
            }
        },
        barConfig: {
            barPercentage: 1,
            barThickness: 15,
            maxBarThickness: 25,
            hoverBorderWidth: 1,
            hoverRadius: 3
        },
        chartjsConfig: {
            type: "bar",
            data: { datasets: [] },
            options: {
                barValueSpacing: 100,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        title: {
                            display: true,
                            text: "Domain",
                            padding: 2
                        },
                        position: "bottom",
                        align: "start"
                    },
                    tooltip: {
                        position: "nearest"
                    },
                    title: {
                        display: false,
                        text: "Sessions By Date"
                    },
                }
            }
        }
    };

    (chartConfig.chartjsConfig.data as any).datasets = k2[1];
    // eslint-disable-next-line guard-for-in
    for (const trace in k2[1]) {
        (chartConfig.chartjsConfig.data as any).datasets[trace].parsing = {
            xAxisKey: "Time",
            yAxisKey: "Conversations"
        };
        (chartConfig.chartjsConfig.data as any).datasets[trace].backgroundColor = COLORB.BeebotSet["10"][trace];
    };

    return <Bar options={options} data={chartConfig.chartjsConfig.data} />;

};
