import React, { useState } from 'react';
import { Alert, Box, Button, Grid, TextField } from '@mui/material';
import  { Schema } from "../../../schemas/pages/schema";
import { toast } from "react-toastify";

interface CodeEditorProps {
    schema: string;
    onUpdate: (schema: Schema) => void;
}

const CodeEditor: React.FC<CodeEditorProps> = ({ schema, onUpdate }) => {
    const [codeSchema, updateCodeSchema] = useState<string>(schema);

    const updateCodeEditor = React.useCallback((e: any) => {
        updateCodeSchema(e.target.value);
    }, []);

    const onSaveCodeEditor = React.useCallback(() => {
        onUpdate(JSON.parse(codeSchema));
        toast.success(`Schema has been updated`);
    }, [codeSchema, onUpdate]);

    return (
        <>
            <Box className="bb-flex bb-flex-column bb-tac" sx={{ minHeight: "60vh" }}>
                <Box className="bb-flex page-edit-buttons" >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ paddingBottom: '10px' }}>
                            <Alert
                                className="bb-title-info bb-mb-2"
                                severity="info">
                                {/* eslint-disable-next-line max-len */}
                                When using the code editor cross check the expected output by using the update button and checking the visual editor before saving.
                            </Alert>
                        </Grid>
                        <Grid item xs={1} sx={{ paddingBottom: '10px' }}>
                            <Button onClick={onSaveCodeEditor} variant="outlined" disabled={false}>Update</Button>
                        </Grid>
                    </Grid>
                </Box>
                <TextField
                    id="code-editor"
                    multiline
                    maxRows={40}
                    fullWidth
                    variant="filled"
                    onChange={updateCodeEditor}
                    value={codeSchema}
                    inputProps={{ style: { fontFamily: "'Courier New', Courier, monospace" } }}
                />
            </Box>
        </>
    );
};

export { CodeEditor };
