import { SearchOutlined } from "@mui/icons-material";
import { TextField, styled } from "@mui/material";
import { useCallback, useState, useEffect } from "react";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    marginLeft: 0,
    display: "inline-block",
    textAlign: "center",
    width: "100%",
    verticalAlign: "middle",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "100%"
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    position: "relative",
    pointerEvents: "none",
    display: "inline",
    alignItems: "center",
    justifyContent: "center",
    width: "60px",
    top: "3px",
    left: "12px"
}));

const StyledInputBase = styled(TextField)(({ theme }) => ({
    "color": "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "200px",
        [theme.breakpoints.up("sm")]: {
            "width": "22ch",
            "&:focus": {
                width: "30ch",
            },
        },
    },
}));

export interface SearchFieldProps {
    searchString?: string;
    callSearch: (searchString?: string) => void;
    timeout?: number;
}

export const SearchField = ({ searchString, callSearch, timeout }: SearchFieldProps) => {

    const [searchStringLocal, setSearchString] = useState<string>();

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            callSearch(searchStringLocal);
        }, timeout ?? 1500);

        return () => clearTimeout(timeOutId);
    }, [searchStringLocal, callSearch, timeout]);

    useEffect(() => {
        setSearchString(searchString);
    }, [searchString]);

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchString(event.target.value);
        }, [setSearchString]);

    return (
        <Search>
            <SearchIconWrapper>
                <SearchOutlined />
            </SearchIconWrapper>

            <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                variant="outlined"
                value={searchStringLocal}
                onChange={handleChange}
            />
        </Search>
    );
};
