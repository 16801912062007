import { useCallback } from 'react';
import { WidgetProps } from '@rjsf/utils';

import { MenuItem, Select, InputLabel } from '@mui/material';

import { Profile } from "../../../../schemas/profile/schema";

import { stringSort } from "../../../../services/helper/sort";

import { useSmartPageContext } from "../../smartPages/smartPagesContext";

const ProfileWidget = ({ onChange, id, value, label }: WidgetProps) => {
    const { profileData } = useSmartPageContext();

    const handleChange = useCallback((event: any) => {
        const newValue = event.target.value;
        onChange(newValue);
    }, [onChange]);

    return (
        <>
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <Select
                id={id}
                value={value || (profileData?.profile && profileData?.profile[0]?.type)}
                labelId={`${id}-label`}
                label={label}
                onChange={handleChange}
                variant="outlined"
            >
                {profileData?.profile
                    ?.sort((a, b) => stringSort(a.title || a.type, b.title || b.type))
                    .map((profile: Profile) =>
                        <MenuItem key={profile.type} value={profile.type}>{profile.title || profile.type}</MenuItem>)
                }
            </Select>
        </>
    );
};

export { ProfileWidget };
