import { Card, CardContent, CardHeader, Typography } from "@mui/material";

import { LoadingCard } from "../../shared/LoadingCard";

interface ActiveConversationsProps {
    isLoading: boolean;
    conversationCount: number;
}

export const ActiveConversations = (props: ActiveConversationsProps) => {
    if (props.isLoading) {
        return (<LoadingCard title="Active Users" />);
    }

    return (
        <Card>
            <CardHeader title="Active Users" />
            <CardContent>
                <Typography variant="h1" textAlign="center">
                    {props.conversationCount}
                </Typography>
            </CardContent>
        </Card>);
};
