import { useParams, Link } from "react-router-dom";

import Typography from "@mui/material/Typography";
import {
    Container,
    Box,
    Alert,
    CircularProgress
} from "@mui/material";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import { useState, useEffect } from "react";

import useDataClient from "../../../axios/dataClient";

import { getApp } from "../../../services/api/FrontendService";

import { AppViewModel } from "../../../services/model/apps/AppViewModel";

import { AppSettingsBaseForm } from "./AppSettingsBaseForm";

import { getStagingUrl } from "../../../services/helper/regex";

export const AppSettings = () => {
    const { appId } = useParams();
    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const { get } = useDataClient();

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                const data = await getApp(get)(appId);
                setAppData(data);
            }
        };

        fetchData();
    },
    [appId, get]);

    if (!appData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <Container>
            <Box className="bb-title-bar bb-mt-3">
                <h2 className="bb-m0 bb-p0">Settings: {appData.displayName}</h2>
                <Box className="bb-ml-auto bb-align-items-center bb-flex">
                    <OpenInNewIcon className="bb-open-app-link"></OpenInNewIcon>
                    <a rel="noreferrer" className="bb-mr-1" href={`https://${getStagingUrl(appData.url)}`} target="_blank">View my
                        Staging App</a>
                    <OpenInNewIcon className="bb-open-app-link"></OpenInNewIcon>
                    <a rel="noreferrer" className="bb-mr-1" href={`https://${appData.url}`} target="_blank">
                    View my Live App</a>
                </Box>
            </Box>
            <Breadcrumbs className="bb-mb-3 bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                <Link to="/applications">Applications</Link>
                <Link to={`/applications/${appId}`}>Modules</Link>
                <Typography color="text.primary" className="bb-m0 bb-p0">Settings</Typography>
            </Breadcrumbs>
            <Alert className="bb-title-info bb-mb-2" severity="info">Manage the pages of
                your <b>{appData.displayName}</b> application.&nbsp;
            <a rel="noreferrer" className="bb-mr-1" href={`https://delightful-bush-088acfb03.5.azurestaticapps.net/?page=theme`}
                target="_blank">Learn More</a></Alert>
            <Box className="bb-flex bb-ui-box bb-flex-column bb-tac" sx={{ minHeight: "60vh" }}>
                <AppSettingsBaseForm appId={appId}></AppSettingsBaseForm>
            </Box>
        </Container>
    );
};
