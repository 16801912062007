import { JSONSchema7 } from "json-schema";

import * as React from "react";

import { Form } from '@rjsf/mui';

import validatorAjv8 from "@rjsf/validator-ajv8";

import { toast } from "react-toastify";

import { UiSchema } from "@rjsf/utils";

import { CardContent, Grid, Card, Typography, Box, CardHeader, Tabs, Tab, Modal, useTheme, Button } from "@mui/material";

import { Schema, PageRow, PageColumn, PagePanel } from "../../../schemas/pages/schema";

import { getPanelSchema } from "../../../schemas/pages/getPanelSchema";

import pageSchemaModel from "../../../schemas/pages/pageSchema.json";

import pageRowSchemaModel from "../../../schemas/pages/pageRow.json";

import pageColumnSchemaModel from "../../../schemas/pages/pageColumn.json";

import { EditButton } from "./EditButton";

import { AllButtons } from "./AllButtons";

import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";

import ViewColumnIcon from "@mui/icons-material/ViewColumn";

import { PageEditModalForm } from "./PageEditModalForm";

import { PageEditColumn } from "./PageEditColumn";
import { usePageContext } from "./pagesContext";
import { DeleteButton } from "./DeleteButton";
import { CodeEditor } from "./CodeEditor";
import { Fragment } from "react";

export interface PageEditFormProps {
    pageName: string | undefined;
    pageSchema: Schema;
    submitting: boolean;
    onUpdate: (schema: Schema) => void;
    onError: (e: any) => void;
    onSubmit: (e: any) => void;
}

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const PageEditForm: React.FC<PageEditFormProps> = ({
    pageName,
    submitting,
    onUpdate,
    onError,
    onSubmit,
    pageSchema
}) => {
    const { setCurrentFormData, setCurrentSchema, setSelectedTableId } = usePageContext();

    const theme = useTheme();

    const [openEditModal, setOpenEditModal] = React.useState(false);
    const [currentRowIndex, setCurrentRowIndex] = React.useState<number | undefined>();
    const [currentColumnIndex, setCurrentColumnIndex] = React.useState<number | undefined>();
    const [currentPanelIndex, setCurrentPanelIndex] = React.useState<number | undefined>();
    const [currentTabValue, setCurrentTabValue] = React.useState(0);

    const uiSchema: UiSchema = {
        'ui:submitButtonOptions': {
            props: {
                disabled: false,
            },
            norender: true,
            submitText: 'Submit'
        },
        "pageName": {
            "ui:readonly": !!pageName,
            "ui:size": "small",
            "props": {
                "ui:className": "bb-page-name"
            }
        },
        "preventDeletion": {
            "ui:widget": "hidden"
        }
    };

    const EditButtonRender = (rowIndex: number, columnIndex?: number, panelIndex?: number) => {
        return (
            <EditButton
                key={`row-${rowIndex}-edit`}
                rowIndex={rowIndex}
                columnIndex={columnIndex}
                panelIndex={panelIndex}
                onClick={handleModalOpen}
            />
        );
    };

    const DeleteButtonRender = (rowIndex: number, columnIndex?: number, panelIndex?: number) => {
        return (
            <DeleteButton
                key={`row-${rowIndex}-delete`}
                rowIndex={rowIndex}
                columnIndex={columnIndex}
                panelIndex={panelIndex}
                onClick={handleDelete}
            />
        );
    };

    const AllButtonsRender = (rowIndex: number, indexTotal: number, columnIndex?: number) => {
        return (
            <AllButtons
                key={`row-${rowIndex}-all`}
                rowIndex={rowIndex}
                indexTotal={indexTotal}
                columnIndex={columnIndex}
                onMoveUpClick={handleMoveUp}
                onMoveDownClick={handleMoveDown}
                onEditClick={handleModalOpen}
                onDeleteClick={handleDelete}
            />
        );
    };

    const handleColumnModalOpen = React.useCallback((rowIndex: number, columnIndex: number) => {
        const column = pageSchema.bbRow[rowIndex].columns[columnIndex];
        setCurrentFormData(column);
        setCurrentSchema(pageColumnSchemaModel as JSONSchema7);
        setCurrentColumnIndex(columnIndex);

        setCurrentRowIndex(rowIndex);
        setOpenEditModal(true);
        setSelectedTableId(undefined);
    }, [pageSchema.bbRow, setCurrentFormData, setCurrentSchema, setSelectedTableId]);

    const handlePanelModalOpen = React.useCallback((rowIndex: number, columnIndex: number, panelIndex: number) => {
        const panel = pageSchema.bbRow[rowIndex].columns[columnIndex].bbPanelTypes[panelIndex];
        const panelSchema = getPanelSchema(panel.type);

        if (panelSchema) {
            setCurrentFormData(panel);
            setCurrentSchema(panelSchema);
            setCurrentPanelIndex(panelIndex);
            setCurrentColumnIndex(columnIndex);
            setCurrentRowIndex(rowIndex);
            setOpenEditModal(true);
            setSelectedTableId(undefined);
        }
    }, [pageSchema.bbRow, setCurrentFormData, setCurrentSchema, setSelectedTableId]);

    const handleModalOpen = React.useCallback((rowIndex: number, columnIndex?: number, panelIndex?: number) => {

        if (panelIndex !== undefined && columnIndex !== undefined) {
            handlePanelModalOpen(rowIndex, columnIndex, panelIndex);
            return;
        }

        if (columnIndex !== undefined) {
            handleColumnModalOpen(rowIndex, columnIndex);
            return;
        }

        const row = pageSchema.bbRow[rowIndex];
        setCurrentFormData(row);
        setCurrentSchema(pageRowSchemaModel as JSONSchema7);

        setCurrentRowIndex(rowIndex);
        setOpenEditModal(true);
        setSelectedTableId(undefined);
    }, [pageSchema.bbRow, setCurrentFormData, setCurrentSchema, handlePanelModalOpen, handleColumnModalOpen, setSelectedTableId]);

    const handleColumnDelete = React.useCallback((rowIndex: number, columnIndex: number) => {

        const newPageSchema = { ...pageSchema };

        newPageSchema.bbRow[rowIndex].columns.splice(columnIndex, 1);
        newPageSchema.bbRow[rowIndex].columns = newPageSchema.bbRow[rowIndex].columns.map((column, index) => {
            column.order = index;
            return column;
        });

        onUpdate(newPageSchema);
        toast.success(`Row ${rowIndex + 1} Column ${columnIndex + 1} removed`);
    }, [pageSchema, onUpdate]);

    const handlePanelDelete = React.useCallback((rowIndex: number, columnIndex: number, panelIndex: number) => {
        const newPageSchema = { ...pageSchema };

        newPageSchema.bbRow[rowIndex].columns[columnIndex].bbPanelTypes.splice(panelIndex, 1);
        newPageSchema.bbRow[rowIndex].columns[columnIndex].bbPanelTypes = newPageSchema.bbRow[rowIndex].columns[columnIndex].bbPanelTypes
            .map((panel, index) => {
                panel.order = index;
                return panel;
            });

        onUpdate(newPageSchema);
        toast.success(`Row ${rowIndex + 1} Column ${columnIndex + 1} Panel ${panelIndex + 1} removed`);
    }, [pageSchema, onUpdate]);

    const handleDelete = React.useCallback((rowIndex: number, columnIndex?: number, panelIndex?: number) => {

        if (panelIndex !== undefined && columnIndex !== undefined) {
            handlePanelDelete(rowIndex, columnIndex, panelIndex);
            return;
        }

        if (columnIndex !== undefined) {
            handleColumnDelete(rowIndex, columnIndex);
            return;
        }

        const newPageSchema = { ...pageSchema };

        newPageSchema.bbRow.splice(rowIndex, 1);
        newPageSchema.bbRow = newPageSchema.bbRow.map((row, index) => {
            row.order = index;
            return row;
        });

        onUpdate(newPageSchema);
        toast.success(`Row ${rowIndex + 1} removed`);
    }, [pageSchema, onUpdate, handlePanelDelete, handleColumnDelete]);

    const swapRows = React.useCallback((sourceRowIndex: number, targetRowIndex: number): Schema => {

        const newPageSchema = { ...pageSchema };

        const rowToMove = newPageSchema.bbRow[sourceRowIndex];
        newPageSchema.bbRow[sourceRowIndex] = newPageSchema.bbRow[targetRowIndex];
        newPageSchema.bbRow[targetRowIndex] = rowToMove;
        newPageSchema.bbRow = newPageSchema.bbRow.map((row, index) => {
            row.order = index;
            return row;
        });

        return newPageSchema;
    }, [pageSchema]);

    const swapColumns = React.useCallback((rowIndex: number, sourceColumnIndex: number, targetColumnIndex: number): Schema => {

        const newPageSchema = { ...pageSchema };

        const columnToMove = newPageSchema.bbRow[rowIndex].columns[sourceColumnIndex];
        newPageSchema.bbRow[rowIndex].columns[sourceColumnIndex] = newPageSchema.bbRow[rowIndex].columns[targetColumnIndex];
        newPageSchema.bbRow[rowIndex].columns[targetColumnIndex] = columnToMove;
        newPageSchema.bbRow[rowIndex].columns = newPageSchema.bbRow[rowIndex].columns.map((column, index) => {
            column.order = index;
            return column;
        });

        return newPageSchema;
    }, [pageSchema]);

    const handleColumnMoveUp = React.useCallback((rowIndex: number, columnIndex: number) => {

        if (columnIndex <= 0 || pageSchema.bbRow[rowIndex].columns.length <= (columnIndex)) {
            return;
        }

        const newPageSchema = swapColumns(rowIndex, columnIndex, columnIndex - 1);

        onUpdate(newPageSchema);
    }, [pageSchema, onUpdate, swapColumns]);

    const handleMoveUp = React.useCallback((rowIndex: number, columnIndex?: number) => {

        if (columnIndex !== undefined) {
            handleColumnMoveUp(rowIndex, columnIndex);
            return;
        }

        if (rowIndex <= 0 || pageSchema.bbRow.length <= (rowIndex)) {
            return;
        }

        const newPageSchema = swapRows(rowIndex, rowIndex - 1);

        onUpdate(newPageSchema);
    }, [pageSchema, handleColumnMoveUp, onUpdate, swapRows]);

    const handleColumnMoveDown = React.useCallback((rowIndex: number, columnIndex: number) => {

        if (pageSchema.bbRow[rowIndex].columns.length <= (columnIndex + 1)) {
            return;
        }

        const newPageSchema = swapColumns(rowIndex, columnIndex, columnIndex + 1);

        onUpdate(newPageSchema);
    }, [pageSchema, onUpdate, swapColumns]);

    const handleMoveDown = React.useCallback((rowIndex: number, columnIndex?: number) => {

        if (columnIndex !== undefined) {
            handleColumnMoveDown(rowIndex, columnIndex);
            return;
        }

        if (pageSchema.bbRow.length <= (rowIndex + 1)) {
            return;
        }

        const newPageSchema = swapRows(rowIndex, rowIndex + 1);

        onUpdate(newPageSchema);
    }, [pageSchema, handleColumnMoveDown, onUpdate, swapRows]);

    const handleModalClose = React.useCallback(() => {
        return;
    }, []);

    const clearCurrentData = React.useCallback(() => {
        setCurrentFormData(undefined);

        setCurrentRowIndex(undefined);
        setCurrentColumnIndex(undefined);
        setCurrentPanelIndex(undefined);
    }, [setCurrentFormData]);

    const updatePanelData = React.useCallback((formData: any) => {
        const panel = formData as PagePanel;

        if (formData && currentColumnIndex !== undefined && currentRowIndex !== undefined && currentPanelIndex !== undefined) {
            const newPageSchema = { ...pageSchema };

            const currentPanel = newPageSchema.bbRow[currentRowIndex].columns[currentColumnIndex].bbPanelTypes[currentPanelIndex];
            newPageSchema.bbRow[currentRowIndex].columns[currentColumnIndex].bbPanelTypes[currentPanelIndex] = { ...currentPanel, ...panel };
            onUpdate(newPageSchema);
        }

        clearCurrentData();
        setOpenEditModal(false);
        setSelectedTableId(undefined);
    }, [clearCurrentData, currentColumnIndex, currentPanelIndex, currentRowIndex, onUpdate, pageSchema, setSelectedTableId]);

    const updateColumnData = React.useCallback((formData: any) => {
        const column = formData as PageColumn;

        if (formData && currentColumnIndex !== undefined && currentRowIndex !== undefined) {
            const newPageSchema = { ...pageSchema };

            const currentColumn = newPageSchema.bbRow[currentRowIndex].columns[currentColumnIndex];

            const newColumn = { ...currentColumn, ...column };

            let updatedPanelTypes: PagePanel[] = newColumn.bbPanelTypesList.map((panelType) => {

                const existingPanel = newColumn.bbPanelTypes.find((panel) => panel.order === panelType.order);

                if (existingPanel) {
                    return existingPanel;
                }

                return { type: panelType.panelType };
            });

            updatedPanelTypes = updatedPanelTypes.map((panelType, index) => {
                return { ...panelType, order: index };
            });

            newColumn.bbPanelTypes = updatedPanelTypes;

            newColumn.bbPanelTypesList = newColumn.bbPanelTypesList.map((panelType, index) => {
                return { ...panelType, order: index };
            });

            newPageSchema.bbRow[currentRowIndex].columns[currentColumnIndex] = newColumn;
            onUpdate(newPageSchema);
        }

        clearCurrentData();
        setOpenEditModal(false);
        setSelectedTableId(undefined);
    }, [clearCurrentData, currentColumnIndex, currentRowIndex, onUpdate, pageSchema, setSelectedTableId]);

    const handleModalCancel = React.useCallback((): void => {
        clearCurrentData();
        setOpenEditModal(false);
        setSelectedTableId(undefined);
    }, [clearCurrentData, setSelectedTableId]);

    const handleModalUpdate = React.useCallback((e: any): void => {
        if (currentRowIndex === undefined) {
            return;
        }

        if (currentPanelIndex !== undefined && currentColumnIndex !== undefined) {
            updatePanelData(e.formData);
            return;
        }

        if (currentColumnIndex !== undefined) {
            updateColumnData(e.formData);
            return;
        }

        const row = e.formData as PageRow;
        if (row) {
            const newPageSchema = { ...pageSchema };
            const currentRow = newPageSchema.bbRow[currentRowIndex];
            newPageSchema.bbRow[currentRowIndex] = { ...currentRow, ...row };
            onUpdate(newPageSchema);
        }

        clearCurrentData();
        setOpenEditModal(false);
        setSelectedTableId(undefined);
    }, [currentRowIndex, currentPanelIndex, currentColumnIndex, clearCurrentData, updatePanelData, updateColumnData, pageSchema, onUpdate, setSelectedTableId]);

    const CreateTabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    };

    const handleTabChange = React.useCallback((event: React.SyntheticEvent, newValue: number) => {
        setCurrentTabValue(newValue);
    }, []);

    const handleAddNewRow = React.useCallback((index: number | undefined = undefined) => {
        const newPageSchema = { ...pageSchema };
        const newRow = { rowTitle: "", rowSubTitle: "", columns: [] };
        // We are ignoring the type script error because setting the other properties is preventing the form from setting defaults
        if (index !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            newPageSchema.bbRow.splice(index + 1, 0, newRow);
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            newPageSchema.bbRow.push(newRow);
        }

        onUpdate(newPageSchema);
    }, [pageSchema, onUpdate]);

    const handleAddNewColumn = React.useCallback((rowIndex: number) => {
        const newPageSchema = { ...pageSchema };
        newPageSchema.bbRow[rowIndex].columns.push(
            {
                // We are ignoring the type script error because setting the other properties is preventing the form from setting defaults
                //colBg: "",
                //colGrid: "",
                //colPad: "",
                //colSpan: null,
                //colTextAlign: "",
                bbPanelTypes: [],
                bbPanelTypesList: []
            });
        onUpdate(newPageSchema);
    }, [pageSchema, onUpdate]);

    return (
        <Fragment>
            <Modal
                open={openEditModal}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ "& .MuiBox-root": { backgroundColor: theme.palette.background.default } }}
            >
                <PageEditModalForm
                    onUpdate={handleModalUpdate}
                    onError={onError}
                    onCancel={handleModalCancel}
                />
            </Modal>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={currentTabValue}
                        onChange={handleTabChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                    >
                        <Tab id={`tab-${0}`} label="Visual Editor" aria-controls={`tabpanel-${0}`}/>
                        <Tab id={`tab-${1}`} label="Code Editor" aria-controls={`tabpanel-${1}`}/>
                    </Tabs>
                </Box>
                <CreateTabPanel value={currentTabValue} index={0}>
                    <Form
                        id={`${pageName}_pagesubmit`}
                        schema={pageSchemaModel as JSONSchema7}
                        formData={pageSchema}
                        validator={validatorAjv8}
                        onSubmit={onSubmit}
                        onError={onError}
                        uiSchema={uiSchema}
                        liveValidate={true}
                        showErrorList={false}
                        disabled={submitting}
                        className="bb-edit-page-content"
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={1}>
                                {pageSchema?.bbRow?.map((row, rowIndex) =>
                                    (<Grid id={`row-${rowIndex}`} key={`row-${rowIndex}`} item xs={12}>
                                        <Card variant="outlined" className="bb-page-editor-row">
                                            {/*Row Header*/}
                                            <CardHeader className="bb-pe-row-title bb-pe-titles"
                                                titleTypographyProps={{
                                                    textAlign: "left"
                                                }}
                                                action={
                                                    AllButtonsRender(rowIndex, pageSchema.bbRow.length)
                                                }
                                                title={`Row ${rowIndex + 1}`}
                                            />
                                            <CardContent className="bb-pe-row-content">
                                                {(row.rowTitle || row.rowSubTitle) &&
                                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                        {/*Row title & Sub Title*/}
                                                        <h2>{row.rowTitle}</h2>
                                                        <h4 className="bb-mb-3 bb-mt-0 bb-row-subtitle">{row.rowSubTitle}</h4>
                                                    </Typography>
                                                }
                                                <Box sx={{ flexGrow: 1, maxWidth: "1280px", margin: "auto" }}>
                                                    <Grid container spacing={1} className="bb-column-container">
                                                        {row?.columns?.map((column, columnIndex) =>
                                                            (<PageEditColumn
                                                                key={`row-${rowIndex}-column-${columnIndex}`}
                                                                column={column}
                                                                rowIndex={rowIndex}
                                                                columnIndex={columnIndex}
                                                                allButtons={AllButtonsRender(rowIndex, row.columns.length, columnIndex)}
                                                                editButtonRender={EditButtonRender}
                                                                deleteButtonRender={DeleteButtonRender}
                                                            />)
                                                        )}
                                                    </Grid>
                                                </Box>
                                                <Button
                                                    onClick={() => handleAddNewColumn(rowIndex)}
                                                    variant="contained"
                                                    size="small"
                                                    className="bb-mt-2"
                                                    disabled={submitting}
                                                    startIcon={<ViewColumnIcon/>}
                                                >
                                                    Add New Column
                                                </Button>
                                            </CardContent>
                                        </Card>
                                        <Button
                                            onClick={() => handleAddNewRow(rowIndex)}
                                            variant="contained"
                                            size="small"
                                            startIcon={<HorizontalSplitIcon />}
                                            disabled={submitting}
                                            className="bb-mt-1 bb-mb-1"
                                        >
                                            Add New Row
                                        </Button>
                                    </Grid>)
                                )}
                            </Grid>
                        </Box>
                    </Form>
                    {pageName && (pageSchema.bbRow?.length ?? 0) === 0 &&
                        <Button onClick={() => handleAddNewRow()}
                            variant="contained"
                            size="small"
                            startIcon={<HorizontalSplitIcon />}
                            disabled={submitting}
                            className="bb-mt-3 bb-mb-1"
                        >
                            Add New Row
                        </Button>
                    }
                    {!pageName &&
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<HorizontalSplitIcon />}
                            className="bb-mt-3 bb-mb-1"
                            type="submit"
                            disabled={submitting}
                            form={`${pageName}_pagesubmit`}
                        >
                            Create New Page
                        </Button>
                    }
                </CreateTabPanel>
                <CreateTabPanel value={currentTabValue} index={1}>
                    <CodeEditor
                        schema={JSON.stringify(pageSchema, undefined, 4)}
                        onUpdate={onUpdate}
                    />
                </CreateTabPanel>
            </Box>
        </Fragment>
    );
};
