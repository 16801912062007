import { useParams, Link } from "react-router-dom";

import { Analytics } from "../analytics/Analytics";

export const AppAnalytics = () => {
    const { appId } = useParams();

    return (
        <div>
            <Link to={`/applications/${appId}`}>
            </Link>
            <Analytics appId={appId ?? ""}></Analytics>
        </div>
    );
};
