import { Grid, Container, Typography, Box } from "@mui/material";

import { FolderCard } from ".";

export const Assets = () => {
    return (<>
        <Container>
            <Box sx={{ display: "flex", margin: "auto", marginBottom: "2rem", justifyContent: "center" }}>
                <Typography variant="h2" component="h2">
                    Media Library
                </Typography>
            </Box>
            <Grid
                container
                justifyContent="center"
                spacing={6}>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <FolderCard name="Public" parentPath="root" isPublic={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <FolderCard name="Private" parentPath="root" isPublic={false} />
                </Grid>
            </Grid>
        </Container>
    </>);
};
