import { Alert } from "@mui/material";

const DescriptionWithLink = (props: any) => {
    const { description, linkText, linkUrl } = props.schema;
    return (
        <Alert className="bb-title-info bb-mb-2" severity="info">{description} <a target="blank" href={`https://${linkUrl}`}>{linkText}</a></Alert>
    );
};

export { DescriptionWithLink };
