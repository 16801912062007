import { Box, ListItemButton, ListItemText } from "@mui/material";
import { ReactElement, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { MemberAppContext } from "../MemberAppContext";

import { theme } from "../theme";

interface SidebarItemProps {
    customOnClickHandler?: () => void;
    primaryText: string;
    isEnabled: boolean;
    linkTo?: string;
    icon?: ReactElement;
}

export const SidebarItem = (props: SidebarItemProps) => {
    const appContext = useContext(MemberAppContext);
    const { isEnabled, primaryText, linkTo, customOnClickHandler } = props;
    const navigate = useNavigate();

    const navigateTo = useCallback(() => {
        const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
        if (isMobile) {
            appContext.setSidebarOpen(false);
        }
        navigate(linkTo ?? "/");
    }, [linkTo, navigate, appContext]);

    const onSidebarItemClick = customOnClickHandler ?? navigateTo;

    return isEnabled ? (
        <ListItemButton sx={[
            { "&:hover": {
                background: theme.palette.text.primary,
                color: theme.palette.secondary.contrastText
            },
            "&:active": {
                backgroundColor: "aliceblue"
            }
            }

        ]} className="sidebar-item" onClick={onSidebarItemClick}>

            <Box className="sidebar-item-icon">{props.icon}</Box>
            <ListItemText primary={primaryText} className="sidebar-item-text bb-mt-0" sx={{ padding: theme.spacing(1) }} />
        </ListItemButton>
    ) : (
        <></>
    );
};
