import {
    Button,
    CircularProgress,
    Grid,
    Typography,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";

import React, { useState, useEffect, useCallback } from "react";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { JSONSchema7 } from "json-schema";

import { v7 as uuidv7 } from "uuid";

import useDataClient from "../../../axios/dataClient";

import { Schema, DbData } from "../../../schemas/pages/schema";

import { deletePage, getPageContent, upsertPageContent, getPageNames } from "../../../services/api/PagesService";

import { getAppConfigContent } from "../../../services/api/FrontendAppConfigService";

import { Schema as NavigationSchema } from "../../../schemas/navigation/schema";

import { SchemaType } from "../../../schemas/schemaTypes";

import { getTableStructures } from "../../../services/api/DataManagementService";

import { AppPreview } from "../preview/AppPreview";

import { PageEditForm } from "./PageEditForm";
import { PageContext } from "./pagesContext";
import { WarningModal } from "../../shared/WarningModal";
import { UndoButton } from "../UndoButton";
import { PageSearch } from "./PageSearch";
import { newPage } from "../../newModuleNames";

export interface PageEditBaseFormProps {
    appId: string | undefined;
    pageName: string | undefined;
    isDelete: boolean | undefined;
}

export const PageEditBaseForm: React.FC<PageEditBaseFormProps> = ({ appId, pageName, isDelete }) => {
    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const [schema, updateSchema] = useState<Schema | undefined>(undefined);
    const [currentPageName, setCurrentPageName] = useState<string | undefined>(pageName);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [currentFormData, setCurrentFormData] = React.useState<any>();
    const [currentSchema, setCurrentSchema] = React.useState<JSONSchema7 | undefined>();
    const [dbData, setDbData] = React.useState<DbData | undefined>();
    const [selectedTableId, setSelectedTableId] = React.useState<string>();
    const [showPageReloadModal, setShowPageReloadModal] = useState(false);
    const [pageNamesList, setPageNamesList] = useState<string[]>([]);

    const navigate = useNavigate();
    const { get, post, deleteRequest } = useDataClient();

    const navigateToDelete = () => {
        navigate(`/applications/${appId}/pages/${currentPageName}/delete`);
    };

    const handleOpenDelete = () => {
        setOpenDeleteModal(true);
    };

    const handleCloseDelete = () => {
        setOpenDeleteModal(false);
        navigate(`/applications/${appId}/pages/${currentPageName}`);
    };

    useEffect(() => {
        if (isDelete) {
            handleOpenDelete();
        }
    }, [isDelete]);

    const fetchDbData = React.useCallback(async () => {
        const data = await getTableStructures(get);

        data.splice(0, 0, { id: "none", displayName: "None", description: "", isReadOnly: false, columns: [] });

        setDbData({
            tables: data.map((table) => {
                table.columns.splice(0, 0, { field: "none", headerName: "None" });
                return table;
            })
        });
    }, [get]);

    const fetchData = React.useCallback(async () => {
        if (appId) {
            const pageNames = await getPageNames<string[]>(get)(appId);

            const formatedPages = pageNames.map((name) => {
                return name.replace(".json", "").toUpperCase();
            });

            setPageNamesList(formatedPages);

            if (currentPageName) {

                const pageData = await getPageContent<Schema>(get)(appId, currentPageName);

                updateSchema({
                    ...pageData,
                    bbRow: pageData.bbRow.map((row, rowIndex) => {
                        row.columns.map((column, columnIndex) => {
                            column.bbPanelTypes = column.bbPanelTypes.map((panel, panelIndex) => {
                                panel.order = panelIndex;
                                return panel;
                            });

                            column.bbPanelTypesList = column.bbPanelTypes.map((panel, panelTypeIndex) => {
                                return {
                                    order: panelTypeIndex,
                                    panelType: panel.type
                                };
                            });

                            column.order = columnIndex;
                            return column;
                        });

                        row.order = rowIndex;
                        return row;
                    }),
                    pageName: currentPageName
                });
            } else {
                const newSchema: Schema = { bbRow: [] };
                updateSchema(newSchema);
            }
        }
    }, [appId, currentPageName, get]);

    useEffect(() => {
        if (pageName && !currentPageName) {
            setCurrentPageName(pageName);
        }
    }, [pageName, currentPageName]);

    useEffect(() => {
        (async () => {
            await fetchData();
            await fetchDbData();
        })();
    }, [appId, get, pageName, fetchData, fetchDbData]);

    const handleSubmit = useCallback(async ({ formData }: any) => {
        if (appId && schema) {
            setSubmitting(true);

            if (formData.pageName?.toUpperCase() === newPage.toUpperCase()) {
                toast.error(`Path name cannot be ${newPage}`);
                setSubmitting(false);
                return;
            }

            const isNewPage = !pageName;
            const now = new Date();

            let latestPageHash: string | undefined;

            if (!isNewPage) {
                const latestPageData = await getPageContent<Schema>(get)(appId, pageName);
                latestPageHash = latestPageData.savedHash;

                if (latestPageHash !== schema.savedHash) {
                    setShowPageReloadModal(true);
                    setSubmitting(false);
                    return;
                }
            }
            else {
                if (pageNamesList && pageNamesList.some((name) => name === formData.pageName?.toUpperCase())) {
                    toast.error("Page with the same name already exists");
                    setSubmitting(false);
                    return;
                }
            }

            const schemaData: Schema = !schema ? { bbRow: [] } : { ...schema };
            const newSchema: Schema = {
                ...schemaData,
                pageName: formData.pageName,
                title: formData.title,
                clientIntro: formData.clientIntro,
                lastUpdatedDate: now,
                savedHash: uuidv7()
            };

            if (isNewPage) {
                newSchema.createdDate = now;
            }

            updateSchema(newSchema);
            upsertPageContent<Schema>(post)(appId, `${newSchema.pageName}.json`, newSchema).then(() => {
                if (isNewPage) {
                    toast.success("New page created");
                    navigate(`/applications/${appId}/pages/${newSchema.pageName}`);
                }
                else {
                    toast.success("Page data saved");
                }
                return;
            }).finally(() => {
                setSubmitting(false);
            });
        }
    }, [post, appId, schema, pageName, navigate, get, pageNamesList]);

    const handleError = useCallback((e: any): void => {
        console.log("Data changed: ", e.formData);
    }, []);

    const handleUndo = useCallback(async (): Promise<void> => {
        setSubmitting(true);
        fetchData().then(() => {
            toast.warning("Page data has been reset");
            return;
        }).finally(() => {
            setSubmitting(false);
        });
    }, [fetchData]);

    const handleDelete = useCallback(async (): Promise<boolean> => {
        if (schema?.preventDeletion) {
            // preventDeletion field is not available in the UI, so schema.canBeDeleted can be used
            toast.error("This page cannot be deleted");
            return false;
        }

        if (appId && currentPageName) {
            setSubmitting(true);
            return getAppConfigContent<NavigationSchema>(get)(appId, SchemaType.Nav)
                .then((navData: NavigationSchema) => {
                    const hasLinkedNavigation = navData.nav.some((navItem) => {
                        return navItem.link?.endsWith(`pages/${currentPageName}.json`);
                    });

                    if (hasLinkedNavigation) {
                        toast.error("This page is linked to a navigation, please delete the navigation before deleting the page");
                        setSubmitting(false);
                        return false;
                    }

                    // This is intended nested promise as first result needs to be processed before 2nd promise call
                    deletePage(deleteRequest)(appId, currentPageName)
                        .then(() => { // eslint-disable-line promise/no-nesting
                            toast.success("Page has been deleted");
                            navigate(`/applications/${appId}/pages`);
                            return;
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });

                    return true;
                });
        }

        return false;
    }, [appId, currentPageName, deleteRequest, navigate, get, schema?.preventDeletion]);

    const handleConfirmDelete = useCallback(async (): Promise<void> => {
        const isDeleted = await handleDelete();
        setOpenDeleteModal(false);
        if (!isDeleted) {
            navigate(`/applications/${appId}/pages/${currentPageName}`);
        }
    }, [navigate, handleDelete, appId, currentPageName]);

    const handleUpdate = useCallback((newSchema: Schema): void => {
        if (newSchema) {
            updateSchema({ ...newSchema });
        }
    }, []);

    const handleShowPageReloadModalClose = () => {
        setShowPageReloadModal(false);
    };

    const handleShowPageReloadModalProceed = () => {
        setShowPageReloadModal(false);
        handleUndo();
    };

    if (!schema || !dbData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <PageContext.Provider
            value={{
                schema,
                updateSchema,
                handleSubmit,
                handleUpdate,
                currentFormData,
                setCurrentFormData,
                currentSchema,
                setCurrentSchema,
                dbData,
                selectedTableId,
                setSelectedTableId
            }}>
            <Dialog
                open={showPageReloadModal}
            >
                <DialogTitle id="alert-dialog-title" className="bb-error-dialog-header bb-flex bb-align-items-center">
                    <WarningAmberIcon className="bb-mr-1"></WarningAmberIcon> <h2 className="bb-m0 bb-p0">{"WARNING!"}</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="bb-tac">
                        <h3>
                            Another user has recently updated this page. Please reload the page to get the most recent page data and try again.
                        </h3>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleShowPageReloadModalClose}>
                        Close
                    </Button>
                    <Button onClick={handleShowPageReloadModalProceed} className="bb-ml-auto">
                        Reload Page Data
                    </Button>
                </DialogActions>
            </Dialog>
            <Box className="bb-page-edit-cont">
                <Box className="bb-pe-fab">
                    {currentPageName && schema &&
                        <AppPreview page={schema} pageName={currentPageName} />
                    }
                </Box>
                {submitting && <Box className="bb-tac bb-page-save-loader"><CircularProgress/></Box>}
                <Box className="bb-flex bb-flex-column bb-tac" sx={{ minHeight: "60vh" }}>
                    <Box className="bb-flex page-edit-buttons">
                        <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom
                            alignContent="left">
                            Page Editor
                        </Typography>
                        <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon"
                            to={`/applications/${appId}/pages`}>
                            <Button variant="outlined" startIcon={<KeyboardArrowLeftIcon/>} disabled={submitting}>
                                Pages List
                            </Button>
                        </Link>
                        {currentPageName &&
                            <>
                                <Grid item xs={1}>
                                    <Button onClick={navigateToDelete} variant="outlined" startIcon={<DeleteOutlineIcon/>}
                                        color="error" disabled={submitting}>
                                        Delete
                                    </Button>
                                </Grid>
                                <Grid item xs={1}>
                                    <UndoButton onClick={handleUndo} disable={submitting}></UndoButton>
                                </Grid>
                            </>
                        }
                        <Button variant="contained" startIcon={<SaveOutlinedIcon/>} disabled={submitting} type="submit"
                            form={`${currentPageName}_pagesubmit`}>
                        Save
                        </Button>
                    </Box>
                    { schema?.bbRow &&
                        <PageSearch bbRows={schema.bbRow}></PageSearch>
                    }
                    <PageEditForm
                        pageName={currentPageName}
                        pageSchema={{ ...schema }}
                        onUpdate={handleUpdate}
                        onError={handleError}
                        onSubmit={handleSubmit}
                        submitting={submitting}
                    ></PageEditForm>
                </Box>
            </Box>
            <WarningModal
                open={openDeleteModal}
                description={"Are you sure you want to perform this action?"}
                onConfirm={handleConfirmDelete}
                onCancel={handleCloseDelete}
            />
        </PageContext.Provider>);
};
