import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Typography,
    Backdrop
} from "@mui/material";

import { useCallback, useState, useEffect } from "react";

import { toast } from "react-toastify";

import useDataClient from "../../../../axios/dataClient";

import { useAsync } from "../../../../useAsync";

import { error } from "../../../ErrorDisplay";

import { FolderCard } from "./FolderCard";

import { FileCard, FileCardProps } from "./FileCard";

export interface FolderInfo {
    path: string;
    name: string;
    isPublic: boolean;
    subfolders: string[];
    files: FileCardProps[];
}

export interface BlobFile {
    name: string;
    path: string;
    url: string;
    lastUpdated?: Date | string;
}

export interface FileFolderInfo {
    fullPath?: string;
    currentPath: string;
    name: string;
    parentPath: string;
    isPublic: boolean;
}

export interface FileFolderViewProps {
    fileFolderInfo: FileFolderInfo;
    mediaSearchString?: string;
    handleFolderSelection: (fullPath?: string) => void;
    handleModalCancel: () => void;
    handleFileSelect: (fullUrl: string) => void;
    requiredHeight?: number;
    requiredWidth?: number;
}

export const FileFolderView = ({
    fileFolderInfo,
    mediaSearchString,
    handleFolderSelection,
    handleModalCancel,
    handleFileSelect,
    requiredHeight,
    requiredWidth }: FileFolderViewProps) => {

    const [contents, setContents] = useState<{ subfolders: string[]; files: BlobFile[] }>({ subfolders: [], files: [] });
    const { post } = useDataClient();
    const [isLoading, setIsLoading] = useState(false);
    const [currentlyDisplayedFileCount, setCurrentlyDisplayedFileCount] = useState(24);
    const [selectedFileUrl, setSelectedFileUrl] = useState<string>();
    const [selectedFilePath, setSelectedFilePath] = useState<string>();
    const [searchResult, setSearchResults] = useState<BlobFile[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isImageSizeMismatch, setIsImageSizeMismatch] = useState<boolean>();
    const [imageObject, setImageObject] = useState<HTMLImageElement>();
    const [isProcessingResize, setIsProcessingResize] = useState(false);

    const fetchFolders = useCallback(async () => {
        if (mediaSearchString) {
            return {
                subfolders: [],
                files: []
            };
        }

        setIsLoading(true);
        setContents({ subfolders: [], files: [] });
        const formData = new FormData();
        formData.append("folderPath", fileFolderInfo.currentPath);
        formData.append("isPublic", fileFolderInfo.isPublic.toString());
        formData.append("showOnlyImages", "true");
        const value = await post<{ subfolders: any[]; files: any[] }>("api/asset", formData);
        setIsLoading(false);
        return value;
    }, [post, fileFolderInfo.currentPath, fileFolderInfo.isPublic, mediaSearchString]);

    useAsync(fetchFolders, setContents, [post, fileFolderInfo.currentPath, fileFolderInfo.isPublic, mediaSearchString]);

    const getFileFolderInfo = (
        currentPath: string,
        name: string,
        parentPath: string,
        isPublic: boolean,
        fullPath?: string): FileFolderInfo => {

        return {
            fullPath,
            currentPath,
            name,
            parentPath,
            isPublic
        };
    };

    const loadMore = useCallback(() => {
        setCurrentlyDisplayedFileCount(current => current + 24);
    }, []);

    useEffect(() => {
        if (selectedFileUrl &&
            requiredHeight &&
            requiredWidth) {

            const image: HTMLImageElement = new Image();
            image.src = selectedFileUrl;

            setTimeout(() => {
                if (image?.height > 0 && image?.width > 0) {
                    setImageObject(image);

                    const isSizeMismatch = image?.height !== requiredHeight || image?.width !== requiredWidth;
                    setIsImageSizeMismatch(isSizeMismatch);

                    return;
                }
            }, 100);
        }

        setImageObject(undefined);
        setIsImageSizeMismatch(false);
    }, [selectedFileUrl, requiredHeight, requiredWidth]);

    const handleFileSelection = useCallback((file: BlobFile) => {
        if (selectedFileUrl === decodeURI(file.url)) {
            setSelectedFileUrl("");
            setSelectedFilePath("");
            setImageObject(undefined);
            setIsImageSizeMismatch(false);
        }
        else {
            setSelectedFileUrl(decodeURI(file.url));
            setSelectedFilePath(decodeURI(file.path + (file.path ? "\\" : "") + file.name));
        }
    }, [selectedFileUrl]);

    const handleFileSelectFromFileInfo = useCallback((file: BlobFile) => {
        const fileUrl = decodeURI(file.url);
        setSelectedFileUrl(fileUrl);
        handleFileSelect(fileUrl);
    }, [handleFileSelect]);

    const handleModalFileSelect = useCallback((): void => {
        if (selectedFileUrl) {
            handleFileSelect(selectedFileUrl);
        }
    }, [selectedFileUrl, handleFileSelect]);

    const handleFileResize = useCallback((): void => {
        if (selectedFilePath) {
            setIsProcessingResize(true);

            const object = {
                isPublic: fileFolderInfo.isPublic,
                sourcePath: selectedFilePath,
                newHeight: requiredHeight,
                newWidth: requiredWidth
            };

            post<BlobFile>("api/asset/resize", object).then((newFile: BlobFile) => {
                if (!newFile) {
                    toast.error("Something went wrong when resizing the image, please try again or use a correct sized image");
                    return;
                }

                if (mediaSearchString) {
                    const newSearchResults = [newFile, ...searchResult];
                    setSearchResults(newSearchResults);
                }
                else {
                    const newFiles = [newFile, ...contents.files];
                    setContents({ subfolders: contents.subfolders, files: newFiles });
                }

                setTimeout(() => {
                    setSelectedFileUrl(decodeURI(newFile.url));
                    setSelectedFilePath(decodeURI(newFile.path + (newFile.path ? "\\" : "") + "\\" + newFile.name));
                    toast.success("New image with correct size created: " + newFile.name);
                }, 500);

                return;
            })
                .catch(() => {
                    toast.error("Something went wrong when resizing the image, please try again or use a correct sized image");
                })
                .finally(() => {
                    setIsProcessingResize(false);
                });
        }
    }, [selectedFilePath, contents, searchResult, mediaSearchString, fileFolderInfo.isPublic, post, requiredHeight, requiredWidth]);

    const runSearch = useCallback(async () => {
        if (!mediaSearchString) {
            return [];
        }

        setIsSearching(true);

        try {
            const object = {
                searchFilter: mediaSearchString,
                isPublic: fileFolderInfo.isPublic,
                relativePath: fileFolderInfo.currentPath,
                showOnlyImages: true
            };
            const results = await post<BlobFile[]>("api/asset/search", object);
            setIsSearching(false);
            return results;
        }
        catch {
            setIsSearching(false);
            error("something went wrong");
            return [];
        }
    }, [mediaSearchString, fileFolderInfo.isPublic, fileFolderInfo.currentPath, post]);

    useAsync(runSearch, setSearchResults, [mediaSearchString, post, fileFolderInfo.isPublic, fileFolderInfo.currentPath]);

    if (isLoading || isSearching) {
        return <div className="bb-tac"><CircularProgress /></div>;
    }

    return (
        <Box>
            {isProcessingResize &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isProcessingResize}
                >
                    <div className="bb-tac"><CircularProgress /></div>
                </Backdrop>
            }
            {mediaSearchString ?
                (<Box id="search-results">
                    <Box id="list-results" sx={{ margin: "1.5em", width: "100%", boxSizing: "border-box" }}>
                        <Grid container spacing={1}>
                            {searchResult.slice(0, currentlyDisplayedFileCount).map(file =>
                                (<FileCard
                                    key={file.path + file.name}
                                    isSelected={selectedFileUrl === decodeURI(file.url)}
                                    handleFileSelection={handleFileSelection}
                                    handleFileSelectFromFileInfo={handleFileSelectFromFileInfo}
                                    file={file}
                                    isPublic={fileFolderInfo.isPublic}
                                    isImageSizeMismatch={isImageSizeMismatch}
                                />)
                            )}
                        </Grid>
                    </Box>
                </Box>)
                : (<>
                    <Box id="list-results" sx={{ margin: "1.5em" }}>
                        <Grid
                            container
                            spacing={1}
                            className="bb-folder-list bb-align-items-center bb-mb-2"
                        >
                            {fileFolderInfo.currentPath &&
                                <Box className="bb-folder-up">
                                    <FolderCard
                                        fileFolderInfo={getFileFolderInfo(
                                            fileFolderInfo.parentPath,
                                            "..",
                                            "",
                                            fileFolderInfo.isPublic,
                                            fileFolderInfo.parentPath
                                        )}
                                        handleFolderSelection={handleFolderSelection}
                                    />
                                </Box>
                            }
                            {contents.subfolders.map(subFolder =>
                                (<Grid key={subFolder} item xs={12} sm={12} md={6} lg={2} xl={2}>
                                    <FolderCard
                                        fileFolderInfo={getFileFolderInfo(
                                            [fileFolderInfo.currentPath, subFolder].join("/"),
                                            subFolder,
                                            fileFolderInfo.currentPath,
                                            fileFolderInfo.isPublic,
                                            [fileFolderInfo.currentPath, subFolder].join("/")
                                        )}
                                        handleFolderSelection={handleFolderSelection}
                                    />
                                </Grid>)
                            )}
                        </Grid>
                        <Grid container spacing={2} sx={{ width: "97%", boxSizing: "border-box" }}>
                            {contents.files.slice(0, currentlyDisplayedFileCount).map(file =>
                                (<FileCard
                                    key={file.name}
                                    isSelected={selectedFileUrl === decodeURI(file.url)}
                                    handleFileSelection={handleFileSelection}
                                    handleFileSelectFromFileInfo={handleFileSelectFromFileInfo}
                                    file={file}
                                    isPublic={fileFolderInfo.isPublic}
                                    isImageSizeMismatch={isImageSizeMismatch}
                                />)
                            )}
                        </Grid>
                    </Box>
                    <Box sx={{ width: "100%", margin: "2rem", textAlign: "center" }}>
                        <Button className="bb-primary-button" onClick={loadMore}>Load More</Button>
                    </Box>
                </>)
            }
            <Box className="bb-pe-modal-footer">
                <Grid container spacing={1} className="bb-flex bb-fixed">
                    <Grid item>
                        <Button onClick={handleModalCancel} variant="text">
                            Cancel
                        </Button>
                    </Grid>
                    {imageObject?.height && imageObject?.width &&
                        <Grid item className="bb-flex bb-align-items-center">
                            <Typography variant='h5' className="bb-mb-0" sx={{ marginLeft: "1rem", marginRight: "1rem" }}>
                                [Selected Image Size: -{'>'} Height: {imageObject?.height} px Width: {imageObject?.width} px]
                            </Typography>
                        </Grid>
                    }
                    {isImageSizeMismatch &&
                        <Grid item>
                            <Button
                                onClick={handleFileResize}
                                variant="contained"
                                title="Create a copy of the selected image with required size"
                            >
                                Resize Image
                            </Button>
                        </Grid>
                    }
                    <Grid item className="bb-ml-auto">
                        <Button onClick={handleModalFileSelect} variant="contained" disabled={!selectedFileUrl || isImageSizeMismatch}>
                            Select
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};
