import axios from "axios";

import { customHistory } from "../components/CustomHistory";

import { error, errors } from "../components/ErrorDisplay";

const axiosApi = axios.create({
    baseURL: window.location.origin.toString(),
    headers: { "Content-Type": "application/json" },
});

axiosApi.interceptors.response.use(
    (response) => {
        return response;
    },
    (errorMessage) => {
        if (errorMessage?.response !== null) {
            if (errorMessage.response.status === 500) {
                error(errorMessage.response.data.message);
            } else if (errorMessage.response.status === 400) {
                errors(errorMessage.response.data.errors[""]);
            } else if (errorMessage.response.status === 403) {
                error(errorMessage.response.data);
                customHistory.replace("/");
            }
        } else {
            // eslint-disable-next-line no-console
            console.warn(
                "Intercepted error message without response body",
                errorMessage
            );
        }
        Promise.reject(errorMessage);
    }
);
export const AxiosApi = axiosApi;
