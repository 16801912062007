import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useField } from "formik";
import { SxProps, Theme } from "@mui/material";

interface SwitchFieldProps {
    name: string;
    label: string;
    disabled?: boolean;
    sxProps?: SxProps<Theme>;
}

export const SwitchField = (props: SwitchFieldProps) => {
    const { name, label, sxProps, disabled } = props;

    const [field] = useField(name);
    const isDisabled = Boolean(disabled);

    const switchComponent = (
        <Switch
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            checked={field.value}
            disabled={isDisabled}
        />
    );

    return label ? (
        <FormGroup sx={sxProps}>
            <FormControlLabel control={switchComponent} label={label} />
        </FormGroup>
    ) : (
        switchComponent
    );
};
