import { Card, CardHeader, CardContent, Button } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { useCallback } from "react";

import { LockedChatData } from "../../../services/model/livechat/dashboard/lockedChatData";
import useDataClient from "../../../axios/dataClient";
import { LoadingCard } from "../../shared/LoadingCard";

interface LockedChatsProps {
    isLoading: boolean;
    lockedChatsData: LockedChatData[];
    removeLockedChat: (chatId: string) => void;
}

export const LockedChats = ({ isLoading, lockedChatsData, removeLockedChat }: LockedChatsProps) => {

    const { get } = useDataClient();

    const createUnlockChat = useCallback((chatId: string, agentId: string) => {
        return (async () => {
            await get("api/queue/unlockChat/" + chatId + "/" + agentId);
            removeLockedChat(chatId);
        });
    }, [get, removeLockedChat]);

    const renderCell = useCallback((params: GridRenderCellParams<any, any>) => {
        return (<Button onClick={createUnlockChat(params.row.chatId, params.row.agentId)}>Unlock</Button>);
    }, [createUnlockChat]);

    const columns: GridColDef[] = [
        { field: "agentName", headerName: "Agent Name", flex: 1 },
        { field: "userName", headerName: "User Name", flex: 1 },
        { field: "chatId", headerName: "Chat Id", flex: 1 },
        { field: "unlock", headerName: "Unlock", renderCell }
    ];

    const getRowId = useCallback((test: LockedChatData) => {
        return test.chatId;
    }, []);

    if (isLoading) {
        return (<LoadingCard title="Locked Chats" />);
    }

    return (<Card>
        <CardHeader title="Locked Chats" />
        <CardContent>
            <DataGrid
                autoHeight
                columns={columns}
                rows={lockedChatsData}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "conversations", sort: "desc" }]
                    },
                    pagination: {
                        paginationModel: {
                            pageSize: 5
                        }
                    }
                }}
                disableRowSelectionOnClick
                getRowId={getRowId}
                pageSizeOptions={[5, 10, 25, 50]} />
        </CardContent>
    </Card>);
};
