import { ISubscriptionsSummary } from "../model/notifications/ISubscriptionsSummary";
import { ISendNotificationRequest } from "../model/notifications/ISendNotificationRequest";
import { IFilter } from "../model/notifications/IFilter";
import { INotificationsLog } from "../model/notifications/INotificationsLog";
import { IIndividualNotification } from "../model/notifications/IIndividualNotification";

const getSubscriptions = (get: <T>(url: string) => Promise<T>) => async (appId: string) => {
    const result = await get<ISubscriptionsSummary>(`/api/notifications/${appId}`);
    return result;
};

const getHistory = (get: <T>(url: string) => Promise<T>) => async (appId: string) => {
    const result = await get<IIndividualNotification[]>(`/api/notifications/${appId}/history`);
    return result;
};

const getLandingAnalytics = (get: <T>(url: string) => Promise<T>) => async (appId: string) => {
    const result = await get<any>(`/api/notifications/${appId}/analytics`);
    return result;
};

const getSubscriptionsCount = (get: <T>(url: string) => Promise<T>) => async (appId: string, filters: IFilter[] = []) => {
    const filtersJson = encodeURIComponent(JSON.stringify(filters));
    const result = await get<ISubscriptionsSummary>(`/api/notifications/${appId}/subscriptions?filtersJson=${filtersJson}`);
    return result;
};

const send = (post: <T>(url: string, body: any) => Promise<T>) => async (appId: string, request: ISendNotificationRequest): Promise<INotificationsLog> => {
    const result = await post<INotificationsLog>(`/api/notifications/${appId}/send`, request);
    return result;
};

export { getSubscriptions, getSubscriptionsCount, send, getHistory, getLandingAnalytics };
