import { JSONSchema7 } from "json-schema";

import { PanelType } from "../panelTypes";

import { resolveCommonSchemaDefinitions } from "../common/resolveDefinitions";

import panelAccordionSchema from "./panels/panelAccordion.json";
import panelBotLearnSchema from "./panels/panelBotLearn.json";
import panelButtonStackSchema from "./panels/panelButtonStack.json";
import panelCardGridSchema from "./panels/panelCardGrid.json";
import panelEventsSchema from "./panels/panelEvents.json";
import panelFadingBannerSchema from "./panels/panelFadingBanner.json";
import panelHtmlSchema from "./panels/panelHtml.json";
import panelIconBoxSchema from "./panels/panelIconBox.json";
import panelLauncherSchema from "./panels/panelLauncher.json";
import panelMediaSchema from "./panels/panelMedia.json";
import panelSliderSchema from "./panels/panelSlider.json";
import panelStartChatSchema from "./panels/panelStartChat.json";
import panelVideoSchema from "./panels/panelVideo.json";
import panelMapSchema from "./panels/panelMap.json";
import panelSocialSchema from "./panels/panelSocial.json";
import panelImgSchema from "./panels/panelImg.json";
import panelButtonSchema from "./panels/panelButton.json";

export const getPanelSchema = (schemaType: string): JSONSchema7 | undefined => {
    switch (schemaType) {
        case PanelType.Accordion:
            return resolveCommonSchemaDefinitions(panelAccordionSchema as JSONSchema7);
        case PanelType.BotLearn:
            return resolveCommonSchemaDefinitions(panelBotLearnSchema as JSONSchema7);
        case PanelType.ButtonStack:
            return resolveCommonSchemaDefinitions(panelButtonStackSchema as JSONSchema7);
        case PanelType.CardGrid:
            return resolveCommonSchemaDefinitions(panelCardGridSchema as JSONSchema7);
        case PanelType.Events:
            return resolveCommonSchemaDefinitions(panelEventsSchema as JSONSchema7);
        case PanelType.FadingBanner:
            return resolveCommonSchemaDefinitions(panelFadingBannerSchema as JSONSchema7);
        case PanelType.Html:
            return resolveCommonSchemaDefinitions(panelHtmlSchema as JSONSchema7);
        case PanelType.IconBox:
            return resolveCommonSchemaDefinitions(panelIconBoxSchema as JSONSchema7);
        case PanelType.Launcher:
            return resolveCommonSchemaDefinitions(panelLauncherSchema as JSONSchema7);
        case PanelType.MediaPanel:
            return resolveCommonSchemaDefinitions(panelMediaSchema as JSONSchema7);
        case PanelType.Slider:
            return resolveCommonSchemaDefinitions(panelSliderSchema as JSONSchema7);
        case PanelType.StartChat:
            return resolveCommonSchemaDefinitions(panelStartChatSchema as JSONSchema7);
        case PanelType.Video:
            return resolveCommonSchemaDefinitions(panelVideoSchema as JSONSchema7);
        case PanelType.Map:
            return resolveCommonSchemaDefinitions(panelMapSchema as JSONSchema7);
        case PanelType.Social:
            return resolveCommonSchemaDefinitions(panelSocialSchema as JSONSchema7);
        case PanelType.Img:
            return resolveCommonSchemaDefinitions(panelImgSchema as JSONSchema7);
        case PanelType.Button:
            return resolveCommonSchemaDefinitions(panelButtonSchema as JSONSchema7);
        default:
            return undefined;
    }
};
