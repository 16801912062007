import { useFormikContext } from "formik";

import { useCallback } from "react";

import { Box, Button, IconButton } from "@mui/material";

import { Remove } from "@mui/icons-material";

import { BotSecret } from "../../../services/model/botSecret";
import { TextField } from "../../../material/TextField";

export const BotSecretsForm = () => {
    const context = useFormikContext<{ secrets: BotSecret[] }>();

    const addSecret = useCallback(() => {
        context.setFieldValue("secrets", [...context.values.secrets, {
            id: Math.random().toString(10),
            key: "",
            value: ""
        }], true);
    }, [context]);

    const removeSecret = useCallback((secretToRemove: BotSecret) => {
        return () => {
            const newSecrets = context.values.secrets.filter(secret => secret.id !== secretToRemove.id);
            context.setValues({ secrets: newSecrets }, true);
            context.setFieldValue("secrets", newSecrets, true);
        };
    }, [context]);

    const secretInput = useCallback((secret: BotSecret, index: number) => {
        return (<Box key={secret.id} sx={{ display: "flex" }}>
            <TextField sx={{ margin: "1em" }} name={`secrets[${index}].key`} label="Key" />
            <TextField sx={{ margin: "1em" }} name={`secrets[${index}].value`} label="Value" />
            <Box sx={{ display: "flex" }}>
                <IconButton sx={{ margin: "auto" }} onClick={removeSecret(secret)}><Remove /></IconButton>
            </Box>
        </Box>);
    }, [removeSecret]);
    return (
        <>
            {context.values.secrets.map(secretInput)}
            <Button sx={{ display: "flex" }} onClick={addSecret}>Add</Button>
        </>);
};
