import { useState, useEffect, useCallback, useSyncExternalStore } from 'react';
import { WidgetProps } from '@rjsf/utils';
import { MenuItem, Select, InputLabel } from '@mui/material';

import { Option } from "../../../../schemas/profile/schema";

import { ProfileOption } from "../../../../schemas/smartPages/schema";

import { stringSort } from "../../../../services/helper/sort";

import { useSmartPageContext } from "../../smartPages/smartPagesContext";

const OptionWidget = ({ onChange, id, value, label, formContext }: WidgetProps) => {
    const { profileData } = useSmartPageContext();
    const [options, setOptions] = useState<Option[]>();
    const [selectedProfileId, setSelectedProfileId] = useState<string>();
    const smartPageName = formContext.smartPageName;

    function subscribe(callback: () => void) {
        window.addEventListener("profileStorageChange", callback);
        return () => {
            window.removeEventListener("profileStorageChange", callback);
        };
    }

    function getSnapshot() {
        return localStorage.getItem(smartPageName);
    }

    const useLocalStorage = () => {
        const item = useSyncExternalStore(subscribe, getSnapshot, () => undefined);

        const profileValue = typeof item === "string" ? item : null;

        return [profileValue] as const;
    };

    const [profileAsString] = useLocalStorage();

    useEffect(() => {

        if (profileAsString) {
            const profiles = JSON.parse(profileAsString) as ProfileOption[];
            if (profiles && profiles.some(p => p.name)) {

                // This method of getting the relevant profile id is dependent on the field name,
                // but its the only way working in case of an array of custom widgets.
                // Ex. id - root_target_profile_1_options_2
                const selectedProfileIdEndPart = id.replace("root_target_profile_", "");
                const indexOfNextUnderscore = selectedProfileIdEndPart.indexOf("_");

                if (indexOfNextUnderscore > 0) {
                    const selectedProfileIdAsString = selectedProfileIdEndPart.substring(0, indexOfNextUnderscore);
                    const selectedProfileIdAsNumber = parseInt(selectedProfileIdAsString, 10);
                    if (!isNaN(selectedProfileIdAsNumber) && profiles.length > selectedProfileIdAsNumber) {
                        setSelectedProfileId(profiles[selectedProfileIdAsNumber].name);
                    }
                }
            }
        }
    }, [profileAsString, id]);

    useEffect(() => {
        if (!options || options.length === 0) {
            return;
        }

        if (options.length > 1 &&
            !options.some(option => option.optionName === value)) {

            // This occurs when the profile selection is changed and a selected option value is not available as the options values have changed.
            // In this case, we try to set the value to the first value from options, or "".
            onChange(options[0].optionName || "");
        }
    }, [options, value, onChange]);

    useEffect(() => {
        const selectedProfile = !profileData ?
            { options: [] } :
            profileData?.profile?.find(profile => profile.type === selectedProfileId);

        const selectedProfileOptions = selectedProfile?.options && selectedProfile.options.length > 0 ?
            selectedProfile.options :
            [{}] as Option[];

        setOptions(selectedProfileOptions);
    }, [profileData, selectedProfileId]);

    const handleChange = useCallback((event: any) => {
        const newValue = event.target.value;
        onChange(newValue);
    }, [onChange]);

    if (!options) {
        return <></>;
    }

    return (
        <>
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <Select
                id={id}
                value={value || options[0].optionName}
                labelId={`${id}-label`}
                onChange={handleChange}
                label={label}
                variant="outlined"
                defaultValue={options[0].optionName}
            >
                {options
                    ?.sort((a, b) => stringSort(a.optionName, b.optionName))
                    .map((option) =>
                        (<MenuItem
                            key={option.optionName}
                            value={option.optionName}
                        >
                            {option.optionName}
                        </MenuItem>
                        )
                    )}
            </Select>
        </>
    );
};

export { OptionWidget };
