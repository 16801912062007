import { Card, CardContent, CardHeader, Typography } from "@mui/material";

import { LoadingCard } from "../../shared/LoadingCard";

interface ActiveChatProps {
    isLoading: boolean;
    chatCount: number;
}

export const ActiveChats = ({ isLoading, chatCount }: ActiveChatProps) => {
    if (isLoading) {
        return (<LoadingCard title="Active Chats" />);
    }

    return (
        <Card>
            <CardHeader title="Active Chats" />
            <CardContent>
                <Typography variant="h1" textAlign="center">
                    {chatCount}
                </Typography>
            </CardContent>
        </Card>);
};
