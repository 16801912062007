import { useField } from "formik";
import { FormControl, InputLabel, Select as MuiSelect, SelectProps } from "@mui/material";

type MuiSelectProps = SelectProps & {
    name: string;
};

const Select = (props: MuiSelectProps) => {
    const [field, meta] = useField(props.name);
    const labelId = `${props.name}-label`;
    return (
        <FormControl sx={{ display: "flex" }}>
            <InputLabel variant="standard" id={labelId}>{props.label}</InputLabel>
            <MuiSelect
                {...props}
                labelId={labelId}
                placeholder={props.placeholder}
                error={meta.touched && !!meta.error}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                fullWidth
                label={props.label}
                variant="standard"
                margin="dense"
                type={props.type}
                required={props.required}
                disabled={props.disabled}
                multiple={props.multiple}
            >
                {props.children}
            </MuiSelect>
        </FormControl>
    );
};

export { Select };
