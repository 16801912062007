import { Box, Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import { useCallback } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import useDataClient from "../../../axios/dataClient";
import { getBot } from "../../../services/api/BotService";
import { BotDetailsModel } from "../../../services/model/bot";

import { TemplateDialogCard } from "./TemplateDialogCard";

export const BotDialogs = () => {
    const params = useParams();
    const botId = params.botId as string;

    const { get } = useDataClient();
    const { data, isLoading } = useQuery(["botDetails", botId], () =>
        getBot(get, botId)
    );

    const addDialog = useCallback(() => {
        alert("add implementation");
    }, []);

    if (isLoading) {
        return <CircularProgress />;
    }

    const botDetails = data as BotDetailsModel;
    const template = botDetails?.beeBotFile?.templateDetails;

    const header = (
        <Typography
            variant="h3"
            color="ActiveCaption"
            component={Link}
            to={`/bots/${botId}`}>
            {"< " + botDetails.displayName}
        </Typography>);

    if (!template) {
        return (<>
            {header}
            <Typography>No template details</Typography>
        </>);
    }

    return (<>
        <Box marginBottom="1rem">
            {header}
        </Box>
        <Grid
            container
            spacing={2}
            alignItems="stretch"
            alignContent="center"
        >
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <TemplateDialogCard icon={<AccountTreeIcon />} dialog={template.mainDialog} />
            </Grid>
            {template.subDialogs.map(dialog => (
                <Grid key={dialog.name} item xs={12} sm={6} md={3}>
                    <TemplateDialogCard icon={<CloudUploadIcon />}  dialog={dialog} />
                </Grid>
            ))}
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <Card sx={{ height: "100%", cursor: "pointer" }} onClick={addDialog}>
                    <CardContent sx={{ textAlign: "center", verticalAlign: "middle" }}>
                        <Typography fontSize="8rem">+</Typography>
                        <Typography fontSize="2rem">Add Dialog</Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </>);
};
