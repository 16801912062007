import * as React from 'react';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from "@mui/icons-material/Delete";
import { WarningModal } from "../../shared/WarningModal";

export interface DeleteButtonProps {
    rowIndex: number;
    columnIndex?: number;
    panelIndex?: number;
    onClick: (rowIndex: number, columnIndex?: number, panelIndex?: number) => void;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ rowIndex, columnIndex, panelIndex, onClick }) => {
    const [open, setOpen] = React.useState(false);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const [confirmAction, setConfirmAction] = React.useState<() => void>(() => () => {});

    const handleOpen = (action: () => void) => {
        setConfirmAction(() => action);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    const handleConfirm = () => {
        confirmAction();
        setOpen(false);
    };
    return (
        <>
            <IconButton aria-label="settings" title="Delete" color="error" onClick={
                React.useCallback(() => handleOpen(() => onClick(rowIndex, columnIndex, panelIndex)), [rowIndex, columnIndex,panelIndex, onClick])
            }>
                <DeleteIcon />
            </IconButton>
            <WarningModal
                open={open}
                description={"Are you sure you want to perform this action?"}
                onConfirm={handleConfirm}
                onCancel={handleClose}
            />
        </>
    );
};
