import { Outlet } from "react-router-dom";

import { Drawer, Container, Box, useTheme } from "@mui/material";

import { useContext } from "react";

import { MemberAppContext } from "../MemberAppContext";

import { FooterHeight, TopBarHeight, MainContainerTopMargin, BreadcrumbsBarHeight } from "../theme";

import { TopToolbar } from "./topToolbar/top-toolbar";
import { Sidebar } from "./sidebar";

export const Layout = () => {
    const appContext = useContext(MemberAppContext);
    const drawerWidth = appContext.sidebarOpen ? "fit-content" : 0;

    const theme = useTheme();

    return (
        <Container className="customer-portal" sx={{ paddingTop: theme.spacing(1) }}>
            <TopToolbar />

            <Box className="main-content" sx={{ display: "flex" }}>
                <Box className="sidebar-container" component="nav" sx={{ width: drawerWidth }}>
                    <Drawer
                        open={appContext.sidebarOpen}
                        variant="persistent"
                        anchor="left"
                        PaperProps={{
                            style: {
                                position: "inherit",
                                border: "none",
                                paddingRight: theme.spacing(2)
                            }
                        }}>
                        <Sidebar />
                    </Drawer>
                </Box>
                <Box component="main"
                    className="main-viewport"
                    sx={{
                        flex: "1",
                        padding: appContext.sidebarOpen ? theme.spacing(2, 0, 2, 2) : theme.spacing(2, 0),
                        minHeight: `calc(100vh - ${TopBarHeight} - ${BreadcrumbsBarHeight} - ${MainContainerTopMargin} - ${FooterHeight})`,
                        maxHeight: `calc(100vh - ${TopBarHeight})`
                    }}>
                    <Outlet />
                </Box>
            </Box>
        </Container >
    );
};
