import { JSONSchema7 } from 'json-schema';

import { Form } from '@rjsf/mui';

import validatorAjv8 from '@rjsf/validator-ajv8';

import { UiSchema }  from "@rjsf/utils";

import { AppFeatures } from "../../../schemas/settings";
import schema from "../../../schemas/settings/app-features.json";

export interface AppFeaturesFormProps {
    appFeatures: AppFeatures;
    submitting: boolean;
    onChange: (e: any) => void;
    onError: (e: any) => void;
    onSubmit: (e: any) => void;
    uiSchema: UiSchema;
}

export const AppFeaturesForm: React.FC<AppFeaturesFormProps> = ({ onChange, submitting, onError, onSubmit, appFeatures, uiSchema }) => {
    return (
        <Form
            id="settings_pagesubmit"
            schema={schema as JSONSchema7}
            formData={appFeatures}
            validator={validatorAjv8}
            onChange={onChange}
            onSubmit={onSubmit}
            onError={onError}
            uiSchema={uiSchema}
            liveValidate={true}
            showErrorList={false}
            disabled={submitting}
        />
    );
};
