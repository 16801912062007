/* eslint-disable max-len */

import { Box, Container, Typography, Card, Button, Link } from "@mui/material";
import * as React from "react";
import Grid from "@mui/material/Grid";

import BarChartIcon from "@mui/icons-material/BarChart";
import CropOriginalOutlinedIcon from "@mui/icons-material/CropOriginalOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import ColorLensIcon from "@mui/icons-material/ColorLens";

import { useContext } from "react";

import { MemberAppContext } from "../MemberAppContext";

import banner from '../img/banner.jpg';
import platformassist from '../img/platformassist.png';

export const Home = () => {
    const { user } = useContext(MemberAppContext);
    const firstName = user.displayName?.split(" ")[0] ?? "Guest";

    return (
        <Container sx={{ marginBottom: "3rem" }}>
            <Box>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12 }}>
                    <Grid item xs={12} className="animated animatedFadeInUp fadeInUp1 bb-mt-3">
                        <Card className="bb-p-2 bb-mt-3 bb-flex bb-home-banner">
                            <Box className="bb-flex bb-flex-column bb-justify-content-center bb-p-2 bb-intro-banner-text">
                                <Typography variant="h2" className="bb-intro-banner-header">Hi <span className="bb-welcome-name">
                                    {firstName}</span>,
                                </Typography>
                                <Typography variant="body1" className="bb-mb-2">Welcome to the <strong>Beebot AI Platform</strong>.</Typography>
                                <Typography variant="body1">Over the coming months <b>we will be releasing new self-service capabilities</b> across the platform. You can find details of these updates on the cards below.</Typography>
                                <Button href="/applications" title="My Apps" className="bb-flex bb-mt-2" variant="contained" sx={{ width: "fit-content" }}>My Apps</Button>
                            </Box>
                            <Box className="bb-flex bb-align-items-center bb-width-100 bb-flex-end">
                                <img src={banner} alt="Image" className="img-responsive bb-banner-img" />
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ marginTop: "3rem" }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12, lg: 12 }}>
                    <Grid item xs={12} sm={12} md={6} lg={4} className="animated animatedFadeInUp fadeInUp1">
                        <Card className="bb-tac bb-mb-2">
                            <div className="bb-card-header bb-home-icons">
                                <Link href="/applications"><BarChartIcon></BarChartIcon></Link>
                            </div>
                            <div className="bb-card-body bb-p-2">
                                <Typography variant="h3">Analytics</Typography>
                                <Typography>Provides insight into your app's performance and engagement, helping you make informed decisions based on data.</Typography>
                                <Typography>In this first release, you will be able to view the number of Users, Sessions, App Users, Page Views, Content Views, Search Queries, Feedback and more.</Typography>
                            </div>
                            <Button className="bb-mb-2" href="/applications" variant="contained" title="Analytics">View App Analytics</Button>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} className="animated animatedFadeInUp fadeInUp2">
                        <Card className="bb-tac bb-mb-2">
                            <div className="bb-card-header bb-home-icons">
                                <Link href="/assets/public"><CropOriginalOutlinedIcon></CropOriginalOutlinedIcon></Link>
                            </div>
                            <div className="bb-card-body bb-p-2">
                                <Typography variant="h3">Media Library</Typography>
                                <Typography>Easily manage assets for all your applications on the Beebot AI Platform.</Typography>
                                <Typography>In this first release, you will be able to upload, delete and organise your media assets.</Typography>
                            </div>
                            <Button href="/assets/public" className="bb-mb-2" variant="contained" title="Media Library">Open Media Library</Button>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} className="animated animatedFadeInUp fadeInUp3">
                        <Card className="bb-tac bb-mb-2" >
                            <div className="bb-card-header bb-home-icons">
                                <Link href="/data"><ListAltOutlinedIcon></ListAltOutlinedIcon></Link>
                            </div>
                            <div className="bb-card-body bb-p-2">
                                <Typography variant="h3">Data Management</Typography>
                                <Typography>Enables you to manage the data that populates content of your data-driven pages in your Beebot AI Applications.</Typography>
                                <Typography>In this first release you will be able to view your data tables, edit existing data, add new rows, and edit columns.</Typography>
                            </div>
                            <Button href="/data" className="bb-mb-2" variant="contained" title="Data Management">Access Data Manager</Button>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ marginTop: "3rem" }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12 }}>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="animated animatedFadeInUp fadeInUp4">
                        <Card className="bb-tac bb-o-v bb-mb-4">
                            <div className="bb-card-header bb-home-icons">
                                <div className="ribbon ribbon-top-left"><span>Coming Soon</span></div>
                                <PhoneAndroidOutlinedIcon></PhoneAndroidOutlinedIcon>
                            </div>
                            <div className="bb-card-body bb-p-2">
                                <Typography variant="h3">Page Editor</Typography>
                                <Typography>Enables you to manage the pages of your Beebot AI Applications.</Typography>
                                <Typography>When released, this feature will give you the ability to view your application pages and edit the content within those pages.</Typography>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="animated animatedFadeInUp fadeInUp5">
                        <Card className="bb-tac bb-o-v bb-mb-4">
                            <div className="bb-card-header bb-home-icons">
                                <div className="ribbon ribbon-top-left"><span>Coming Soon</span></div>
                                <NotificationsActiveOutlinedIcon></NotificationsActiveOutlinedIcon>
                            </div>
                            <div className="bb-card-body bb-p-2">
                                <Typography variant="h3">Notifications</Typography>
                                <Typography>Enables you to manage notifications for your Beebot AI Applications.</Typography>
                                <Typography>When released, this feature will enable you to view and send notifications for your front-end application users, with the ability to target users by locations and interests etc.</Typography>
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className="animated animatedFadeInUp fadeInUp6">
                        <Card className="bb-tac bb-o-v bb-mb-4">
                            <div className="bb-card-header bb-home-icons">
                                <div className="ribbon ribbon-top-left"><span>Coming Soon</span></div>
                                <ColorLensIcon></ColorLensIcon>
                            </div>
                            <div className="bb-card-body bb-p-2">
                                <Typography variant="h3">Theme Editor</Typography>
                                <Typography>Enables you to manage the theme of your Beebot AI Applications.</Typography>
                                <Typography>When released, this feature will give you the ability to manage the branding of your applications, add your application logos and set colour themes.</Typography>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Box style={{ marginTop: "-3rem" }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12 }}>
                    <Grid item xs={12} className="animated animatedFadeInUp fadeInUp1 bb-mt-3">
                        <Card className="bb-p-2 bb-mt-3 bb-flex bb-home-banner">
                            <Box className="bb-flex bb-flex-column bb-justify-content-center bb-p-2 bb-intro-banner-text">
                                <Typography variant="h2" className="bb-intro-banner-header">Platform <span className="bb-welcome-name">
                                    Assistant</span>
                                </Typography>
                                <Typography variant="body1">Designed to assist you in building <b>Engaging Applications</b> on the <b>Beebot AI Platform</b></Typography>
                                <Button href={`https://product.bbotapp.com/?page=home`}
                                    target="_blank" title="Learn More" className="bb-flex bb-mt-2" variant="contained" sx={{ width: "fit-content" }}>Learn More</Button>
                            </Box>
                            <Box className="bb-flex bb-align-items-center bb-width-100 bb-flex-end">
                                <img src={platformassist} alt="Image" className="img-responsive bb-banner-img" />
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};
