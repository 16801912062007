import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    InputLabel,
    Typography
} from "@mui/material";
import { useCallback } from "react";

import { BlobFile } from "./FileFolderView";

interface FileInfoProps {
    open: boolean;
    onCancel: () => void;
    onSelect: () => void;
    file: BlobFile;
    isImageSizeMismatch?: boolean;
}

export const FileInfo = ({ open, file, onCancel, onSelect, isImageSizeMismatch }: FileInfoProps) => {

    const copyUrl = useCallback(() => {
        navigator.clipboard.writeText(file.url);
    }, [file]);

    const renderLastUpdated = useCallback((lastUpdated?: Date | string) => {
        if (typeof lastUpdated === "string") {
            return new Date(lastUpdated).toLocaleString();
        }
        else {
            return lastUpdated?.toLocaleString();
        }
    }, []);

    return (
        <Dialog open={open}>
            <DialogTitle className="bbot-dialog-title">
                <Typography>{file.name}</Typography>
            </DialogTitle>
            <DialogContent className="bbot-dialog-content">
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <img className="bb-gallery-large-img" src={file.url} alt='Gallery' />
                    <FormControl variant="standard" sx={{ margin: "0.5rem 0" }}>
                        <InputLabel htmlFor="file-last-updated">Last Updated
                        </InputLabel>
                        <Input sx={{ pointerEvents: "none" }}
                            id="file-last-updated"
                            defaultValue={renderLastUpdated(file.lastUpdated)} />
                    </FormControl>
                    <FormControl variant="standard" sx={{ margin: "0.5rem 0", display: "flex", flexDirection: "column"  }}>
                        <InputLabel htmlFor="file-url">Url
                        </InputLabel>
                        <Input sx={{ pointerEvents: "none" }}
                            id="file-url"
                            defaultValue={file.url} />
                        <Button onClick={copyUrl} variant="outlined" sx={{ maxWidth: "120px", marginTop: "1rem" }}>
                            Copy URL
                        </Button>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions className="">
                <Button
                    variant="contained"
                    sx={{ marginLeft: "auto" }}
                    onClick={onCancel}
                >
                    Close
                </Button>
                <Button
                    variant="contained"
                    sx={{ marginLeft: "auto" }}
                    onClick={onSelect}
                    disabled={isImageSizeMismatch}
                >
                    Select
                </Button>
            </DialogActions>
        </Dialog>
    );
};
