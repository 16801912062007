import * as React from "react";

import { DataGrid, GridRowsProp, GridColDef, GridToolbar } from "@mui/x-data-grid";

import { Box, Typography } from "@mui/material";

import { transform_grid_data } from "./AnalyticsHelpers";

interface Props {
    data: any | undefined;
    config: any;
    title?: string;
}

export const AnalyticsConfig_sessiondetail = {
    title: "App Content Click Detail",
    initialState: {
        sorting: {
            sortModel: [{ field: "Clicks", sort: "desc" }],
        },
        pagination: { paginationModel: { pageSize: 25 } }
    },
    columnDef: [
        {
            TimeGenerated: {
                label: "Time"
            }
        },
        {
            activePage: {
                label: "Active Page"
            }
        },
        {
            et: {
                label: "Activity"
            }
        },
        {
            eventValue: {
                label: "Activity Detail"
            }
        },
        {
            timeDiffInSeconds: {
                label: "Time Spent (seconds)"
            }
        }
    ], columns: [
        { field: "TimeGenerated", headerName: "Time", flex: 3, minWidth: 150 },
        { field: "activePage", headerName: "Active Page", flex: 3, minWidth: 150 },
        { field: "et", headerName: "Activity", flex: 3, minWidth: 150 },
        { field: "eventValue", headerName: "Activity Detail", flex: 3, minWidth: 150 },
        { field: "timeDiffInSeconds", headerName: "Time Spent (seconds)", flex: 3, minWidth: 150 },
    ]
};

export const AnalyticsDataGridModal = ({ data, config, title }: Props) => {

    if (!data || data === "") {
        return null;
    }
    // loading={analyticsData.isLoading} data={analyticsData.data}

    // no need to use existing header def config!!!!!!!!!!!!!!!!!!!!!!!!!!
    const transform_data = (transform_grid_data(data.data) as GridRowsProp);
    const cols = (config.columns as GridColDef[]);

    return (
        <Box className="bb-analytics-tables" sx={{ width: "100%" }}>
            {title && (
                <Box sx={{
                    display: "flex", justifyContent: "center", background: "#fff",
                    padding: "10px", alignItems: "center", margin: "0px!important", mb: 2
                }}>
                    <Typography variant="h3">{title}</Typography>
                </Box>
            )}
            <DataGrid
                sx={{
                }}
                pageSizeOptions={[25]}
                rows={transform_data}
                columns={cols}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        printOptions: { disableToolbarButton: true }
                    },
                }}
                initialState={config.initialState}
                density="compact"
                disableColumnFilter={false}
                disableDensitySelector={true}
                disableColumnSelector={true}
            />
        </Box>
    );
};
