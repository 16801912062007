/* eslint-disable max-len */
import * as React from "react";
import { Grid, Box } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
export interface BBContactStripProps {
    panel: any;
}
export const BBContactStrip: React.FC<BBContactStripProps> = ({ panel }) => {
    return (
        <Grid container>
            {panel?.bbAddressHeader ? (
                <Grid item xs className="bb-contact-strip">
                    <Box className="bb-flex bb-flex-column bb-align-items-center bb-p-2">
                        <BusinessIcon className="bb-primary"></BusinessIcon>
                        <h3>{panel?.bbAddressHeader}</h3>
                        <p className="bb-mb-0">{panel?.bbAddressPara}</p>
                        <p className="bb-mt-0"><a href={panel?.bbAddressLink}>{panel?.bbAddressLinkName}</a></p>
                    </Box>
                </Grid>
            ) : (<span></span>)}
            {panel?.bbEmailHeader ? (
                <Grid item xs className="bb-contact-strip">
                    <Box className="bb-flex bb-flex-column bb-align-items-center bb-p-2">
                        <EmailIcon className="bb-primary"></EmailIcon>
                        <h3>{panel?.bbEmailHeader}</h3>
                        <p className="bb-mb-0">{panel?.bbEmailPara}</p>
                        <p className="bb-mt-0"><a href={panel?.bbEmailLink}>{panel?.bbEmailLink}</a></p>
                    </Box>
                </Grid>
            ) : (<span></span>)}
            {panel?.bbTelHeader ? (
                <Grid item xs className="bb-contact-strip">
                    <Box className="bb-flex bb-flex-column bb-align-items-center bb-p-2">
                        <PhoneIcon className="bb-primary"></PhoneIcon>
                        <h3>{panel?.bbTelHeader}</h3>
                        <p className="bb-mb-0">{panel?.bbTelPara}</p>
                        <p className="bb-mt-0"><a href={panel?.bbTelLink}>{panel?.bbTelLink}</a></p>
                    </Box>
                </Grid>
            ) : (<span></span>)}
        </Grid>
    );
};
