interface DateProps {
    value: Date | number;
    dateStyle?: "full" | "long" | "medium" | "short";
    timeStyle?: "full" | "long" | "medium" | "short";
}

export const DateDisplay = (props: DateProps) => {
    const dateTimeFormatter = new Intl.DateTimeFormat("en-GB", {
        dateStyle: props.dateStyle,
        timeStyle: props.timeStyle,
    });

    const date = dateTimeFormatter.format(props.value);

    return (<>{date}</>);
};
