import { Card, CardContent, CardHeader, CircularProgress } from "@mui/material";

interface LoadingCardProps {
    title: string;
}

export const LoadingCard = (props: LoadingCardProps) => {
    return (
        <Card>
            <CardHeader title={ props.title } />
            <CardContent>
                <CircularProgress />
            </CardContent>
        </Card>);
};
