import { Button, CircularProgress } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import { DateDisplay } from "../../shared/DateDisplay";

interface RefreshBarProps {
    isLoading: boolean;
    lastRefreshDate: Date | number;
    handleRefresh: () => Promise<void>;
}

export const RefreshBar = (props: RefreshBarProps) => {
    if (props.isLoading) {
        return (<CircularProgress />);
    }

    return (<>
        Last refreshed:&nbsp;
        <strong><DateDisplay value={props.lastRefreshDate} dateStyle="short" timeStyle="short" /></strong>
        <Button onClick={props.handleRefresh}>
            <RefreshIcon />
        </Button>
    </>);
};
