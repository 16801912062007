import { Button, Card, CardActions, CardContent, CardHeader, Dialog, Grid, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";

import { BeeBotFileTemplateDialogDetails } from "../../../services/model/beeBotFile/BeeBotFile";
import { Graph } from "../../dataDisplay/Graph";

import { TemplateDialogDetails } from "./TemplateDialogDetails";

interface params {
    dialog: BeeBotFileTemplateDialogDetails;
    icon?: JSX.Element;
}
const graphWidth = 7;

export const TemplateDialogCard = ({ dialog, icon }: params) => {
    const [detailsShowing, setDetailsShowing] = useState(false);
    const showDetails = useCallback(() => setDetailsShowing(true), [setDetailsShowing]);
    const hideDetails = useCallback(() => setDetailsShowing(false), [setDetailsShowing]);

    return (<>
        <Card>
            <CardHeader
                avatar={icon}
                title={dialog.name}
            />
            <CardContent sx={{ textAlign: "center", verticalAlign: "middle" }}>
                <Grid container
                    spacing={4}
                    alignItems="stretch"
                    alignContent="center">
                    <Grid item sm={graphWidth}>
                        <Graph y={[2, 3, 8, 7]} yRange={[0, 10]} />
                    </Grid>
                    <Grid item sm={12 - graphWidth} >
                        <Stack direction="column" justifyContent="center" sx={{ height: "100%" }}>
                            <Typography fontWeight="bold">
                                500
                            </Typography>
                            <Typography color="#82a652">
                                + 13%
                            </Typography>
                            <Typography>
                                vs. LWK
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions sx={{
                display: "flex",
                justifyContent: "flex-end",
            }}>
                <Button onClick={showDetails}>
                    Details
                </Button>
            </CardActions>
        </Card>
        <Dialog fullWidth open={detailsShowing} onClose={hideDetails}>
            <TemplateDialogDetails dialog={dialog} />
        </Dialog>
    </>);
};
