import * as React from "react";

import { Grid, Box, Link } from "@mui/material";

export interface BBButtonProps {
    panel: any;
}

export const BBButton: React.FC<BBButtonProps> = ({ panel }) => {

    return (
        <Box>
            <Grid item xs>
                <Link className="bb-pe-fb-btn bb-mb-1 bb-flex" sx={{
                    textDecoration: "none",
                    fontWeight: "normal",
                    padding: "1rem",
                    marginBottom: "1rem",
                    borderRadius: "5px",
                    width: "fit-content",
                    color: "#fff"
                }} href={panel.bbContentButtonLink} title={panel.bbContentButtonText}>
                    {panel.bbContentButtonText}
                </Link>
            </Grid>
        </Box>
    );
};
