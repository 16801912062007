import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

interface AddCardProps {
    headingText: string;
    onClick: () => void;
}

export const AddCard = (props: AddCardProps) => {
    const { headingText, onClick } = props;

    return (
        <Card
            onClick={onClick}
            sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
            }}
        >
            <CardContent
                sx={{
                    textAlign: "center",
                }}
            >
                <AddIcon fontSize="large" />
                <Typography data-testid="add-card">{headingText}</Typography>
            </CardContent>
        </Card>
    );
};
