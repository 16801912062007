import { ReactElement } from "react";

import { PanelType } from "../../../../schemas/panelTypes";

import { BBStartChat } from "./BBStartChat";
import { BBFadingBanner } from "./BBFadingBanner";
import { BBAccordion } from "./BBAccordion";
import { BBHtml } from "./BBHtml";
import { BBIconBox } from "./BBIconBox";
import { BBLauncher } from "./BBLauncher";
import { BBSlider } from "./BBSlider";
import { BBMediaPanel } from "./BBMediaPanel";
import { BBVideo } from "./BBVideo";
import { BBButtonStack } from "./BBButtonStack";
import { BBMap } from "./BBMap";
import { BBSocial } from "./BBSocial";
import { BBImg } from "./BBImg";
import { BBButton } from "./BBButton";
import { BBBotLearn } from "./BBBotLearn";
import { BBCardGrid } from "./BBCardGrid";
import { BBContactStrip } from "./BBContactStrip";

export const getPanelPreview = (data: any, type: string): ReactElement => {
    switch (type) {
        case PanelType.StartChat:
            return <BBStartChat panel={data} />;
        case PanelType.FadingBanner:
            return <BBFadingBanner panel={data} />;
        case PanelType.Accordion:
            return <BBAccordion panel={data} />;
        case PanelType.Html:
            return <BBHtml panel={data} />;
        case PanelType.IconBox:
            return <BBIconBox panel={data} />;
        case PanelType.Launcher:
            return <BBLauncher panel={data} />;
        case PanelType.Slider:
            return <BBSlider panel={data} />;
        case PanelType.MediaPanel:
            return <BBMediaPanel panel={data} />;
        case PanelType.ButtonStack:
            return <BBButtonStack panel={data} />;
        case PanelType.Video:
            return <BBVideo panel={data} />;
        case PanelType.Map:
            return <BBMap panel={data} />;
        case PanelType.Social:
            return <BBSocial panel={data} />;
        case PanelType.Img:
            return <BBImg panel={data} />;
        case PanelType.Button:
            return <BBButton panel={data} />;
        case PanelType.BotLearn:
            return <BBBotLearn panel={data} />;
        case PanelType.CardGrid:
            return <BBCardGrid panel={data} />;
        case PanelType.ContactStrip:
            return <BBContactStrip panel={data} />;
        default:
            return <p>No preview</p>;
    }
};
