/*eslint no-loop-func: "error"*/

import * as React from "react";

import { Box } from "@mui/material";

export interface BBHtmlProps {
    panel: any;
}
export const BBHtml: React.FC<BBHtmlProps> = ({ panel }) => {
    //const htmlContent = panel?.html;
    //const elements = document.getElementsByClassName('bbHTML');
    //for (const element of elements) {
    //element.innerHTML = htmlContent;
    //}
    return (
        <Box>
            {panel?.imgUrl ? (<img src={panel?.imgUrl} className="bb-pe-img, bb-width-100" />) : (<span></span>)}
            {panel?.panelHeading ? (<h3 className="bb-tal">{panel?.panelHeading}</h3>) : (<span></span>)}
            {panel?.panelHeading ? (<p className="bb-tal">{panel?.panelPara}</p>) : (<span></span>)}
            <div className="bbHTML">{panel?.html}</div>
        </Box>
    );
};
