export interface DeploymentModel {
    appId: string;
    rowKey: string;
    timestamp?: Date;
    eTag: string;
    deploymentState: DeploymentState;
    deploymentProgress: DeploymentProgress;
    dateCreated: Date;
    lastUpdated: Date;
    deploymentItemModels: DeploymentItemModel[];
}

export interface DeploymentItemModel {
    rowKey: string;
    update: string;
    userId: string;
    deploymentProgress: DeploymentProgress;
    dateCreated: Date;
}

export enum DeploymentState {
    Staging ,
    Live = 10
}

export enum DeploymentProgress
{
    Waiting = 0,
    InProgress = 10,
    Completed = 20,
}

export enum DeploymentType
{
    Config = 0,
    Pathways = 10,
}

export const DeploymentStateText = {
    [DeploymentState.Staging]: "Staging",
    [DeploymentState.Live]: "Live",
};

export const DeploymentProgressText = {
    [DeploymentProgress.Waiting]: "Waiting",
    [DeploymentProgress.InProgress]: "In-Progress",
    [DeploymentProgress.Completed]: "Completed",
};

