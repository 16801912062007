/* eslint-disable max-len */

import React, { useCallback } from 'react';

import { MenuItem, Select } from '@mui/material';

const PanelTypeDropDownWidget = (props: any) => {
    const handleChange = useCallback((event: any) => {
        const newValue = event.target.value;
        props.onChange(newValue);
    }, [props]);

    return (
        <Select
            className="bb-pt-item-cont"
            id={props.id}
            value={props.value}
            onChange={handleChange}
            variant="outlined"
            defaultValue={props.options.enumOptions[0]}
        >
            {props.options.enumOptions.map((option: any, i: number) => <MenuItem key={i} value={option.value} className="bb-pt-item"><img src={option.schema.thumbUrl} className="bb-pt-img"/><p>{option.label}</p></MenuItem>)
            }
        </Select>
    );
};

export { PanelTypeDropDownWidget };
