import {
    Button,
    CircularProgress,
    Grid,
    Container,
    Box,
    Alert,
    Typography,
    Modal,
    useTheme
} from "@mui/material";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import { useParams, Link, useNavigate } from "react-router-dom";

import React, { useState, useEffect, useCallback, useMemo } from "react";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import EditIcon from "@mui/icons-material/Edit";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { toast } from "react-toastify";

import { Form } from '@rjsf/mui';

import validatorAjv8 from "@rjsf/validator-ajv8";

import { JSONSchema7 } from "json-schema";

import { UiSchema } from "@rjsf/utils";

import useDataClient from "../../axios/dataClient";

import { WarningModal } from "../shared/WarningModal";

import { getAppConfigContent, postAppConfigContent  } from "../../services/api/FrontendAppConfigService";

import { SchemaType } from "../../schemas/schemaTypes";

import { Schema, Pathway } from "../../schemas/pathways/schema";

import pathwaysSchemaModel from "../../schemas/pathways/pathwaysSchemaData.json";

import { newPathway as newPathwayId } from "../newModuleNames";

import { MediaLibraryWidget } from "../applications/customWidget/MediaLibrary/MediaLibraryWidget";

import { PathwayStageForm } from "./PathwayStageForm";

import { UndoButton } from "../applications/UndoButton";

import { AccordionFieldTemplate } from "../applications/customWidget/FieldTemplate/AccordionFieldTemplate";

export const PathwayForm: React.FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    const { pathwayId } = useParams();
    const [schema, setSchema] = useState<Schema>();
    const [pathway, setPathway] = useState<Pathway>();
    const { get, post } = useDataClient();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [currentPathwayId, setCurrentPathwayId] = useState<string | undefined>(pathwayId);
    const [openEditStagesModal, setOpenEditStagesModal] = React.useState(false);
    const [warningModalOpen, setWarningModalOpen] = React.useState(false);
    const isDelete = location?.pathname?.endsWith("/delete");

    const customWidgets = useMemo(() => ({
        MediaLibraryWidget
    }), []);

    const uiSchema: UiSchema = {
        'ui:submitButtonOptions': {
            norender: true
        },
        "ui:options": {
            classNames: "custom-class-title1"
        },
        "id": {
            "ui:readonly": !!pathwayId
        },
        "items": {
            "ui:ObjectFieldTemplate": AccordionFieldTemplate,
        },
        "settings": {
            "ui:options": {
                classNames: "custom-class-title2"
            },
            "icon": {
                "ui:widget": "MediaLibraryWidget"
            },
            "image": {
                "ui:widget": "MediaLibraryWidget"
            },
            "badge": {
                "ui:widget": "MediaLibraryWidget"
            },
            "ui:ObjectFieldTemplate": AccordionFieldTemplate,
        },
        "channel": {
            "ui:options": {
                classNames: "custom-class-title2"
            }
        }
    };

    const reformatTimeValue = React.useCallback((timeValue: string | undefined) => {

        if (timeValue && timeValue.length <= 5) {
            return `${timeValue}:00`;
        }

        return timeValue;
    }, []);

    const reformatTimeValues = React.useCallback((pathwayData: Pathway) => {

        if (pathwayData.settings?.send_time) {
            pathwayData.settings.send_time = reformatTimeValue(pathwayData.settings?.send_time);
        }

        if (pathwayData.settings?.send_time_mapping?.Any) {
            pathwayData.settings.send_time_mapping.Any = reformatTimeValue(pathwayData.settings?.send_time_mapping?.Any);
        }

        if (pathwayData.settings?.send_time_mapping?.Morning) {
            pathwayData.settings.send_time_mapping.Morning = reformatTimeValue(pathwayData.settings?.send_time_mapping?.Morning);
        }

        if (pathwayData.settings?.send_time_mapping?.Afternoon) {
            pathwayData.settings.send_time_mapping.Afternoon = reformatTimeValue(pathwayData.settings?.send_time_mapping?.Afternoon);
        }

        if (pathwayData.settings?.send_time_mapping?.Evening) {
            pathwayData.settings.send_time_mapping.Evening = reformatTimeValue(pathwayData.settings?.send_time_mapping?.Evening);
        }

        return pathwayData;
    }, [reformatTimeValue]);

    const fetchData = React.useCallback(async () => {
        const schemaData = await getAppConfigContent<Schema>(get)("noApp", SchemaType.Pathways);
        setSchema(schemaData);

        if (currentPathwayId) {
            const pathwayData = schemaData?.pathways?.find(p => p.id === currentPathwayId);

            if (pathwayData) {
                reformatTimeValues(pathwayData);
            }

            setPathway(pathwayData);
        }
        else {
            const newPathway: Pathway = { stages: [] };
            setPathway(newPathway);
        }
    }, [get, currentPathwayId, reformatTimeValues]);

    useEffect(() => {
        if (pathwayId && !currentPathwayId) {
            setCurrentPathwayId(pathwayId);
        }
    }, [pathwayId, currentPathwayId]);

    useEffect(() => {
        (async () => {
            await fetchData();
        })();
    }, [get, fetchData]);

    const handleSubmit = useCallback(async ({ formData }: any) => {
        setSubmitting(true);
        const isNewPathway = !pathwayId;

        const schemaData: Schema = !schema ? { pathways: [] } : { ...schema };

        const newPathway = formData as Pathway;

        if (newPathway.id?.toUpperCase() === newPathwayId.toUpperCase()) {
            toast.error(`Pathway id cannot be ${newPathwayId}`);
            setSubmitting(false);
            return;
        }

        const pathwayDataIndex = schemaData.pathways.findIndex(p => p.id?.toUpperCase() === newPathway.id?.toUpperCase());
        if (pathwayDataIndex >= 0) {

            if (isNewPathway) {
                toast.error("Pathway with the same id already exists");
                setSubmitting(false);
                return;
            }

            schemaData.pathways.splice(pathwayDataIndex, 1, newPathway);
        }
        else {
            schemaData.pathways.push(newPathway);
        }

        postAppConfigContent<Schema>(post)("noApp", SchemaType.Pathways, {
            configDocument: schemaData,
            message: "Updated pathway"
        }).then(() => {
            toast.success("Pathway updated");
            if (isNewPathway) {
                navigate(`/pathways/${newPathway.id}`);
            }

            setSchema(schemaData);
            setPathway(newPathway);

            return;
        }).finally(() => {
            setSubmitting(false);
        });
    }, [post, navigate, pathwayId, schema]);

    const handleError = useCallback((e: any): void => {
        console.log("Data changed: ", e.formData);
    }, []);

    const handleUndo = useCallback(async (): Promise<void> => {
        setSubmitting(true);
        fetchData().then(() => {
            toast.warning("Pathway data has been reset");
            return;
        }).finally(() => {
            setSubmitting(false);
        });
    }, [fetchData]);

    const handleDelete = useCallback(async (): Promise<boolean> => {
        if (currentPathwayId) {
            setSubmitting(true);
            const schemaData: Schema = !schema ? { pathways: [] } : { ...schema };
            const pathwayDataIndex = schemaData.pathways.findIndex(p => p.id === currentPathwayId);
            if (pathwayDataIndex >= 0) {
                schemaData.pathways.splice(pathwayDataIndex, 1);
            }

            return postAppConfigContent<Schema>(post)("noApp", SchemaType.Pathways, {
                configDocument: schemaData,
                message: "Removed pathway data from pathways"
            }).then(() => {
                toast.warning("Pathway has been deleted");
                navigate(`/pathways`);

                return true;
            }).finally(() => {
                setSubmitting(false);
            });
        }

        return false;
    }, [currentPathwayId, post, navigate, schema]);

    const navigateToDelete = () => {
        navigate(`/pathways/${currentPathwayId}/delete`);
    };

    const handleModalClose = React.useCallback(() => {
        return;
    }, []);

    const handleModalCancel = React.useCallback((): void => {
        setOpenEditStagesModal(false);
    }, []);

    const handleModalOpen = React.useCallback(() => {
        setOpenEditStagesModal(true);
    }, []);

    const handleModalUpdate = React.useCallback((e: any): void => {
        const newPathwayStages = e.formData as Pathway;
        const newPathway = { ...pathway };
        if (newPathwayStages && newPathway) {
            newPathway.stages = newPathwayStages.stages;
            setPathway(newPathway);
        }
        setOpenEditStagesModal(false);
    }, [pathway]);

    const handleWarningModalOpen = () => setWarningModalOpen(true);

    const handleWarningModalClose = () => {
        setWarningModalOpen(false);
        navigate(`/pathways/${currentPathwayId}`);
    };

    useEffect(() => {
        if (isDelete) {
            handleWarningModalOpen();
        }
    }, [isDelete]);

    const handleConfirmDelete = useCallback(async (): Promise<void> => {
        const isDeleted = await handleDelete();
        setWarningModalOpen(false);
        if (!isDeleted) {
            navigate(`/pathways/${currentPathwayId}`);
        }
    }, [navigate, handleDelete, currentPathwayId]);

    if (!pathway) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    return (
        <>
            <Modal
                open={openEditStagesModal}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ "& .MuiBox-root": { backgroundColor: theme.palette.background.default } }}
            >
                <PathwayStageForm
                    currentPathway={pathway}
                    onUpdate={handleModalUpdate}
                    onError={handleError}
                    onCancel={handleModalCancel}
                />
            </Modal>
            <Container>
                <Box className="bb-title-bar bb-mt-3">
                    <h2 className="bb-m0 bb-p0">Pathways</h2>
                </Box>
                <Breadcrumbs className="bb-mb-3 bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb" style={{ paddingTop: "10px" }}>
                    <Link to={`/pathways`}>Pathways</Link>
                    <Typography color="text.primary" className="bb-m0 bb-p0">{pathway?.name || "New Pathway"}</Typography>
                </Breadcrumbs>
                <Alert className="bb-title-info bb-mb-2" severity="info" style={{ width: "100%" }}>Manage your pathways on the Beebot AI Platform.&nbsp;
                    <a rel="noreferrer" className="bb-mr-1" href={`https://product.bbotapp.com/?page=acp`}
                        target="_blank">Learn More</a></Alert>
                <Box className="bb-flex bb-ui-box bb-flex-column bb-tac" sx={{ minHeight: "60vh" }}>
                    <Box className="bb-page-edit-cont">
                        {submitting && <Box className="bb-tac"><CircularProgress /></Box>}
                        <Box className="bb-flex bb-flex-column bb-justify-content-center bb-tac" sx={{ minHeight: "60vh" }}>
                            <Box className="bb-flex page-edit-buttons">
                                <Typography sx={{ fontSize: 18, fontWeight: "bold" }} color="text.secondary" gutterBottom alignContent="left">
                                    Pathway Editor
                                </Typography>
                                <Link className="bb-ml-auto bb-text-decoration-none bb-app-icon" to={`/pathways`}>
                                    <Button variant="outlined" startIcon={<KeyboardArrowLeftIcon />} disabled={submitting}>
                                        Pathways
                                    </Button>
                                </Link>
                                {currentPathwayId &&
                                    <>
                                        <Grid item xs={1}>
                                            <Button
                                                onClick={navigateToDelete}
                                                variant="outlined"
                                                startIcon={<DeleteOutlineIcon />}
                                                color="error"
                                                disabled={submitting}>
                                                    Delete
                                            </Button>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <UndoButton onClick={handleUndo} disable={submitting}></UndoButton>
                                        </Grid>
                                    </>
                                }
                                <Button variant="contained" startIcon={<SaveOutlinedIcon />} disabled={submitting} type="submit" form="pathway_pagesubmit">
                                    Save
                                </Button>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="bb-sp-cont">
                                    <Form
                                        id={"pathway_pagesubmit"}
                                        schema={pathwaysSchemaModel as JSONSchema7}
                                        formData={pathway}
                                        validator={validatorAjv8}
                                        onSubmit={handleSubmit}
                                        onError={handleError}
                                        uiSchema={uiSchema}
                                        widgets={customWidgets}
                                        liveValidate={true}
                                        showErrorList={false}
                                        disabled={submitting}
                                        className="bb-pathways-initial-form"
                                    />
                                    <Typography sx={{ fontSize: "1rem", fontWeight: "bold", width: "100%" }}
                                        color="text.secondary" gutterBottom className="bb-ml-auto bb-flex bb-flex-end bb-align-items-center bb-mt-1">
                                        {pathway.stages?.length || 0} Stages
                                        <Button startIcon={<EditIcon />}
                                            onClick={handleModalOpen} variant="contained" disabled={submitting} size="small" sx={{ marginLeft: 2 } }>
                                            Edit Stages
                                        </Button>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <WarningModal
                open={warningModalOpen}
                description={"Are you sure you want to perform this action?"}
                onConfirm={handleConfirmDelete}
                onCancel={handleWarningModalClose}
            />
        </>);
};
