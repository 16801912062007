import { useCallback, useState } from "react";

import { Box, CircularProgress, Grid, Container, Alert, Typography } from "@mui/material";

import { useAsync } from "../../useAsync";

import { getTables } from "../../services/api/DataManagementService";

import { ITableListModel } from "../../services/model/dataManagement/ITableListModel";

import useDataClient from "../../axios/dataClient";

import { TableCard } from "./TableCard";

export const TableList = () => {

    const { get } = useDataClient();
    const [tables, setTables] = useState<ITableListModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const loadTables = useCallback(async () => {
        setIsLoading(true);
        const data = await getTables(get);
        setIsLoading(false);
        return data;
    }, [get]);

    useAsync(loadTables, setTables, [get]);

    const renderCard = useCallback((table: ITableListModel) => {
        return (
            <Grid item xs={12} sm={6} md={4} key={table.id}>
                <TableCard
                    id={table.id}
                    displayName={table.displayName}
                    isReadOnly={table.isReadOnly}
                    description={table.description} />
            </Grid>);
    },
    []);

    return (
        <Container>
            <Box className="bb-title-bar">
                <h2>Data Tables</h2>
            </Box>
            <Alert className="bb-title-info" severity="info">Select one of your tables to edit and view.</Alert>
            <Grid className="bb-apps"
                container
                spacing={2}
                alignItems="stretch"
                alignContent="center">
                {isLoading
                    ? <CircularProgress />
                    : tables.length > 0
                        ? tables.map(renderCard)
                        : <Box className="bb-flex bb-ui-box bb-flex-column bb-justify-content-center bb-tac bb-misc-ui-helper" sx={{ minHeight: "60vh" }}>
                            <Box className="bb-app-loader"><i></i></Box>
                            <Typography variant="h4">No Data to view or manage.</Typography>
                        </Box>}
            </Grid>
        </Container>
    );
};
