import React, { useContext, useEffect, useState } from "react";
import { RouteObject, useRoutes } from "react-router-dom";

import { Connections, Home, Layout, NoMatch } from "./components";
import { ChatPage } from "./components";
import { RpasPage } from "./components";
import { BotPage } from "./components";
import { BotDetails } from "./components/Bots/BotDetails";
import { RpaPageLayout } from "./components/rpas/RpaPageLayout";
import { BotDialogs } from "./components/Bots/BotEditor/BotDialogs";
import { BotPageLayout } from "./components/Bots/BotPageLayout";
import { LiveChatDashboard } from "./components/livechat/dashboard/LiveChatDashboard";
import { MemberAppContext } from "./MemberAppContext";
import { RpaDetails } from "./components/rpas/RpaDetails";
import { Users } from "./components/Users/Users";
import { Assets, AssetsLayout, Folder } from "./components/assets";
import { Pathways, PathwayForm } from "./components/pathways";
import { CustomerConfigurationContext } from "./CustomerConfigurationContext";
import { Forbidden } from "./components/Forbidden";
import { Insights } from "./components/insights";
import { Analytics } from "./components/analytics";
import {
    Applications,
    AppAnalytics,
    AppDetails,
    AppPages,
    AppTheme,
    AppSettings,
    PageEdit,
    AppNavigation,
    AppProfile,
    AppSmartPages,
    AppProfileForm,
    AppSmartPageForm
} from "./components/applications";
import {
    AppNotifications,
    NotificationsLanding
} from "./components/applications/notifications";
import { TableList } from "./components/data";
import { ViewTable } from "./components/data/ViewTable";
import { DeploymentsPage } from "./components/applications/deployment/DeploymentsPage";
import * as newModuleNames from "./components/newModuleNames";

export type AppRoute = RouteObject & {
    searchBar?: boolean;
}

const liveChatRoutes: AppRoute[] = [
    {
        path: "livechat",
        element: <LiveChatDashboard />
    },
    {
        path: "livechat/chat",
        element: <ChatPage />
    }
];

const botsRoutes: AppRoute[] = [
    {
        path: "bots",
        element: <BotPageLayout />,
        searchBar: true,
        children: [
            {
                index: true,
                element: <BotPage />
            },
            {
                path: ":botId/dialogs",
                element: <BotDialogs />
            },
            {
                path: ":botId",
                element: <BotDetails />
            }
        ]
    }];

const automationRoutes: AppRoute[] = [
    {
        path: "automation",
        element: <RpaPageLayout />,
        searchBar: true,
        children: [
            { index: true, element: <RpasPage /> }
        ]
    }
];

const connectionsRoutes: AppRoute[] = [
    {
        path: "connections",
        element: <Connections />
    }
];

const assetRoutes: AppRoute[] = [
    {
        path: "assets",
        element: <AssetsLayout />,
        searchBar: true,
        children: [
            { index: true, element: <Assets /> },
            { path: "*", element: <Folder /> }
        ] }];

const insightRoutes: AppRoute[] = [
    {
        path: "insights",
        element: <Insights />
    }
];

const analyticsRoutes: AppRoute[] = [
    {
        path: "analytics",
        element: <Analytics appId=""></Analytics>
    }
];

const dataRoutes: AppRoute[] = [
    {
        path: "data/:tableId",
        element: <ViewTable />,
    },
    {
        path: "data",
        element: <TableList />
    }
];

const applicationsRoutes: AppRoute[] = [
    {
        path: "applications/:appId/notifications",
        element: <AppNotifications />,
    },
    {
        path: "applications/:appId/notifications/landing",
        element: <NotificationsLanding />,
    },
    {
        path: "applications/:appId/analytics",
        element: <AppAnalytics />,
    },
    {
        path: "applications/:appId/pages",
        element: <AppPages />,
    },
    {
        path: "applications/:appId/pages/:pageName",
        element: <PageEdit />,
    },
    {
        path: "applications/:appId/pages/:pageName/delete",
        element: <PageEdit />,
    },
    {
        path: `applications/:appId/pages/${newModuleNames.newPage}`,
        element: <PageEdit />,
    },
    {
        path: "applications/:appId/theme",
        element: <AppTheme />,
    },
    {
        path: "applications/:appId",
        element: <AppDetails />,
    },
    {
        path: "applications",
        element: <Applications />
    },
    {
        path: "applications/:appId/settings",
        element: <AppSettings />,
    },
    {
        path: "applications/:appId/navigation",
        element: <AppNavigation />,
    },
    {
        path: "applications/:appId/profiles",
        element: <AppProfile />,
    },
    {
        path: "applications/:appId/profiles/:profileName",
        element: <AppProfileForm />,
    },
    {
        path: "applications/:appId/profiles/:profileName/delete",
        element: <AppProfileForm />,
    },
    {
        path: `applications/:appId/profiles/${newModuleNames.newProfile}`,
        element: <AppProfileForm />,
    },
    {
        path: "applications/:appId/smartPages",
        element: <AppSmartPages />,
    },
    {
        path: "applications/:appId/smartPages/:smartPageName",
        element: <AppSmartPageForm />,
    },
    {
        path: "applications/:appId/smartPages/:smartPageName/delete",
        element: <AppSmartPageForm />,
    },
    {
        path: `applications/:appId/smartPages/${newModuleNames.newSmartPage}`,
        element: <AppSmartPageForm />,
    }
];

const adminRoutes: AppRoute[] = [
    {
        path: "users",
        element: <Users />,
        searchBar: true
    },
    {
        path: "pathways",
        element: <Pathways />,
    },
    {
        path: "pathways/:pathwayId",
        element: <PathwayForm />,
    },
    {
        path: "pathways/:pathwayId/delete",
        element: <PathwayForm />,
    },
    {
        path: `pathways/${newModuleNames.newPathway}`,
        element: <PathwayForm />,
    },
    {
        path: "deployments",
        element: <DeploymentsPage />,
    }
];

const children: AppRoute[] = [
    {
        index: true,
        element: <Home />
    },
    {
        path: "rpas",
        element: <RpaPageLayout />,
        children: [
            { path: ":botId", element: <RpaDetails /> }
        ]
    },
    {
        path: "*",
        element: <NoMatch />
    }
];

const root: AppRoute[] = [{
    path: "/",
    element: <Layout />,
    children
}];

// We need this to check for search bar in top toolbar - if we attempt to use a UseContext to pass the current routes, we get runtime errors
export const allRoutes = [
    ...root,
    ...children,
    ...botsRoutes,
    ...automationRoutes,
    ...connectionsRoutes,
    ...liveChatRoutes,
    ...assetRoutes,
    ...insightRoutes,
    ...analyticsRoutes,
    ...adminRoutes,
    ...dataRoutes,
    ...applicationsRoutes];

const replaceWithForbidden = (route: RouteObject): RouteObject =>
{
    const newRoute: RouteObject = { ...route };
    newRoute.element = <Forbidden />;
    newRoute.children = route.children?.map(replaceWithForbidden);
    return newRoute;
};

const addRoutes = (currentRoutes: RouteObject[], routes: RouteObject[], enabled: boolean): RouteObject[] => {
    let updatedRoutes = [...routes];
    if (!enabled) {
        updatedRoutes = updatedRoutes.map(replaceWithForbidden);
    }

    return currentRoutes.concat(updatedRoutes);
};

export const AuthorizedRoutes = () => {
    const appContext = useContext(MemberAppContext);
    const {
        conversationAI,
        liveAgent,
        assets,
        automation,
        connections,
        insights,
        applications,
        dataManagement
    } = useContext(CustomerConfigurationContext);
    const [routes, setRoutes] = useState<RouteObject[]>(root);

    useEffect(() => {
        if (!appContext.isAuthenticated && appContext.updateWithTokenData) {
            appContext.updateWithTokenData();
        }

        let updatedChildren = [...children];

        updatedChildren = addRoutes(updatedChildren, botsRoutes, conversationAI);
        updatedChildren = addRoutes(updatedChildren, automationRoutes, automation);
        updatedChildren = addRoutes(updatedChildren, assetRoutes, assets);
        updatedChildren = addRoutes(updatedChildren, applicationsRoutes, applications);
        updatedChildren = addRoutes(updatedChildren, connectionsRoutes, connections);
        updatedChildren = addRoutes(updatedChildren, insightRoutes, insights);
        updatedChildren = addRoutes(updatedChildren, analyticsRoutes, insights);
        updatedChildren = addRoutes(updatedChildren, dataRoutes, dataManagement);

        if (appContext.user && appContext.isAuthenticated && (appContext.user.isLiveAgentAdmin || appContext.user.isLiveOperator)) {
            updatedChildren = addRoutes(updatedChildren, liveChatRoutes, liveAgent);
        }
        if (appContext.user && appContext.isAuthenticated && (appContext.user.isAdmin || appContext.user.isLiveAgentAdmin)) {
            updatedChildren = updatedChildren.concat(adminRoutes);
        }

        setRoutes([{
            path: "/",
            element: <Layout />,
            children: updatedChildren
        }]);
    }, [appContext, conversationAI, automation, connections, assets, liveAgent, insights, applications, dataManagement]);

    return useRoutes(routes);
};
