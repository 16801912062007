import {
    Button,
    Card,
    CardActions,
    Tooltip,
    CardHeader,
    FormControlLabel,
    FormGroup,
    Switch,
    Grid
} from "@mui/material";

import { Link } from "react-router-dom";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";

import { bigNumber } from "../Statistics/StatHelpers";

interface ApplicationCardProps {
    id: string;
    displayName: string;
    isActive: boolean;
    handleIsEnabledChange: (appId: string, isEnabled: boolean) => Promise<void>;
    isEnabledToggleDisabled: boolean;
    visitorsInTheLast24H: number;
    visitorsInTheLast30D: number;
}

export const ApplicationCard = ({
    id,
    displayName,
    isActive,
    handleIsEnabledChange,
    isEnabledToggleDisabled,
    visitorsInTheLast24H,
    visitorsInTheLast30D,
}: ApplicationCardProps) => {

    const [open, setOpen] = useState(false);
    const [desiredState, setDesiredState] = useState(isActive);

    const handleOpen = (newState: boolean) => () => {
        setDesiredState(newState);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirm = async () => {
        await handleIsEnabledChange(id, desiredState);
        handleClose();
    };
    const handleSwitchChange = handleOpen(!isActive);
    const handleButtonClick = handleOpen(!isActive);
    const bbhandleClose = handleClose;
    const bbhandleConfirm = handleConfirm;

    return (
        <Card className="bb-app-icons animated animatedFadeInUp fadeInUp bb-mb-2">
            <CardHeader title={displayName} />
            <Grid container spacing={1} className="bb-app-icon-stats">
                <Grid item xs={6}>
                    <div className="bb-app-icon-description">
                        <h2 className="fontSize2 bb-m0 bb-p0">{bigNumber( visitorsInTheLast30D )}</h2>
                        <p className="bb-m0 bb-p0">Visitors</p>
                        <p className="bb-small-text bb-m0 bb-p0">(Last Month)</p>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className="bb-app-icon-description">
                        <h2 className="fontSize2 bb-m0 bb-p0">{bigNumber( visitorsInTheLast24H) }</h2>
                        <p className="bb-m0 bb-p0">Visitors</p>
                        <p className="bb-small-text bb-m0 bb-p0">(Last 24 hours)</p>
                    </div>
                </Grid>
            </Grid>
            <CardActions>
                <FormGroup>
                    <Tooltip title="Warning: This will disable / enable your app">
                        <FormControlLabel
                            control={
                                <Switch
                                    onClick={handleButtonClick}
                                    checked={isActive}
                                    size="small"
                                    onChange={handleSwitchChange}
                                    disabled={isEnabledToggleDisabled}
                                />
                            }
                            label={isActive ? "Active" : "Inactive"}
                        />
                    </Tooltip>
                </FormGroup>
                <Button variant="contained"
                    component={Link}
                    to={`/applications/${id}`}
                    sx={{ marginLeft: "auto" }}>View
                </Button>
            </CardActions>
            <Dialog
                open={open}
                onClose={bbhandleClose}
            >
                <DialogTitle id="alert-dialog-title" className="bb-error-dialog-header bb-flex bb-align-items-center">
                    <WarningAmberIcon className="bb-mr-1"></WarningAmberIcon> <h2 className="bb-m0 bb-p0">{"WARNING!"}</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="bb-tac">
                        <h2>Are you really sure?</h2>
                        {isActive ? "This will disable your app, and viewers wont see any content." : "This will enable your app so viewers can see content."}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={bbhandleClose}>
                        Cancel
                    </Button>
                    <Button onClick={bbhandleConfirm} className="bb-ml-auto">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>);
};
