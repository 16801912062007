import * as yup from "yup";
import { InferType } from "yup";

export const liveChatTransferSchema =
    yup.object({
        chatId: yup.string().required(),
        toOperatorId: yup.string().required(),
        userName: yup.string().required(),
        userEmail: yup.string().required(),
        issue: yup.string().required(),
        botName: yup.string().required(),
        notes: yup.string(),
        messageHistory: yup.array().of(yup.object({
            sender: yup.string().oneOf(["Bot", "Admin", "User"]),
            message: yup.string().required(),
            time: yup.date().required()
        }))
    });

export type LiveChatTransfer = InferType<typeof liveChatTransferSchema>;
