import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import { useCallback } from "react";

import LoginIcon from "@mui/icons-material/Login";

interface ChatSignInDialogArgs {
    dialogOpen: boolean;
    onCancel: () => void;
    onSuccessfulSignin: () => void;
    onFailedSignin: () => void;
}

export const ChatSignInDialog = ({
    dialogOpen,
    onCancel,
    onSuccessfulSignin,
    onFailedSignin,
}: ChatSignInDialogArgs) => {

    const signIn = useCallback(async () => {
        try {
            onSuccessfulSignin();
        } catch {
            onFailedSignin();
        }
    }, [onFailedSignin, onSuccessfulSignin]);

    const cancel = useCallback(() => {
        onCancel();
    }, [onCancel]);

    return (
        <Dialog open={dialogOpen} maxWidth="md">
            <DialogTitle className="signin-dialog-title">
                Sign in
            </DialogTitle>
            <DialogContent dividers sx={{ textAlign: "center" }}>
                <LoginIcon color="primary" fontSize="large" sx={{ margin: "1rem auto" }} />
                <Typography className="signin-dialog-content" variant="h2" component="h3" sx={{ paddingY: "0.5rem" }}>
                    Would you like to sign in?
                </Typography>
                <Typography className="bb-mb-3" sx={{ marginBottom: "2.5rem", paddingTop: "0.5" }}>
                    This means customers will be able to reach you.
                </Typography>
            </DialogContent>
            <DialogActions className="dialog-actions">
                <Button onClick={cancel}>
                    CANCEL
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={signIn}
                    className="signin-action-confirm">
                    SIGN IN
                </Button>
            </DialogActions>
        </Dialog>
    );
};
