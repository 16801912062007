import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useCallback } from "react";

interface DeleteAssetDialogProps {
    open: boolean;
    onCancel: () => void;
    onDelete: () => void;
}

export const DeleteAssetDialog = ({ open, onCancel, onDelete }: DeleteAssetDialogProps) => {
    const onDeleteCallback = useCallback(async () => {
        onDelete();
    },[onDelete]);

    return (<Dialog open={open} >
        <DialogTitle className="bbot-dialog-title">
            <Typography>Delete</Typography>
        </DialogTitle>
        <DialogContent className="bbot-dialog-content">
            <Typography>
                Are you sure you want to <b>Delete?</b>
            </Typography>
        </DialogContent>
        <DialogActions className="bbot-dialog-actions">
            <Button onClick={onCancel}>Cancel</Button>
            <Button className="bb-primary-button" onClick={onDeleteCallback}>Yes</Button>
        </DialogActions>
    </Dialog>);
};
