import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
    Box,
    Typography,
    Container,
    CircularProgress,
    Breadcrumbs,
    Button,
    Grid,
    Chip,
    Alert
} from '@mui/material';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { AppViewModel } from "../../../services/model/apps/AppViewModel";
import { getApp } from "../../../services/api/FrontendService";
import { getLandingAnalytics } from "../../../services/api/NotificationService";
import useDataClient from "../../../axios/dataClient";
import { GridRenderCellParams } from '@mui/x-data-grid';
import { NotificationsSummaryGrid } from "../../Statistics/NotificationsSummaryGrid";
import { AnalyticsBarChart } from "../../analytics/AnalyticsBarChart";
import { AnalyticsDataGrid } from "../../analytics/AnalyticsDataGrid";
import { getStagingUrl } from "../../../services/helper/regex";
export const NotificationsLanding = () => {
    const { appId } = useParams();
    const [appData, setAppData] = useState<AppViewModel | null>(null);
    const { get } = useDataClient();
    const [landingAnalyticsData, setLandingAnalyticsData] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            if (appId) {
                const [fetchedAppData, fetchedLandingAnalyticsData] = await Promise.all([
                    getApp(get)(appId),
                    getLandingAnalytics(get)(appId)
                ]);
                setAppData(fetchedAppData);
                setLandingAnalyticsData(fetchedLandingAnalyticsData);
            }
        };

        fetchData();
    }, [appId, get]);

    if (!appData) {
        return <Box className="bb-tac"><CircularProgress /></Box>;
    }

    const broadcastGridConfig = {
        title: "",
        initialState: {
            sorting: {
                sortModel: [{ field: "timestamp", sort: "desc" }],
            },
            pagination: { paginationModel: { pageSize: 25 } }
        },
        columnDef: [
            {
                timestamp: {
                    label: "Timestamp"
                }
            },
            {
                user: {
                    label: "Created By"
                }
            },
            {
                title: {
                    label: "Title"
                }
            },
            {
                channels: {
                    label: "Channels"
                }
            },
            {
                filters: {
                    label: "Audience Filters"
                }
            },
            {
                sentSuccessCount: {
                    label: "Sent"
                }
            },
            {
                sentFailedCount: {
                    label: "Failed"
                }
            },
            {
                sentOpenedCount: {
                    label: "Opened"
                }
            }
        ], columns: [
            {
                field: "timestamp",
                headerName: "Timestamp",
                flex: 1.5 ,
                valueFormatter: (param: any) => new Date(param).toISOString().replace(/T/, ' ').replace(/\..+/, '')  },
            { field: "user", headerName: "Created By", flex: 1 },
            { field: "title", headerName: "Title", flex: 1 },
            { field: "channels", headerName: "Channels", flex: 2, renderCell: (params: GridRenderCellParams<any, any>) => {
                return ( <div> { params.value ? (params.value.map((gateway: any) => <Chip size={"small"} key={gateway} label={gateway} />)) : null} </div> ); }
            },
            {
                field: "filters",
                headerName: "Audience Filters",
                flex: 2,
                renderCell: (params: GridRenderCellParams<any, any>) => {
                    return ( <div> { params.value ? (
                        params.value.map(
                            (gateway: any) =>
                                gateway.items.map(
                                    (kvp: any ) => <Chip size={"small"} key={gateway.category + '-' + kvp}  label={kvp} />) ) ) : null} </div> ); }
            },
            { field: "sentSuccessCount", headerName: "Sent", flex: 0.4 },
            { field: "sentFailedCount", headerName: "Failed", flex: 0.4 },
            { field: "sentOpenedCount", headerName: "Opened", flex: 0.8 },
        ]
    };

    const unitPlot = "day";

    return (
        <Container>
            <Box className="bb-title-bar bb-mt-3">
                <h2 className="bb-m0 bb-p0">Notifications: {appData.displayName}</h2>
                <Box className="bb-ml-auto bb-align-items-center bb-flex">
                    <OpenInNewIcon className="bb-open-app-link"></OpenInNewIcon>
                    <a rel="noreferrer" className="bb-mr-1" href={`https://${getStagingUrl(appData.url)}`} target="_blank">View my
                        Staging App</a>
                    <OpenInNewIcon className="bb-open-app-link"></OpenInNewIcon>
                    <a rel="noreferrer" className="bb-mr-1" href={`https://${appData.url}`} target="_blank">
                    View my Live App</a>
                </Box>
            </Box>
            <Breadcrumbs className="bb-breadcrumb bb-flex bb-align-items-center" separator="›" aria-label="breadcrumb">
                <Link to="/applications">Applications</Link>
                <Link to={`/applications/${appId}`}>Modules</Link>
                <Typography color="text.primary" className="bb-m0 bb-p0">Notifications</Typography>
            </Breadcrumbs>
            <Alert className="bb-title-info bb-mb-2 bb-align-items-center bb-flex" severity="info"
                action={
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={`/applications/${appId}/notifications`}
                        sx={{ marginBottom: ".3rem" }}
                        className="animated animatedFadeInUp fadeInUp7"
                    >
                        New Notification
                    </Button>
                }
            >
                Your notification history and statistics.
            </Alert>
            <Box className="bb-mb-2 animated animatedFadeInUp fadeInUp4">
                <NotificationsSummaryGrid loading={landingAnalyticsData ? false : true} data={landingAnalyticsData?.cards} />
            </Box>
            {!landingAnalyticsData ? null : (
                <Box className="bb-ui-box animated animatedFadeInUp fadeInUp5 bb-flex bb-flex-column">
                    <Typography variant="h3" component="h3" className="bb-tac">Notification Timeline</Typography>
                    <Box>
                        <AnalyticsBarChart data={landingAnalyticsData?.timeline} timeunit={ unitPlot } simpleData={true} title=""></AnalyticsBarChart>
                    </Box>
                </Box>
            )}
            <Grid container className="bb-ui-box-analytics bb-flex-column animated animatedFadeInUp fadeInUp6 bb-mt-2">
                <Grid item xs={12} className="bb-mt-2 bb-pt-1 bb-notifications-table">
                    {!landingAnalyticsData ? null : (
                        <>
                            <AnalyticsDataGrid
                                data={landingAnalyticsData?.broadcasts.pastNotifications}
                                config={broadcastGridConfig}
                                title="Notification History"
                                transformed={true}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};
