import { ITableListModel } from "../model/dataManagement/ITableListModel";
import { ITableEditModel } from "../model/dataManagement/ITableEditModel";
import { ITableStructureListModel } from "../model/dataManagement/ITableStructureListModel";
import { IRowKeyPair } from "../model/dataManagement/IRowKeyPair";

const getTables = async (getFunction: <T>(url: string) => Promise<T>) => {
    const result = await getFunction<ITableListModel[]>("/api/data");
    return result;
};

const getTableStructures = async (getFunction: <T>(url: string) => Promise<T>) => {
    const result = await getFunction<ITableStructureListModel[]>("/api/data/structures");
    return result;
};

const getTable = async (getFunction: <T>(url: string) => Promise<T>, tableId: string) => {
    const result = await getFunction<ITableEditModel>(`/api/data/${tableId}`);
    return result;
};

const deleteRows = async (
    deleteFunction: (url: string, data: any) => Promise<void>,
    tableId: string,
    rowKeyPairs: IRowKeyPair[]
) => {
    await deleteFunction(`/api/data/${tableId}/rows/delete`, rowKeyPairs);
};

const saveRow = async (
    putFunction: (url: string, data: any) => Promise<IRowKeyPair>,
    tableId: string,
    rowData: any
): Promise<IRowKeyPair> => {
    const rowKeyPair = await putFunction(`/api/data/${tableId}/rows/save`, rowData);
    return rowKeyPair;
};

export { getTables, getTableStructures, getTable, deleteRows, saveRow };
