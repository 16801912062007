import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import './rjsf-property.css';

export const AccordionFieldTemplate = (props: any) => {
    return (
        <Accordion sx={{ boxShadow: "none", border: "1px solid #eee", margin: "0" }} className="bb-accordion">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="bb-accordion-header">
                <Typography className="bb-m0 bb-p0" sx={{ fontSize: "1rem", fontWeight: "bold" }}>{props.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{props.description}</Typography>
                {props.properties.map((e: any, index: number) => (
                    <div key={index} className="property-wrapper">{e.content}</div>
                ))}
            </AccordionDetails>
        </Accordion>
    );
};
